import * as THREE from "three";

export const customThreejsPipelineModule = () => {
  let scene3: {
    camera: THREE.PerspectiveCamera;
    scene: THREE.Scene;
    renderer: THREE.WebGLRenderer;
  };

  let engaged = false;
  let posBufferX = 0;
  let posBufferY = 0;
  let posBufferZ = 0;
  //window.BATTLE.bufferLength = 2;

  const engage = ({
    canvas,
    canvasWidth,
    canvasHeight,
    GLctx,
  }: {
    canvas: HTMLCanvasElement;
    canvasWidth: number;
    canvasHeight: number;
    GLctx: WebGLRenderingContext;
  }) => {
    if (engaged) {
      return;
    }
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      80,
      window.innerWidth / window.innerHeight,
      4,
      5000
    );
    scene.add(camera);

    const renderer = new THREE.WebGLRenderer({
      canvas,
      context: GLctx,
      alpha: false,
      powerPreference: "high-performance",
      antialias: false,
      logarithmicDepthBuffer: true,
    });
    renderer.autoClear = false;
    renderer.setSize(canvasWidth, canvasHeight);

    scene3 = { scene, camera, renderer };

    engaged = true;
  };

  // This is a workaround for https://bugs.webkit.org/show_bug.cgi?id=237230
  // Once the fix is released, we can add `&& parseFloat(device.osVersion) < 15.x`
  const device = window.XR8.XrDevice.deviceEstimate();
  const needsPrerenderFinish =
    device.os === "iOS" && parseFloat(device.osVersion) >= 15.4;

  (window.XR8 as any).Threejs.xrScene = () => {
    return scene3;
  };

  return {
    name: "customthreejs",
    onStart: (args: any) => engage(args),
    onAttach: (args: any) => engage(args),
    onDetach: () => {
      engaged = false;
    },
    onUpdate: ({ processCpuResult }: { processCpuResult: any }) => {
      const realitySource =
        processCpuResult.reality || processCpuResult.facecontroller;

      if (!realitySource) {
        return;
      }

      const { rotation, position, intrinsics } = realitySource;
      const { scene, camera, renderer } = (window.XR8 as any).Threejs.xrScene();
      for (let i = 0; i < 16; i++) {
        camera.projectionMatrix.elements[i] = intrinsics[i];
      }

      // Fix for broken raycasting in r103 and higher. Related to:
      //   https://github.com/mrdoob/three.js/pull/15996

      /* @ts-ignore */
      if (camera.projectionMatrixInverse.invert) {
        // THREE 123 preferred version
        camera.projectionMatrixInverse.copy(camera.projectionMatrix).invert();
      } else {
        // Backwards compatible version
        camera.projectionMatrixInverse.getInverse(camera.projectionMatrix);
      }

      if (rotation) {
        camera.setRotationFromQuaternion(rotation);
      }

      posBufferX =
        (position.x * 1) / window.BATTLE.bufferLength +
        posBufferX * (1 - 1 / window.BATTLE.bufferLength);
      posBufferY =
        (position.y * 1) / window.BATTLE.bufferLength +
        posBufferY * (1 - 1 / window.BATTLE.bufferLength);
      posBufferZ =
        (position.z * 1) / window.BATTLE.bufferLength +
        posBufferZ * (1 - 1 / window.BATTLE.bufferLength);
      camera.position.set(posBufferX, posBufferY, posBufferZ);
    },
    onCanvasSizeChange: ({
      canvasWidth,
      canvasHeight,
    }: {
      canvasWidth: number;
      canvasHeight: number;
    }) => {
      if (!engaged) {
        return;
      }
      const { renderer } = scene3;
      renderer.setSize(canvasWidth, canvasHeight);
    },
    onRender: () => {
      const { scene, renderer, camera } = scene3;
      renderer.clearDepth();
      if (needsPrerenderFinish) {
        renderer.getContext().finish();
      }
      renderer.render(scene, camera);
    },
    // Get a handle to the xr scene, camera and renderer. Returns:
    // {
    //   scene: The Threejs scene.
    //   camera: The Threejs main camera.
    //   renderer: The Threejs renderer.
    // }
    xrScene: () => {
      return scene3;
    },
  };
};
