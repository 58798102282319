import { CrouchIdle } from "./CrouchIdle";
import * as Utils from "../../core/FunctionLibrary";
import { Character } from "../Character";
import { CharacterStateBase, ProneIdle, Walk } from "./_stateLibrary";

export class EndProne extends CharacterStateBase {
  isCrouchToStand = false;
  constructor(character: Character) {
    super(character);
    this.character.rotationSimulator.mass = 20;
    this.character.rotationSimulator.damping = 0.7;
    this.character.setArcadeVelocityTarget(0.8);
  }

  public update(timeStep: number): void {
    super.update(timeStep);
    this.character.setState(new Walk(this.character));
  }

  public onInputChange(): void {
    super.onInputChange();
  }
}
