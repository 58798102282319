import { ParticleFX } from "./../particles/ParticleFX";
import { EntityType } from "../enums/EntityType";
import { IWorldEntity } from "../interfaces/IWorldEntity";
import { World } from "../world/World";
import * as THREE from "three";
import { MeshLine, MeshLineMaterial } from "meshline";

export class Pennant extends THREE.Object3D implements IWorldEntity {
  public world: World | undefined;

  public _avgPos = new THREE.Vector3();
  public _sumPos = new THREE.Vector3();
  public _down = new THREE.Vector3(0, -1, 0);
  public iconScale = 40;
  public height = 60;
  renderOrder: number = 99;
  private ballSprite: THREE.Sprite;
  //public promptAnim: THREE.Sprite;
  public promptParticle: ParticleFX;
  public promptIcon: THREE.Sprite;

  public rayCastGroup: THREE.Group;
  public tapTargets: THREE.Object3D[] = [];
  public tapCallback = () => {};
  public tappable = false;
  public linePoints: THREE.Vector3[] = [];
  public line: MeshLine;
  private brandYellow = new THREE.Color()
    .setHex(0xfab500)
    .convertSRGBToLinear();
  //private icon: THREE.Texture;

  constructor(world: World, icon: string) {
    super();
    this.name = "targetPennant";
    this.entityType = EntityType.UIOverlay;
    this.rayCastGroup = new THREE.Group();
    this.world = world;
    world.add(this);

    this.line = this.createLine();
    //this.promptAnim = this.createPromptAnim();
    this.promptParticle = this.createPromptParticle();
    this.promptIcon = this.createPromptIcon();

    this.ballSprite = this.createBallSprite();

    this.rayCastGroup.add(this.ballSprite);

    this.traverse((child) => {
      this.tapTargets.push(child);
    });
    this.rayCastGroup.traverse((child) => {
      this.tapTargets.push(child);
    });

    document.addEventListener("tap3D", this.onTapEvent.bind(this));

    this.hide();
    this.world.bloomObjects.add(this.promptParticle.object!);
    this.world.bloomObjects.add(this.promptIcon);
    this.world.bloomObjects.add(this.ballSprite);
  }

  onTapEvent(event: any): void {
    if (!this.visible || !this.tappable) return;

    const match = this.tapTargets.find((target) => {
      return target.uuid === event.detail.uuid;
    });

    if (match) {
      document.dispatchEvent(
        new CustomEvent("pennantTap", { detail: { name: this.name } })
      );
    }
  }

  createPromptParticle(): ParticleFX {
    const particle = this.world?.particleManager.get("cta")!;

    particle!.object!.scale.set(
      this.iconScale * 20,
      this.iconScale * 20,
      this.iconScale * 20
    );

    this.add(particle.object!);
    particle!.object!.position.set(0, this.iconScale * 1.5, 0);
    particle.restart();
    particle.stop();
    return particle;
  }

  createBallSprite(): THREE.Sprite {
    const icon = new THREE.TextureLoader().load("./tex/pennant-ball.png");
    const ballSpriteMat = this.createSpriteMaterial(icon);

    const ballSprite = new THREE.Sprite(ballSpriteMat);
    ballSprite.center = new THREE.Vector2(0.5, 0);
    ballSprite.scale.set(2, 2, 2);
    this.world?.graphicsWorld.add(ballSprite);
    //ballSprite.renderOrder = 99;
    return ballSprite;
  }

  createPromptIcon(): THREE.Sprite {
    const icon = new THREE.TextureLoader().load("./tex/pennant-target.png");
    const iconMat = this.createSpriteMaterial(icon);

    const iconSprite = new THREE.Sprite(iconMat);
    iconSprite.center = new THREE.Vector2(0.5, 0.0);
    iconSprite.scale.set(this.iconScale, this.iconScale, this.iconScale);
    iconSprite.position.set(0, this.iconScale - 0.2, 0);
    this.add(iconSprite);

    const tapExpandArea = iconSprite.clone();

    iconSprite.add(tapExpandArea);
    tapExpandArea.position.set(0, -1.5, 0);
    tapExpandArea.scale.set(4, 4, 4);
    tapExpandArea.visible = false;

    this.tapTargets.push(tapExpandArea);

    //this.promptAnim.add(iconSprite);
    //iconSprite.position.set(0, 0, 0);
    return iconSprite;
  }

  createLine(): MeshLine {
    //const lineTex = new THREE.TextureLoader().load("./tex/gfx_line.png");
    //lineTex.encoding = THREE.sRGBEncoding;
    const lineMat = new MeshLineMaterial({
      color: this.brandYellow,
      //map: lineTex,
      //useMap: true,
      //alphaMap: lineTex,
      //useAlphaMap: true,
      // sizeAttenuation: false,
      transparent: true,
      lineWidth: 0.5,
      //transparent: true,
      depthTest: false,
      // blending: THREE.AdditiveBlending,
      side: THREE.DoubleSide,
    } as any);
    //lineMat.vertexShader = this.fixedVertexShader; // uncomment if we get logarithmic working
    this.linePoints.push(new THREE.Vector3(0, this.iconScale, 0));
    this.linePoints.push(new THREE.Vector3(0, this.iconScale, 0));
    this.linePoints.push(new THREE.Vector3(0, -this.height, 0));
    this.linePoints.push(new THREE.Vector3(0, -this.height, 0));

    const geometry = new THREE.BufferGeometry().setFromPoints(this.linePoints);
    const line = new MeshLine();
    line.setFromPoints(this.linePoints);
    line.setGeometry(geometry, (p) => 1);
    const lineMesh = new THREE.Mesh(line, lineMat);

    this.add(lineMesh);
    this.world?.bloomObjects.add(lineMesh);
    lineMesh.frustumCulled = false;
    return line;
  }

  entityType: EntityType;
  updateOrder: number = 10;

  addToWorld(world: World): void {
    this.world = world;
    world.overlays.push(this);
    world.graphicsWorld.add(this);
    world.graphicsWorld.add(this.rayCastGroup);
  }
  removeFromWorld(world: World): void {
    this.world = undefined;
    world.overlays = world.overlays.filter((overlay) => {
      return overlay !== this;
    });
    world.graphicsWorld.remove(this);
    world.graphicsWorld.remove(this.rayCastGroup);
  }

  setScenario(data: any): void {
    throw new Error("Method not implemented.");
  }

  update(timestep: number, unscaledTimeStep: number): void {
    if (isNaN(this.position.x)) return;

    const result = this.getRaycast();
    this.rayCastGroup.position.copy(result.point);
    (this.line as any).advance(new THREE.Vector3(0, this.iconScale, 0));
    (this.line as any).advance(new THREE.Vector3(0, this.iconScale, 0));
    (this.line as any).advance(
      new THREE.Vector3(0, -this.height + result.point.y, 0)
    );
    (this.line as any).advance(
      new THREE.Vector3(0, -this.height + result.point.y, 0)
    );
  }

  setPosition(position: THREE.Vector3): void {
    this.position.copy(position);
    this.position.y = this.height;
  }

  public getRaycast(): THREE.Intersection {
    return this.world?.getTerrainRaycast(this.position.x, this.position.z)!;
  }

  public showPrompt(): void {
    this.tappable = true;
    this.promptParticle.object!.visible = true;
    this.promptIcon.visible = true;
    this.promptParticle.restart();

    //this.promptSource.play();
  }
  public hidePrompt(): void {
    this.tappable = false;
    this.promptParticle.object!.visible = false;
    this.promptIcon.visible = false;
    //this.promptSource.stop();
    this.promptParticle.stop();
  }

  public show(): void {
    this.visible = true;
    this.rayCastGroup.visible = true;
  }

  public dispose(): void {
    this.world!.graphicsWorld.remove(this.rayCastGroup);
    this.world!.graphicsWorld.remove(this);
  }

  public hide(): void {
    this.hidePrompt();
    this.visible = false;
    this.rayCastGroup.visible = false;
    this.promptParticle.stop();
  }

  public createSpriteMaterial(map: THREE.Texture): THREE.SpriteMaterial {
    map.encoding = THREE.sRGBEncoding;
    return new THREE.SpriteMaterial({
      map: map,
      opacity: 1,
      //alphaMap: map,
      color: 0xffffff,
      transparent: true,
      sizeAttenuation: true,
      //depthWrite: false,
      depthTest: false,
      //blending: THREE.AdditiveBlending,
    });
  }

  fixedVertexShader = (THREE.ShaderChunk["meshline_vert"] = [
    "",
    "#include <common>",
    THREE.ShaderChunk.logdepthbuf_pars_vertex,
    THREE.ShaderChunk.fog_pars_vertex,
    "",
    "attribute vec3 previous;",
    "attribute vec3 next;",
    "attribute float side;",
    "attribute float width;",
    "attribute float counters;",
    "",
    "uniform vec2 resolution;",
    "uniform float lineWidth;",
    "uniform vec3 color;",
    "uniform float opacity;",
    "uniform float sizeAttenuation;",
    "",
    "varying vec2 vUV;",
    "varying vec4 vColor;",
    "varying float vCounters;",
    "",
    "vec2 fix( vec4 i, float aspect ) {",
    "",
    "    vec2 res = i.xy / i.w;",
    "    res.x *= aspect;",
    "	 vCounters = counters;",
    "    return res;",
    "",
    "}",
    "",
    "void main() {",
    "",
    "    float aspect = resolution.x / resolution.y;",
    "",
    "    vColor = vec4( color, opacity );",
    "    vUV = uv;",
    "",
    "    mat4 m = projectionMatrix * modelViewMatrix;",
    "    vec4 finalPosition = m * vec4( position, 1.0 );",
    "    vec4 prevPos = m * vec4( previous, 1.0 );",
    "    vec4 nextPos = m * vec4( next, 1.0 );",
    "",
    "    vec2 currentP = fix( finalPosition, aspect );",
    "    vec2 prevP = fix( prevPos, aspect );",
    "    vec2 nextP = fix( nextPos, aspect );",
    "",
    "    float w = lineWidth * width;",
    "",
    "    vec2 dir;",
    "    if( nextP == currentP ) dir = normalize( currentP - prevP );",
    "    else if( prevP == currentP ) dir = normalize( nextP - currentP );",
    "    else {",
    "        vec2 dir1 = normalize( currentP - prevP );",
    "        vec2 dir2 = normalize( nextP - currentP );",
    "        dir = normalize( dir1 + dir2 );",
    "",
    "        vec2 perp = vec2( -dir1.y, dir1.x );",
    "        vec2 miter = vec2( -dir.y, dir.x );",
    "        //w = clamp( w / dot( miter, perp ), 0., 4. * lineWidth * width );",
    "",
    "    }",
    "",
    "    //vec2 normal = ( cross( vec3( dir, 0. ), vec3( 0., 0., 1. ) ) ).xy;",
    "    vec4 normal = vec4( -dir.y, dir.x, 0., 1. );",
    "    normal.xy *= .5 * w;",
    "    normal *= projectionMatrix;",
    "    if( sizeAttenuation == 0. ) {",
    "        normal.xy *= finalPosition.w;",
    "        normal.xy /= ( vec4( resolution, 0., 1. ) * projectionMatrix ).xy;",
    "    }",
    "",
    "    finalPosition.xy += normal.xy * side;",
    "",
    "    gl_Position = finalPosition;",
    "",
    THREE.ShaderChunk.logdepthbuf_vertex,
    THREE.ShaderChunk.fog_vertex &&
      "    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );",
    THREE.ShaderChunk.fog_vertex,
    "}",
  ].join("\n"));
}
