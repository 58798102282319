import * as THREE from "three";
import { PerspectiveCamera } from "three";
import { World } from "../scene/world/World";

export const experiencePipelineModule = () => {
  return {
    name: "experience",
    onStart: ({ canvas }: { canvas: HTMLCanvasElement }) => {
      const { scene, camera, renderer } = (window.XR8 as any).Threejs.xrScene();
      camera.position.set(0, window.BATTLE.USER_HEIGHT, 0);

      camera.near = 5;
      camera.far = 50000;
      // camera.updateProjectionMatrix();
      window.XR8.XrController.updateCameraProjectionMatrix({
        origin: camera.position,
        facing: camera.quaternion,
        cam: { nearClipPlane: 5, farClipPlane: 50000 },
      });

      const world = new World(
        "/export-models/world.glb",
        scene,
        camera,
        renderer,
        false
      );
    },
  };
};
