import { IUpdatable } from "./../interfaces/IUpdatable";
import * as THREE from "three";
import {
  BatchedParticleRenderer,
  ParticleEmitter,
  QuarksLoader,
} from "three.quarks";
import { World } from "../world/World";

import { ParticleFX } from "./ParticleFX";

export class ParticleFxManager implements IUpdatable {
  particleObjects = new Map<string, THREE.Object3D>();
  world: World;
  updateOrder: number = 3;
  public batchedRenderer: BatchedParticleRenderer =
    new BatchedParticleRenderer();
  constructor(world: World) {
    this.world = world;
    this.world.registerUpdatable(this);
    this.world.graphicsWorld.add(this.batchedRenderer);
  }

  update(timestep: number, unscaledTimeStep: number): void {
    this.batchedRenderer.update(timestep);
  }

  preload() {
    this.load("./particles/fx_billowingSmoke_3.json", "smoke");
    this.load("./particles/fx_dustTrail_1.json", "dust");
    this.load("./particles/fx_enemyActivity_2.json", "enemyActivity");
    this.load("./particles/fx_explosion_1.json", "explosion");
    this.load("./particles/fx_fire_2.json", "fire");
    this.load("./particles/fx_muzzleFlash_1.json", "muzzleFlash");
    this.load("./particles/fx_takingFire_1.json", "takingFire");
    this.load("./particles/fx_muzzleFlash_vehicle.json", "muzzleFlashVehicle");
    this.load("./particles/fx_CTA_button_1.json", "cta");
    this.load("./particles/fx_vehicleExplosion_1.json", "vehicleExplosion");
    this.load("./particles/fx_artilleryDust_1.json", "artilleryDust");
    this.load("./particles/fx_radarPing_1.json", "radarPing");
  }

  load(path: string, name: string) {
    let loader = new QuarksLoader();
    loader.setCrossOrigin("");
    loader.load(
      path,
      this.batchedRenderer,
      (object3D: THREE.Object3D) => {
        object3D!.traverse((child) => {
          if (child instanceof ParticleEmitter) {
            child.system.restart();
            child.system.pause();
          }
        });
        object3D.visible = false;
        this.particleObjects.set(name, object3D);
      },
      () => {},
      () => {}
    );
  }

  get(name: string): ParticleFX {
    let obj = this.particleObjects.get(name);
    if (!obj) throw new Error("invalid particle name :" + name);
    let newObj = obj.clone();
    newObj.visible = true;
    //newObj.rotateX(Math.PI / 2);
    this.world.graphicsWorld.add(newObj);
    return new ParticleFX(newObj);
  }
}
