import { VehicleHalt } from "./../characters/character_ai/VehicleHalt";
import * as THREE from "three";

import { Character } from "../characters/Character";
import { FollowPath } from "../characters/character_ai/FollowPath";
import * as Utils from "../core/FunctionLibrary";
import { LoadingManager } from "../core/LoadingManager";
import { ISpawnPoint } from "../interfaces/ISpawnPoint";
import { Car } from "../vehicles/Car";
import { Vehicle } from "../vehicles/Vehicle";
import { World } from "../world/World";
import { VehicleType } from "./../enums/VehicleType";
import { CharacterSpawnPoint } from "./CharacterSpawnPoint";

export class VehicleSpawnPoint implements ISpawnPoint {
  public object: THREE.Object3D;
  public entity: Vehicle | undefined;
  public name: string;
  constructor(object: THREE.Object3D) {
    this.object = object;
    this.name = this.object.userData.name;
  }

  public spawn(
    loadingManager: LoadingManager,
    world: World
  ): Promise<VehicleSpawnPoint> {
    //this.world = world;
    const glbPath = "./export-models/" + this.object.userData.type + ".glb";

    return new Promise<VehicleSpawnPoint>((resolve, reject) => {
      if (Object.keys(world.modelCache).includes(glbPath)) {
        //clone existing model
        const modelPromise = world.modelCache[glbPath];

        modelPromise.then((model) => {
          resolve(this.createSpawnPoint(model, world));
        });
      } else {
        const modelPromise = new Promise<any>((resolve, reject) => {
          loadingManager.loadGLTF(glbPath, (model: any) => {
            resolve(model);
          });
        });

        world.modelCache[glbPath] = modelPromise;
        modelPromise.then((model) => {
          resolve(this.createSpawnPoint(model, world));
        });
      }
    });
  }

  private createSpawnPoint(
    model: THREE.Object3D,
    world: World
  ): VehicleSpawnPoint {
    let vehicle: Vehicle = this.getNewVehicleByType(
      model,
      this.object.userData.type,
      world
    ) as Vehicle;
    vehicle.name = this.object.userData.name;
    vehicle.spawnPoint = this.object;

    let worldPos = new THREE.Vector3();
    let worldQuat = new THREE.Quaternion();
    this.object.getWorldPosition(worldPos);
    this.object.getWorldQuaternion(worldQuat);

    vehicle.setPosition(worldPos.x, worldPos.y + 1, worldPos.z);
    vehicle.collision.quaternion.copy(Utils.cannonQuat(worldQuat));
    world.add(vehicle);

    if (vehicle.name.includes("BRDM")) {
      vehicle.materials.forEach((mat) => {
        (mat as THREE.MeshStandardMaterial).transparent = true;
      });
      if (vehicle.type === "Mesh") {
        (
          (vehicle as unknown as THREE.Mesh)
            .material as THREE.MeshStandardMaterial
        ).transparent = true;
      }

      vehicle.traverse((child: THREE.Object3D) => {
        if ((child as THREE.Mesh).isMesh) {
          (
            (child as THREE.Mesh).material as THREE.MeshStandardMaterial
          ).transparent = true;
        }
        vehicle.setVisible(false, 1);
      });
    }

    /// TODO - remove reliance on characters driving. create vehicle follow behaviours.

    //const charModel = new THREE.Object3D();
    const charModel: any = {};
    charModel.scene = new THREE.Object3D();
    charModel.animations = [];
    let character = new Character(charModel, world, false);
    world.add(character);
    character.teleportToVehicle(vehicle, vehicle.seats[0]);
    character.setBehaviour(new VehicleHalt(character));
    character.name = vehicle.name;
    this.entity = vehicle;
    setTimeout(() => (vehicle as Car).setFullStop(), 2000);

    // console.log(worldPos);
    const result = world.getTerrainRaycast(worldPos.x, worldPos.z);
    vehicle.setPosition(worldPos.x, result.point.y - 30.5, worldPos.z);

    // console.log(worldPos.x, result.point.y + 1, worldPos.z);
    return this;
  }

  private getNewVehicleByType(
    model: any,
    type: VehicleType,
    world: World
  ): Vehicle | void {
    switch (type) {
      case VehicleType.Car:
        return new Car(model, world);
      case VehicleType.Gwagon:
        return new Car(model, world);
      case VehicleType.Boxer:
        return new Car(model, world);
      case VehicleType.Hawkei:
        return new Car(model, world);
      case VehicleType.BRDM:
        return new Car(model, world);
    }
  }
}
