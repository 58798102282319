import { AudioManager } from "./../../core/AudioManager";
import { Sequence } from "./Sequence";

import { World } from "../../world/World";
import * as THREE from "three";

export class Scenario2 extends Sequence {
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_2";
    this.units = ["gwagon1"];
    this.unitType = "ADO";
    this.target = new THREE.Vector3(-107, 100, 115);
  }

  movementStage(): void {
    super.movementStage();
  }
}
