import { Sequence } from "./Sequence";
import { ScenarioStage } from "../../enums/ScenarioStage";
import { World } from "../../world/World";
import * as THREE from "three";
import { RadarScan } from "../../overlays/RadarScan";
export class Scenario12 extends Sequence {
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_12";
    this.units = ["inf1"];
    this.unitType = "INS";
    this.target = new THREE.Vector3(-92, 100, 100);
  }

  override movementStage() {
    super.movementStage();
    setTimeout(() => this.changeState(ScenarioStage.Ended), 4000);
  }
}
