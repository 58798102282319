import * as THREE from "three";

import { ICharacterState } from "../../interfaces/ICharacterState";
import { Character } from "../Character";
import {
  CoverIdle,
  CrouchCoverIdle,
  CrouchIdle,
  Idle,
  Sprint,
  Walk,
  StartProne,
} from "./_stateLibrary";

export abstract class CharacterStateBase implements ICharacterState {
  public character: Character;
  public timer: number;
  public animationLength: any;

  constructor(character: Character) {
    this.character = character;

    this.character.velocitySimulator.damping =
      this.character.defaultVelocitySimulatorDamping;
    this.character.velocitySimulator.mass =
      this.character.defaultVelocitySimulatorMass;

    this.character.rotationSimulator.damping =
      this.character.defaultRotationSimulatorDamping;
    this.character.rotationSimulator.mass =
      this.character.defaultRotationSimulatorMass;

    this.character.arcadeVelocityIsAdditive = false;
    this.character.setArcadeVelocityInfluence(1, 0, 1);

    this.timer = 0;
  }

  public update(timeStep: number): void {
    this.timer += timeStep;
  }

  public onInputChange(): void {
    //special cases ending
    if (this.character.actions.crouch.justReleased && this.noSpecialState())
      this.character.setState(new Idle(this.character));

    if (this.character.actions.cover.justReleased && this.noSpecialState()) {
      this.character.setState(new Idle(this.character));
    }

    if (this.character.actions.prone.justReleased && this.noSpecialState()) {
      this.character.setState(new StartProne(this.character));
    }

    //handle node charState transitions
    if (this.character.actions.crouch.justPressed)
      this.character.setState(new CrouchIdle(this.character));
    if (this.character.actions.cover.justPressed) {
      if (this.character.actions.crouch.isPressed) {
        this.character.setState(new CrouchCoverIdle(this.character));
      } else {
        this.character.setState(new CoverIdle(this.character));
      }
    }
    if (this.character.actions.prone.justPressed) {
      this.character.setState(new StartProne(this.character));
    }
  }

  public noDirection(): boolean {
    return (
      !this.character.actions.up.isPressed &&
      !this.character.actions.down.isPressed &&
      !this.character.actions.left.isPressed &&
      !this.character.actions.right.isPressed
    );
  }

  public noSpecialState(): boolean {
    return (
      !this.character.actions.crouch.isPressed &&
      !this.character.actions.prone.isPressed &&
      !this.character.actions.cover.isPressed
    );
  }

  public anyDirection(): boolean {
    return (
      this.character.actions.up.isPressed ||
      this.character.actions.down.isPressed ||
      this.character.actions.left.isPressed ||
      this.character.actions.right.isPressed
    );
  }

  public fallInAir(): void {
    //if (!this.character.rayHasHit) { this.character.setState(new Falling(this.character)); }//falling disabled until we can guarantee lerping will stop while raycast hit is true
  }

  public animationEnded(timeStep: number): boolean {
    if (this.character.mixer !== undefined) {
      if (this.animationLength === undefined) {
        console.error(
          this.constructor.name +
            "Error: Set this.animationLength in state constructor!"
        );
        return false;
      } else {
        return this.timer > this.animationLength - timeStep;
      }
    } else {
      return true;
    }
  }

  public setAppropriateDropState(): void {
    if (this.character.actions.run.isPressed) {
      this.character.setState(new Sprint(this.character));
    } else {
      this.character.setState(new Walk(this.character));
    }
  }

  public setAppropriateStartWalkState(): void {
    /*
		let range = Math.PI;
		let angle = Utils.getSignedAngleBetweenVectors(this.character.orientation, this.character.getCameraRelativeMovementVector());

		if (angle > range * 0.8)
		{
			this.character.setState(new StartWalkBackLeft(this.character));
		}
		else if (angle < -range * 0.8)
		{
			this.character.setState(new StartWalkBackRight(this.character));
		}
		else if (angle > range * 0.3)
		{
			this.character.setState(new StartWalkLeft(this.character));
		}
		else if (angle < -range * 0.3)
		{
			this.character.setState(new StartWalkRight(this.character));
		}
		else
		{
			this.character.setState(new StartWalkForward(this.character));
		}
		*/
  }

  protected playAnimation(
    animName: string,
    fadeIn: number,
    callback?: () => void,
    randomStart?: boolean
  ): void {
    this.animationLength = this.character.setAnimation(
      animName,
      fadeIn,
      callback,
      randomStart
    );
  }
}
