import { useRef, useState } from "react";

import { Box, Heading, HStack, Image } from "@chakra-ui/react";
import pennantLinesSrc from "../assets/pennant-lines.svg";
import infoIconSrc from "../assets/info-icon.svg";
import useStore, { NavState } from "../useStore";
import { useNavigate } from "react-router-dom";

const SectionHeader = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  return (
    <HStack
      color="rgba(255, 255, 255, 0.5)"
      fontSize="14px"
      lineHeight="16.8px"
      justifyContent="space-between"
      height="64px"
      width="100%"
    >
      <Box textTransform="uppercase">
        <Heading
          variant="clock"
          fontSize="14px"
          color="rgba(255, 255, 255, 0.4)"
        >
          {children}
        </Heading>
      </Box>
      <Image src={infoIconSrc} onClick={() => navigate("/roles")} />
    </HStack>
  );
};

export default SectionHeader;
