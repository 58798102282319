import { ParticleFxManager } from "./../particles/ParticleFXManager";
import { ParticleFX } from "./../particles/ParticleFX";
import * as CANNON from "cannon-es";
import * as THREE from "three";

import * as Utils from "../core/FunctionLibrary";
import { KeyBinding } from "../core/KeyBinding";
import { EntityType } from "../enums/EntityType";
import { IControllable } from "../interfaces/IControllable";
import { SpringSimulator } from "../physics/spring_simulation/SpringSimulator";
import { World } from "../world/World";
import { Vehicle } from "./Vehicle";

export class Car extends Vehicle implements IControllable {
  public entityType: EntityType = EntityType.Car;
  public drive: string = "awd";
  public dust: ParticleFX;
  public emittingDust = false;
  // private wheelsDebug: THREE.Mesh[] = [];

  //private airSpinTimer: number = 0;

  private steeringSimulator: SpringSimulator;
  private gear: number = 1;

  // Transmission
  private shiftTimer: number = 0;
  private timeToShift: number = 0.2;

  //private canTiltForwards: boolean = false;
  //private characterWantsToExit: boolean = false;

  constructor(gltf: any, world: World) {
    super(gltf, world, {
      radius: 1.5,
      suspensionStiffness: 15,
      suspensionRestLength: 0.5,
      maxSuspensionTravel: 0.3,
      frictionSlip: 2,
      dampingRelaxation: 0.9,
      dampingCompression: 0.9,
      rollInfluence: 0.8,
      maxSuspensionForce: 100000,
    });

    this.readCarData(gltf);

    //this.collision.preStep = (body: CANNON.Body) => { this.physicsPreStep(body, this); };
    this.world = world;

    this.dust = this.world.particleManager.get("dust");
    this.add(this.dust.object!);
    this.dust.object?.position.set(0, 1, -3);
    this.dust.restart();
    this.dust.pause();

    /*
    this.world!.physicsWorld.addEventListener("preStep", () => {
      this.physicsPreStep(this.collision);
    });
*/
    this.actions = {
      throttle: new KeyBinding("KeyW"),
      reverse: new KeyBinding("KeyS"),
      brake: new KeyBinding("Space"),
      left: new KeyBinding("KeyA"),
      right: new KeyBinding("KeyD"),
      exitVehicle: new KeyBinding("KeyF"),
      seat_switch: new KeyBinding("KeyX"),
      view: new KeyBinding("KeyV"),
    };

    this.steeringSimulator = new SpringSimulator(60, 10, 0.6);
  }

  public noDirectionPressed(): boolean {
    let result =
      !this.actions.throttle.isPressed &&
      !this.actions.reverse.isPressed &&
      !this.actions.left.isPressed &&
      !this.actions.right.isPressed;

    return result;
  }

  public update(timeStep: number): void {
    super.update(timeStep);

    let speed = this.speed;
    if (speed > 10 && !this.rayCastVehicle.shouldBeStationary) {
      this.emitDust(true);
    } else {
      this.emitDust(false);
    }

    // Engine
    const engineForce = 15000;
    const maxGears = 3;
    const gearsMaxSpeeds: {
      [gear: string]: number;
    } = {
      R: -12,
      "0": 0,
      "1": 15,
      "2": 27,
      "3": 13 * 3,
      "4": 17 * 3,
      "5": 22 * 3,
    };

    if (
      !this.actions.reverse.isPressed &&
      !this.actions.throttle.isPressed &&
      this.actions.brake.isPressed &&
      this.targetMoveSpeed === 0
    ) {
      //we should be stopped
      //console.log("force stop", this.name);
      this.gear = 0;
      return;
    }

    if (this.shiftTimer > 0) {
      this.shiftTimer -= timeStep;
      if (this.shiftTimer < 0) this.shiftTimer = 0;
    } else {
      // mediate breaking top speed

      /*
      const vec = new CANNON.Vec3();
      if (vec.x > 0.125 && targetMaxSpeed > 3) {
        //we are on a steepish downward slope (in radians)
        targetMaxSpeed = 3;
        this.log("on slope");
        if (Math.abs(this.speed) > targetMaxSpeed) {
          this.actions.brake.isPressed = true;
          this.actions.throttle.isPressed = false;
          this.actions.reverse.isPressed = false;
        }
      }
      */

      // Transmission
      if (this.actions.reverse.isPressed) {
        let powerFactor = 0.2;
        if (speed > -1) {
          powerFactor = 0.1;
        }
        const force = (engineForce / this.gear) * Math.abs(powerFactor) ** 1; // 7 is fudge as force seems to overcome brakes for some reason
        this.applyEngineForce(force);
      } else {
        let powerFactor =
          (gearsMaxSpeeds[this.gear.toString()] - this.speed) /
          (gearsMaxSpeeds[this.gear] - gearsMaxSpeeds[this.gear - 1]);
        if (!isFinite(powerFactor)) {
          powerFactor = 0.01;
        }
        if (powerFactor < 0.1 && this.gear < maxGears) this.shiftUp();
        else if (this.gear > 1 && powerFactor > 1.2) this.shiftDown();
        else if (this.actions.throttle.isPressed) {
          const force = (engineForce / this.gear) * powerFactor ** 1;
          this.applyEngineForce(-force);
        }
      }
    }

    // Steering
    //this.steeringSimulator.simulate(timeStep);
    // this.setSteeringValue(this.steeringSimulator.position);

    if (
      this.rayCastVehicle.numWheelsOnGround < 3 &&
      Math.abs(this.collision.velocity.length()) < 0.5
    ) {
      this.collision.quaternion.copy(this.collision.initQuaternion);
    }
  }

  public shiftUp(): void {
    this.gear++;
    this.shiftTimer = this.timeToShift;

    this.applyEngineForce(0);
  }

  public shiftDown(): void {
    this.gear--;
    this.shiftTimer = this.timeToShift;

    this.applyEngineForce(0);
  }

  public emitDust(val: boolean) {
    if (val === this.emittingDust) return;
    if (val) {
      this.dust.play();
      this.dust.loop(true);
    } else {
      this.dust.loop(false);
    }
    this.emittingDust = val;
  }

  public physicsPreStep(body: CANNON.Body): void {
    super.physicsPreStep(body);
    // Constants
    const quat = Utils.threeQuat(body.quaternion);
    const forward = new THREE.Vector3(0, 0, 1).applyQuaternion(quat);

    this.speed = this.collision.velocity.dot(Utils.cannonVector(forward));
  }

  public onInputChange(): void {
    super.onInputChange();

    const brakeForce = 20;

    if (
      this.actions.throttle.justReleased ||
      this.actions.reverse.justReleased
    ) {
      this.applyEngineForce(0);
    }
    if (this.actions.brake.justPressed) {
      this.setBrake(brakeForce);
      //this.applyEngineForce(0);
    }
    if (this.actions.brake.justReleased) {
      this.setBrake(0);
    }
  }

  override setFullStop(): void {
    super.setFullStop();
    this.emitDust(false);
    this.speed = 0;
  }

  public readCarData(gltf: any): void {
    gltf.scene.traverse((child: THREE.Object3D) => {
      if (Object.prototype.hasOwnProperty.call(child, "userData")) {
        if (Object.prototype.hasOwnProperty.call(child, "data")) {
          //
        }
      }
    });
  }
}
