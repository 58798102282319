import { Sequence } from "./Sequence";
import { ScenarioStage } from "../../enums/ScenarioStage";
import { World } from "../../world/World";
import * as THREE from "three";
import { RadarScan } from "../../overlays/RadarScan";
export class Scenario11 extends Sequence {
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_11";
    this.units = ["obs1"];
    this.unitType = "AOB";
    //this.target = new THREE.Vector3(-92, 100, 100);
    this.promptUnit = true;
  }

  override movementStage() {
    super.movementStage();
    this.hideTargetPennants();
    this.hideUnitPennants();

    const gwagonPos = this.world.getCharacterByName("obs1")!.position;
    // AudioManager.playSFX("sfx_radarDetect");
    const visual = new RadarScan(
      this.world,
      gwagonPos,
      this.world.getVehicleByName("BRDM1")!.position!,
      "./video/ui_visualConfirmation.mp4"
    );
    visual.show();
    setTimeout(() => this.changeState(ScenarioStage.Ended), 7000);
  }
}
