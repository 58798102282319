import { ReactNode, useRef, useState } from "react";

import { Box } from "@chakra-ui/react";

type Props = {
  children: ReactNode;
  onClick?: () => void;
};

const ButtonAlt = ({ children, onClick }: Props) => {
  return (
    <Box
      as="button"
      height="45px"
      lineHeight="1.2"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      border="1px"
      px="8px"
      fontSize="14px"
      textTransform="uppercase"
      fontWeight="semibold"
      minWidth="175px"
      boxShadow="0px 0px 6px #F1B700"
      borderColor="#F1B700"
      fontFamily={"GeomGraphic-Semibold, sans-serif"}
      bg="rgba(241, 183, 0, .20) "
      transform="scale(0.98)"
      color="#ffffff"
      _active={{
        bg: "rgba(241, 183, 0, .20) ",
        transform: "scale(0.98)",
        color: "#ffffff",
      }}
      _focus={{}}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default ButtonAlt;
