import { Sequence } from "./Sequence";
import { World } from "../../world/World";
import { AudioManager } from "../../core/AudioManager";
import * as THREE from "three";
import { Idle } from "../../characters/character_states/_stateLibrary";
import { ScenarioStage } from "../../enums/ScenarioStage";
export class Scenario7 extends Sequence {
  // fight in house
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_7";
    this.units = ["inf1"];
    this.unitType = "INS";
    //this.target = new THREE.Vector3(77, 100, 18);
    this.promptUnit = true;
  }

  override inputWaitStage(): void {
    super.inputWaitStage();
    this.setDirectionFromFormation();
  }

  setDirectionFromFormation() {
    const inf1 = this.world.getCharacterByName("inf1")!;
    const inf2 = this.world.getCharacterByName("inf2")!;
    const inf3 = this.world.getCharacterByName("inf3")!;
    const inf4 = this.world.getCharacterByName("inf4")!;
    const squad = [inf1, inf2, inf3, inf4];

    const formation = this.world.formations.find(
      (form) => form.name === "roomclear"
    );

    formation?.nodes.forEach((node, i) => {
      const pos = new THREE.Vector3();
      node.object.getWorldPosition(pos);
      squad[i].setPosition(pos.x, pos.y, pos.z);
      const vec = new THREE.Vector3(0, 0, 1).applyQuaternion(
        node.object.quaternion
      );
      squad[i].setOrientation(vec);
    });
  }

  override movementStage(): void {
    super.movementStage();
    this.hidePrompts();
    this.hideTargetPennants();
    this.hideUnitPennants();
    this.setDirectionFromFormation();
    AudioManager.playSFX("sfx_buildingBreach");
    this.world
      .getPropByName("breach_house1")!
      .setAnimation("HS_gun_flashes", false);

    const inf1 = this.world.getCharacterByName("inf1")!;
    const inf2 = this.world.getCharacterByName("inf2")!;
    const inf3 = this.world.getCharacterByName("inf3")!;
    const inf4 = this.world.getCharacterByName("inf4")!;

    inf1.setAnimation("house_breach_" + inf1.formationSlot, 0.1, () => {
      inf1.setState(new Idle(inf1));
      this.changeState(ScenarioStage.Ended);
    });
    inf2.setAnimation("house_breach_" + inf2.formationSlot, 0.1, () => {
      inf2.setState(new Idle(inf2));
    });
    inf3.setAnimation("house_breach_" + inf3.formationSlot, 0.1, () => {
      inf3.setState(new Idle(inf3));
    });
    inf4.setAnimation("house_breach_" + inf4.formationSlot, 0.1, () => {
      inf4.setState(new Idle(inf4));
    });
  }

  /*

  override inputWaitStage(): void {
    //replace with VO length
    this.waitTimeout = window.setTimeout(
      () => this.changeState(ScenarioStage.Ended),
      1000
    );
  }

  override movementStage(): void {
    super.movementStage();
    //replace with VO length
    this.waitTimeout = window.setTimeout(
      () => this.changeState(ScenarioStage.Ended),
      3000
    );
  }
  */
}
