import * as THREE from "three";

import { FormationNode } from "./FormationNode";

export class Formation {
  public nodes: FormationNode[] = [];
  public name: string;
  public root: THREE.Object3D;

  private _offset = new THREE.Vector3();
  private _worldTarget = new THREE.Vector3();
  private _worldRotation = new THREE.Quaternion();
  constructor(root: THREE.Object3D) {
    this.root = root;
    this.name = root.userData.name;
    //console.log("loading formation", this.name);

    this.root.children.forEach((child) => {
      let node = new FormationNode(child, this);
      //console.log(node.name);
      this.nodes.push(node);
    });

    //order by object name
    this.nodes.sort((a, b) => a.name.localeCompare(b.name));
  }

  public getNode(index: number): FormationNode {
    return this.nodes[index];
  }

  public getFormationTargetRotation(
    index: number,
    target: THREE.Object3D,
    debug = false
  ): THREE.Quaternion {
    this.nodes[index].object.getWorldQuaternion(this._worldRotation);
    return this._worldRotation;
  }

  public getFormationTargetPosition(
    index: number,
    target: THREE.Object3D,
    debug = false
  ): THREE.Vector3 {
    this._offset.copy(this.nodes[index].object.position);
    this._offset.applyQuaternion(target.quaternion);
    target.getWorldPosition(this._worldTarget);
    this._worldTarget.sub(this._offset);
    if (debug) {
      console.log("nodeName", this.nodes[index].object.name);
      console.log("targetPos", target.position);
      console.log("worldtarget", this._worldTarget);
      console.log("_offset", this._offset);
    }
    return this._worldTarget;
  }

  public getFormationAbsolutePosition(
    index: number,
    target: THREE.Object3D,
    debug = false
  ): THREE.Vector3 {
    this.nodes[index].object.getWorldPosition(this._worldTarget);
    return this._worldTarget;
  }
}
