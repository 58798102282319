import { Character } from "../Character";
import { CharacterStateBase, EndWalk, Walk } from "./_stateLibrary";

export class Sprint extends CharacterStateBase {
  constructor(character: Character) {
    super(character);

    this.character.velocitySimulator.mass = 10;
    this.character.rotationSimulator.damping = 0.8;
    this.character.rotationSimulator.mass = 50;

    this.character.setArcadeVelocityTarget(1.4);
    this.playAnimation(character.alertness + "_fwd_run", 0.1, undefined, true);
  }

  public update(timeStep: number): void {
    super.update(timeStep);
    this.character.setCameraRelativeOrientationTarget();
    this.fallInAir();
  }

  public onInputChange(): void {
    super.onInputChange();

    if (!this.character.actions.run.isPressed) {
      this.character.setState(new Walk(this.character));
    }

    if (this.noDirection()) {
      this.character.setState(new EndWalk(this.character));
    }
  }
}
