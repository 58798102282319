import ButtonBase from "../Components/ButtonBase";

import PaddedLayout from "../Layouts/PaddedLayout";
import SectionHeader from "../Components/SectionHeader";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Heading,
  Stack,
  VStack,
  Image,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import armyChallengeSrc from "../assets/logo-challenge-army.png";
import { useEffect } from "react";
import titleGraphic from "../assets/title-graphic.png";

const Instructions = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }, []);
  return (
    <>
      <PaddedLayout>
        <VStack
          spacing={6}
          justifyContent="space-between"
          margin="auto"
          position="absolute"
          bottom="25px"
          top="25px"
          left="25px"
          right="25px"
        >
          <VStack width="100%">
            <SectionHeader>ARMY COMBAT ROLES</SectionHeader>
            <VStack marginTop="100px" width="100%">
              <Image src={titleGraphic}></Image>
              <Heading marginTop="50px !important" variant="large">
                Terms and Conditions
              </Heading>
            </VStack>
          </VStack>
          <VStack spacing="25px" maxWidth="500px">
            <VStack
              spacing="25px"
              backgroundColor="rgba(51, 51, 51, 0.7)"
              backdropFilter="blur(4px)"
              padding="25px"
              flexDir="column"
              alignItems="center"
            >
              <Text>
                Interactive Battlespace is an Augmented Reality website that
                allows individuals to explore the range of combat roles
                available in the Australian Army. It is accessible via both
                desktop computer or mobile and launched by individuals accessing
                a QR code or through the URL: www.interactivebattlespace.com.au.
                The images, voices and scenes contained in the Interactive
                Battlespace are not designed or intended to replicate any person
                or real-life environment, and are not be used for any other
                purpose than that indicated above.
              </Text>
              <Text>Copyright</Text>
              <Text>
                Copyright in Interactive Battlespace is held by Defence.
                Interactive Battlespace cannot be downloaded, saved, copied or
                modified without the written consent of Defence (through Defence
                Force Recruiting). Any questions about Interactive Battlespace
                can be sent to Defence Force Recruiting at{" "}
                <b>
                  <a href="mailto:digital@dfr.com.au"> digital@dfr.com.au</a>
                </b>
                .
              </Text>
              <Text>Redirection to Websites</Text>
              <Text>
                In accessing Interactive Battlespace, individuals may be
                referred to other websites. Individuals should ensure they
                review any terms and conditions of those websites. Defence Force
                Recruiting shall not be liable for an individual's use (or
                otherwise) of linked websites.{" "}
              </Text>
              <Text>Privacy</Text>
              <Text>
                This Privacy Statement is provided in accordance with Australian
                Privacy Principle 5 as contained in the Privacy Act 1988 (Cth)
                (the 'Privacy Act').
              </Text>
              <Text>
                By accessing the Interactive Battlespace website, information
                such as your internet protocol (IP) address, data on pages you
                access, the type of operating system you are using, your
                location, your mobile network information, and web-log data
                (such as browser type) may be collected and stored by Defence
                Force Recruiting. Access to these items allows Defence Force
                Recruiting to provide you with a suitable experience when you
                access the features of the Interactive Battlespace website. In
                addition, information may be collected by Google Analytics and
                8thwall (the creator of the platform).{" "}
              </Text>
              <Text>
                In accessing the Interactive Battlespace, you will be given the
                opportunity to allow your camera to be turned on. By turning on
                your camera, you will see the Augmented Reality Battlespace
                displayed in your real-life environment. No images visible by
                turning on your camera will be collected or stored by Defence
                Force Recruiting. Opting out of camera usage will impact upon
                your ability to participate in the Interactive Battlespace
                experience.
              </Text>
              <Text>
                Interactive Battlespace also uses cookies to distinguish you
                from other users and to increase your access experience. You may
                deactivate cookie tracking through the settings of your device.{" "}
              </Text>
              <Text>
                Defence Force Recruiting will not use or disclose your personal
                information other than for the purposes specified above, unless
                such use or disclosure is in accordance with the Privacy Act.
                Defence Force Recruiting will retain your personal information
                for no longer than is necessary under law. You may contact
                Defence Force Recruiting at any time if you would like to access
                your personal information or if you require further details
                about the personal information Defence Force Recruiting holds
                about you.
              </Text>
              <Text>
                If you have any questions in relation to the information
                contained in this Privacy Statement, you can contact
                <b>
                  <a href="mailto:digital@dfr.com.au"> digital@dfr.com.au</a>
                </b>
                .
              </Text>
              <ButtonBase onClick={() => navigate("/")}>HOME</ButtonBase>
            </VStack>
            <Image
              marginBottom="50px !important"
              width="129px"
              src={armyChallengeSrc}
            />
          </VStack>
        </VStack>
      </PaddedLayout>
    </>
  );
};

export default Instructions;
