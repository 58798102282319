import { Character } from "../characters/Character";
import { World } from "../world/World";
import { Pennant } from "./Pennant";
import * as THREE from "three";
import { LottieLoader } from "three/examples/jsm/loaders/LottieLoader.js";

//import { LottiePlayer }  from "lottie-web";
//(window as any).bodymovin = LottiePlayer;

export class CharacterPennant extends Pennant {
  leadCharacter: Character;
  unitSprites: THREE.Sprite[] = [];
  lottieAnim: any;
  public height = 20;
  constructor(leadCharacter: Character, world: World, icon: string) {
    super(world, icon);
    this.leadCharacter = leadCharacter;
    this.name = leadCharacter.name + "Pennant";
    this.addGroupSprites();

    this.promptIcon.position.y += this.iconScale / 2;
    this.promptParticle.object!.position.y += this.iconScale / 2;
    this.promptIcon.visible = false;
    const flagSprite = this.createFlagSprite(icon);
    this.createCradleSprite();

    this.world!.bloomObjects.add(flagSprite);
    this.updateGroupPos();
    this.hide();
  }

  createCradleSprite(): THREE.Sprite {
    const icon = new THREE.TextureLoader().load("./tex/gfx_iconCradle.png");
    const cradleMat = this.createSpriteMaterial(icon);
    const cradleSprite = new THREE.Sprite(cradleMat);
    cradleSprite.center = new THREE.Vector2(0.5, 0);
    let size = new THREE.Vector3(257, 89, 100).divideScalar(24); // in pixels
    cradleSprite.scale.copy(size);
    cradleSprite.position.set(0, this.iconScale - 0.35, 0);
    this.add(cradleSprite);
    return cradleSprite;
  }

  override createBallSprite(): THREE.Sprite {
    const icon = new THREE.TextureLoader().load("./tex/gfx_unitCenter.png");
    const ballSpriteMat = this.createSpriteMaterial(icon);

    const ballSprite = new THREE.Sprite(ballSpriteMat);
    ballSprite.center = new THREE.Vector2(0.5, 0.5);
    ballSprite.scale.set(6, 6, 6);
    this.world?.graphicsWorld.add(ballSprite);
    //ballSprite.renderOrder = 99;
    return ballSprite;
  }

  createFlagSprite(unit: string): THREE.Sprite {
    // icon.minFilter = THREE.LinearFilter;

    const flagSpriteMat = new THREE.SpriteMaterial({
      color: 0xffffff,
      sizeAttenuation: true,
    });

    const flagSprite = new THREE.Sprite(flagSpriteMat);
    flagSprite.center = new THREE.Vector2(0.5, 0.0);
    let size = new THREE.Vector3(220, 228, 100).divideScalar(20); // in pixels
    flagSprite.scale.copy(size);
    flagSprite.position.set(0, this.iconScale + 1, 0);
    this.add(flagSprite);

    const loader = new LottieLoader();
    loader.setQuality(1);
    loader.load(`./lottie/ui_icn_${unit}.json`, (tex: any) => {
      this.lottieAnim = tex.animation;
      this.lottieAnim.loop = false;
      this.lottieAnim.autoplay = false;
      tex.encoding = THREE.sRGBEncoding;
      flagSprite.material.map = tex;
    });
    return flagSprite;
  }

  override setPosition(position: THREE.Vector3): void {
    super.setPosition(position);
  }

  override update(timestep: number, unscaledTimeStep: number): void {
    if (!this.visible || !this.leadCharacter) return;
    this.updateGroupPos();
    super.update(timestep, unscaledTimeStep);

    // this.updateDecal(this.getRaycast());
  }

  addGroupSprites() {
    if (this.leadCharacter.name.includes("boxer")) {
      let boxer2 = this.world?.getVehicleByName("boxer2");
      let boxer3 = this.world?.getVehicleByName("boxer3");
      const sprite2 = this.createUnitSprite();
      boxer2!.add(sprite2);
      this.unitSprites.push(sprite2);

      const sprite3 = this.createUnitSprite();
      boxer3!.add(sprite3);
      this.unitSprites.push(sprite3);
    }

    const sprite = this.createUnitSprite();
    this.leadCharacter.add(sprite);
    this.unitSprites.push(sprite);
  }

  createUnitSprite(): THREE.Sprite {
    const icon = new THREE.TextureLoader().load("./tex/gfx_unitModel.png");
    const unitSpriteMat = this.createSpriteMaterial(icon);
    const unitSprite = new THREE.Sprite(unitSpriteMat);
    unitSprite.scale.set(2.5, 2.5, 2.5);
    return unitSprite;
  }

  updateGroupPos() {
    // this.position.copy(this.leadCharacter.position);

    this.leadCharacter.getWorldPosition(this._avgPos);
    this.leadCharacter.followers.forEach((follower) => {
      follower.getWorldPosition(this._sumPos);
      this._avgPos.add(this._sumPos);
    });

    this._avgPos.divideScalar(this.leadCharacter.followers.length + 1);
    this._avgPos.y = this.height;
    this.position.copy(this._avgPos);
  }

  public override show(): void {
    super.show();
    if (this.unitSprites)
      this.unitSprites.forEach((sprite) => (sprite.visible = true));
    if (this.lottieAnim) {
      this.lottieAnim.goToAndPlay(0, true);
    }
  }
  public override hide(): void {
    super.hide();
    if (this.unitSprites)
      this.unitSprites.forEach((sprite) => (sprite.visible = false));
  }

  public override dispose(): void {
    super.dispose();
  }
}
