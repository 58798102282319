import * as THREE from "three";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";

import { ClosestObjectFinder } from "../../core/ClosestObjectFinder";
import * as Utils from "../../core/FunctionLibrary";
import { EntityType } from "../../enums/EntityType";
import { StopState } from "../../enums/StopState";
import { ICharacterAI } from "../../interfaces/ICharacterAI";
import { Car } from "../../vehicles/Car";
import { Vehicle } from "../../vehicles/Vehicle";
import { Formation } from "../../world/Formation";
import { PathNode } from "../../world/PathNode";
import { Character } from "../Character";
import { Idle } from "../character_states/_stateLibrary";
import { FollowPath } from "./FollowPath";
import { ApproachMode, FollowTarget } from "./FollowTarget";

export class FollowCharacter extends FollowTarget implements ICharacterAI {
  public character: Character;

  public targetCharacter: Character;
  public targetNode?: PathNode;
  private targetPosWithOffset = new THREE.Vector3(0, 0, 0);

  public formation: Formation | undefined;
  constructor(character: Character, targetCharacter: Character) {
    super(
      character,
      targetCharacter.position,
      targetCharacter.quaternion,
      4,
      3
    ); //initial targetChar position not used, updated immediately

    this.targetCharacter = targetCharacter;
    this.character = character;
    if (this.character.controlledObject) this.isVehicle = true;
    if (this.isVehicle) {
      this.terminalDistance = 6;
      this.character.controlledObject!.triggerAction("brake", false);
    } else {
      this.terminalDistance = 4;
    }
    //  debug

    const boxMat = new THREE.MeshLambertMaterial({
      color: 0x0000ff,
    });
    this.targetDebugBox.scale.set(0.2, 0.3, 0.2);
    this.targetDebugBox.material = boxMat;
    this.character.world!.graphicsWorld.add(this.targetDebugBox);

    this.setTargetCharacter(targetCharacter);
  }
  finish(): void {
    throw new Error("Method not implemented.");
  }

  public override showDebugBox(show: boolean) {
    this.showDebug = show;
    this.targetDebugBox.visible = show;
  }

  public setTargetCharacter(targetCharacter: Character): void {
    if (this.targetCharacter && this.targetCharacter !== targetCharacter) {
      //TODO - we've switched follow targets. do cleanup of followers list
    }
    this.targetCharacter = targetCharacter;

    if (
      this.targetCharacter.behaviour &&
      this.targetCharacter.behaviour instanceof FollowPath
    ) {
      const targetNode = (this.targetCharacter.behaviour as FollowPath)
        .targetNode;
      if (targetNode.formation) {
        this.formation = this.character.world?.getFormationByName(
          targetNode.formation
        );
      }
      if (targetNode.speed) {
        this.character.setMoveSpeed(targetNode.speed);
      }

      this.targetCharacter.followers.push(this.character);
    }
    this.approachMode = ApproachMode.Loose;
  }

  public getTargetPos() {
    //check if target has updated formation.
    if (!this.targetNode) {
      this.targetNode = (
        this.targetCharacter.behaviour as FollowPath
      ).targetNode;
    }
    if (
      this.formation &&
      this.targetCharacter.behaviour &&
      this.targetCharacter.behaviour instanceof FollowPath
    ) {
      if (
        this.targetNode &&
        this.targetNode.formation &&
        this.targetNode.formation !== this.formation?.name
      ) {
        this.formation = this.character.world?.getFormationByName(
          this.targetNode.formation
        );
        console.log(
          "formation set " +
            this.character.formationSlot +
            " " +
            this.formation?.name
        );
        //console.log(this.character.name, this.targetCharacter.name);
      }

      if (
        (this.approachMode === ApproachMode.Lerp ||
          this.approachMode === ApproachMode.Finished) &&
        this.exact
      ) {
        if (this.targetNode && this.targetNode.staticAnimation) {
          // if this is prep for an animation, we will use the formation nodes literal position and rotation.
          this.targetRot = this.formation!.getFormationTargetRotation(
            this.character.formationSlot,
            this.targetNode.object
          );
          return this.formation!.getFormationAbsolutePosition(
            this.character.formationSlot,
            this.targetNode.object
          );
        }
        return this.formation!.getFormationTargetPosition(
          this.character.formationSlot,
          this.targetNode!.object
        );
      }
    }

    this.targetDebugBox.visible = this.showDebug;
    return this.formation
      ? this.formation.getFormationTargetPosition(
          this.character.formationSlot,
          this.targetCharacter
        )
      : this.targetCharacter.position;
  }

  override update(timeStep: number): void {
    if (this.approachMode === ApproachMode.Lerp) {
      if (this.character.name === "inf2") {
        console.log("ler[p] follow");
      }
    }
    if (this.approachMode === ApproachMode.Finished) {
      this.targetPos = this.getTargetPos();
      this.character.setPosition(
        this.targetPos.x,
        this.targetPos.y,
        this.targetPos.z
      );
      this.character.quaternion.copy(this.targetRot);
    }
    // update followtarget position in realtime
    this.targetPosWithOffset = this.getTargetPos();
    //this.targetPosWithOffset.y = (this.character.position.y + this.targetPosWithOffset.y) /2;  // The difference between this and the other methods is that we use the characters Y, so targets aren't clipping in weird positions on slopes.
    this.targetDebugBox.position.copy(this.targetPosWithOffset);
    this.targetPos = this.targetPosWithOffset;

    if (this.targetNode && this.targetCharacter.behaviour) {
      if (
        !this.targetNode.nextNode &&
        ((this.targetCharacter.behaviour as FollowPath).approachMode ===
          ApproachMode.Waiting ||
          (this.targetCharacter.behaviour as FollowPath).approachMode ===
            ApproachMode.Finished)
      ) {
        this.exact = true;
      } else {
        this.exact = false; // TODO - do we want to give artists control over which nodes are exact?
      }
    }
    super.update(timeStep);

    //reset viewvector to prevent running around like idiots when close to formation target. allow it to get a little behind then speed up.
    // should we even bother with the super.update() call at this point?
    this.targetPos.y = 0;

    let viewVector = new THREE.Vector3();

    if (this.isVehicle) {
      viewVector.subVectors(
        this.targetPos,
        this.character.controlledObject!.position
      );
    } else {
      viewVector.subVectors(this.targetPos, this.character.position);
    }
    viewVector.y = 0;
    /*
    let forward = new THREE.Vector3(0, 0, 1).applyQuaternion(
      this.targetCharacter.quaternion
    );
    */

    let forward = new THREE.Vector3(0, 0, 1).applyQuaternion(
      this.character.quaternion
    );

    let distance = viewVector.length();
    viewVector.normalize();
    forward.normalize();
    let angle = Utils.getSignedAngleBetweenVectors(forward, viewVector);

    if (forward.dot(viewVector) < 1) {
      // target in front - catch up to it
      this.character.setMoveSpeedMultiplier(1.1);
    }

    if (forward.dot(viewVector) > 1) {
      //target behind - slow down a bit
      this.character.setMoveSpeedMultiplier(0.5);
      // this.character.setViewVector(this.targetCharacter.orientation);
      //this.character.setOrientation(this.targetCharacter.orientation, true);
    }

    if (distance < 3) {
      // on target, allow to drop behind slowly to avoid circling
      this.character.setMoveSpeedMultiplier(distance / 3);
      //this.character.setViewVector(this.targetCharacter.orientation);
      //this.character.setOrientation(this.targetCharacter.orientation, true);
    }

    if (this.isVehicle && distance < this.terminalDistance) {
      // on target, allow to drop behind slowly to avoid stop/start driving
      this.character.setMoveSpeedMultiplier(0.8);
      this.reachedTarget();
    }
  }

  override reachedTarget(): void {
    //ignore if target char hasn't also reached target
    if (
      !this.targetCharacter.behaviour ||
      !(this.targetCharacter.behaviour instanceof FollowPath)
    ) {
      // lead char is off doing something else (different behaviour), assume we are good to stay here.

      super.reachedTarget();
      return;
    }

    if (
      this.targetCharacter.behaviour &&
      this.targetCharacter.behaviour instanceof FollowPath && // lead char has stopped
      ((this.targetCharacter.behaviour as FollowPath).approachMode ===
        ApproachMode.Waiting ||
        (this.targetCharacter.behaviour as FollowPath).approachMode ===
          ApproachMode.Finished)
    ) {
      super.reachedTarget();
      this.setStopState(this.targetNode!.stopState);
      return;
    }
  }

  finishedStaticAnimation() {
    //console.log("callback comnplete for", this.character.name);
    this.character.mixer.removeEventListener(
      "finished",
      this.finishedStaticAnimation.bind(this)
    );

    this.character.setState(new Idle(this.character));
    this.character.completedScenario();
    //remove this char from followers
    this.targetCharacter.followers = this.targetCharacter.followers.filter(
      (follower) => {
        return follower !== this.character;
      }
    );
    this.character.clearBehaviour();
  }

  playCharacterAnimation(name: string) {
    this.character.setAnimation(name, 0.1);
  }

  playStaticAnimation(name: string) {
    if (this.character.isRealCharacter) {
      //this.character.setState(new Idle(this.character)); // Why do we need this???????? if never used
      this.character.setAnimation(
        name + "_" + this.character.formationSlot,
        0.1,
        this.finishedStaticAnimation.bind(this)
      );
    }
  }

  dispose(): void {
    this.targetDebugBox.visible = false;
  }

  public teleportToFormation(target: PathNode) {
    //console.log("teleport to", target);
    let targetPos = new THREE.Vector3().copy(target.object.position);

    if (this.formation) {
      targetPos = this.formation.getFormationTargetPosition(
        this.character.formationSlot,
        target.object
      );
    }

    this.character.teleport(targetPos, target.object.quaternion);
  }

  public jumpToEndState(): void {
    //controlled from followpath character
  }
}
