import { AudioManager } from "./../../core/AudioManager";
import { Sequence } from "./Sequence";
import { ScenarioStage } from "../../enums/ScenarioStage";
import { World } from "../../world/World";
import { RadarScan } from "../../overlays/RadarScan";

import { ParticleFX } from "../../particles/ParticleFX";

//8 / artillery air defence detect enemy activity
export class Scenario8 extends Sequence {
  private particles?: ParticleFX;
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_8";
    this.units = ["gwagon1"];
    this.unitType = "ADO";
    this.promptUnit = true;
  }

  override introStage(): void {
    super.introStage();
    this.particles = this.world.particleManager.get("enemyActivity");

    this.particles.object!.position.copy(
      this.world.getVehicleByName("BRDM1")!.position
    );
    this.particles.object!.position.y += 10;
    this.particles.restart();

    setTimeout(() => {
      this.changeState(ScenarioStage.InputWait);
      this.particles?.endEmit();
    }, 5000);
  }
  override inputWaitStage(): void {
    this.changeState(ScenarioStage.Movement);
  }

  override movementStage() {
    super.movementStage();
    this.hideTargetPennants();
    this.hideUnitPennants();

    const gwagonPos = this.world.getVehicleByName("gwagon1")!.position;
    AudioManager.playSFX("sfx_radarDetect");
    const visual = new RadarScan(
      this.world,
      gwagonPos,
      this.world.getVehicleByName("BRDM1")!.position!,
      "./video/ui_radarDetection.mp4",
      true
    );
    visual.show();

    const ping = this.world.particleManager.get("radarPing")!;
    ping.object!.position.set(gwagonPos.x, gwagonPos.y + 10, gwagonPos.z);
    ping.restart();

    setTimeout(() => {
      this.changeState(ScenarioStage.Ended);
      visual.hide();
      ping.stop();
      ping.loop(false);
    }, 5000);

    //let base = this.world.getPropByName("enemyBase");
    let brdm1 = this.world.getVehicleByName("BRDM1");
    let brdm2 = this.world.getVehicleByName("BRDM2");
    let brdm3 = this.world.getVehicleByName("BRDM3");

    setTimeout(() => {
      //base.setVisible(true, 3000);
      //base.setOutline(true);
      brdm1.setVisible(true, 5000);
      brdm2.setVisible(true, 4000);
      brdm3.setVisible(true, 3500);
      brdm1.setOutline(true);
      brdm2.setOutline(true);
      brdm3.setOutline(true);
    }, 2000);

    // TODO - base revealed
    setTimeout(() => {
      this.changeState(ScenarioStage.Ended);
      // base.setOutline(false);
      brdm1.setOutline(false);
      brdm2.setOutline(false);
      brdm3.setOutline(false);
      visual.hide();
    }, 7000);
  }
}
