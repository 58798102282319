import { useContext, useEffect } from "react";
import useStore, { WorldState } from "../useStore";
import { experiencePipelineModule } from "../XR8/experiencePipelineModule";
import * as THREE from "three";
import pennantLinesSrc from "../assets/pennant-lines.svg";
import { World } from "../scene/world/World";
import {
  Box,
  Flex,
  VStack,
  Text,
  useMediaQuery,
  Heading,
  HStack,
  Image,
} from "@chakra-ui/react";
import ARComms from "../Components/ARComms";
import AROrder from "../Components/AROrder";
import SectionHeader from "../Components/SectionHeader";
import LoadingScreen from "../Components/LoadingScreen";
import infoIconSrc from "../assets/info-icon.svg";
import { customThreejsPipelineModule } from "../XR8/customThreeJsPipelineModule";
import ButtonBase from "../Components/ButtonBase";
import { css } from "@emotion/react";
import InputPane from "../Components/InputPane";

import ARStatus from "../Components/ARStatus";
import type { History } from "history";
import {
  useNavigate,
  useLocation,
  UNSAFE_NavigationContext as NavigationContext,
} from "react-router-dom";

import ReactGA from "react-ga4";

function Experience() {
  const { isDesktop, XR8Loaded, worldState, radioComms, radioOrder } = useStore(
    (state) => state
  );
  const [isLandscape] = useMediaQuery("(orientation: landscape)");

  const { setIsDesktop, setWorldState } = useStore((state) => state.api);

  let location = useLocation();

  const navigate = useNavigate();

  const { navigator } = useContext(NavigationContext);
  const history = navigator as unknown as History;
  /*
  useEffect(() => {
    const unlisten = history.listen(() => {
      // don't allow react router navigation away from experience - always reset the app
      window.location.href = window.location.pathname;
    });
    return unlisten;
  }, [history]);
  */

  useEffect(() => {
    document.addEventListener("missionCompleted", missionCompleted);
    return () => {
      document.removeEventListener("missionCompleted", missionCompleted);
    };
  }, []);

  useEffect(() => {
    if (worldState !== WorldState.NONE) return;

    if (!XR8Loaded) return;
    setWorldState(WorldState.LOADING);

    if (isDesktop) {
      const camera = new THREE.PerspectiveCamera(
        80,
        window.innerWidth / window.innerHeight,
        10,
        50000
      );
      const scene = new THREE.Scene();
      const renderer = new THREE.WebGLRenderer({
        canvas: document.getElementById("canvas")!,
        alpha: false,
        powerPreference: "high-performance",
        antialias: false,
        stencil: false,
        //depth: false,
        logarithmicDepthBuffer: true,
      });
      renderer.setSize(window.innerWidth, window.innerHeight);
      const world = new World(
        "/export-models/world.glb",
        scene,
        camera,
        renderer,
        isDesktop
      );
    } else {
      startAR();
    }
  }, [isDesktop, XR8Loaded, worldState]);

  const missionCompleted = () => {
    console.log("Mission complete received");

    navigate("/complete");
  };

  function startAR() {
    /*
    window.XR8.XrController.configure({
      disableWorldTracking: false,
      scale: "absolute",
    });
    */

    const customThreejsPipeline = customThreejsPipelineModule();
    window.XR8.addCameraPipelineModules([
      // Add camera pipeline modules.
      // Existing pipeline modules.
      window.XR8.GlTextureRenderer.pipelineModule(), // Draws the camera feed.
      //(window.XR8 as any).Threejs.pipelineModule(), // Creates a ThreeJS AR Scene.
      customThreejsPipeline,
      window.XR8.XrController.pipelineModule(), // Enables SLAM tracking.
      window.XRExtras.AlmostThere.pipelineModule(), // Detects unsupported browsers and gives hints.
      window.XRExtras.FullWindowCanvas.pipelineModule(), // Modifies the canvas to fill the window.
      window.XRExtras.Loading.pipelineModule(), // Manages the loading screen on startup.
      window.XRExtras.RuntimeError.pipelineModule(), // Shows an error image on runtime error.
      // Custom pipeline modules.
      experiencePipelineModule(),
    ]);

    window.XR8.run({
      canvas: document.getElementById("canvas"),
      allowedDevices: window.XR8.XrConfig.device().MOBILE,
    });
    console.log("AR STarted");
  }

  const sceneReady = () => {
    console.log("Experience: Scene ready");
    setWorldState(WorldState.READY);
    document.dispatchEvent(new CustomEvent("sceneReady", {}));
    ReactGA.event({
      category: "App Events",
      action: "Place",
    });
  };

  return (
    <>
      {worldState === WorldState.LOADING && <LoadingScreen />}

      {worldState === WorldState.PLACEMENT && (
        <>
          <VStack
            zIndex="50"
            height=""
            width="100%"
            position="absolute"
            bottom="25px"
            left="0"
            right="0"
            css={css`
              touch-action: none;
              pointer-events: auto;
            `}
          >
            <Flex flexDir="column" alignItems="center">
              <ButtonBase onClick={sceneReady}>PLACE</ButtonBase>
              <Text
                marginTop="25px"
                variant="geomLight"
                fontSize="11px"
                textAlign="center"
                paddingLeft="20px"
                paddingRight="20px"
              >
                Using one finger, position the AR Battlespace
                <br /> and tap PLACE.
              </Text>
            </Flex>
          </VStack>
          {!isDesktop && <InputPane />}
        </>
      )}

      {worldState === WorldState.READY && (
        <Flex
          width="100vw"
          css={
            isDesktop
              ? css`
                  height: calc(100vh);
                  height: calc(100dvh);
                  max-height: calc(100vh);
                  max-height: calc(100dvh);
                  overflow: hidden;
                  touch-action: none;
                  pointer-events: none;
                `
              : css`
                  height: calc(100vh);
                  height: calc(100dvh);
                  max-height: calc(100vh);
                  max-height: calc(100dvh);
                  overflow: hidden;

                  touch-action: auto;
                  pointer-events: auto;
                `
          }
          margin="auto"
          maxWidth={isDesktop ? "500px" : "100vw"}
          flexDirection="column"
          justifyContent="space-between"
        >
          <VStack
            width={isLandscape && !isDesktop ? "500px" : "100%"}
            marginLeft={isLandscape && !isDesktop ? "auto" : "0"}
            marginRight={isLandscape && !isDesktop ? "auto" : "0"}
          >
            <Box
              background="rgba(0, 0, 0, 0.2)"
              backdropFilter="blur(8px)"
              width="100%"
              paddingLeft="25px"
              paddingRight="25px"
              display={isLandscape && !isDesktop ? "none" : "block"}
            >
              <HStack
                color="rgba(255, 255, 255, 0.5)"
                fontSize="14px"
                lineHeight="16.8px"
                justifyContent="space-between"
                height="64px"
                width="100%"
                css={css`
                  touch-action: auto;
                  pointer-events: auto;
                `}
              >
                <Box textTransform="uppercase">
                  <Heading
                    variant="clock"
                    fontSize="14px"
                    color="rgba(255, 255, 255, 0.4)"
                  >
                    ARMY COMBAT ROLES
                  </Heading>
                </Box>
                <Image
                  src={infoIconSrc}
                  onClick={() => {
                    navigate("/roles");
                    try {
                      ReactGA.event({
                        category: "In Experience Tap",
                        action: "Tap Button",
                        label: "info button top screen",
                      });
                    } catch (error) {}
                  }}
                />
              </HStack>
            </Box>
            {radioOrder && radioOrder.type === "order" && (
              <AROrder radioOrder={radioOrder} />
            )}
            {radioOrder && radioOrder.type === "status" && (
              <ARStatus radioOrder={radioOrder} />
            )}
          </VStack>
          <Flex
            width={isLandscape && !isDesktop ? "500px" : "100%"}
            marginLeft={isLandscape && !isDesktop ? "auto" : "0"}
            marginRight={isLandscape && !isDesktop ? "auto" : "0"}
          >
            {radioComms && <ARComms radioComms={radioComms} />}
          </Flex>
        </Flex>
      )}
    </>
  );
}

export default Experience;
