import { Material } from "@gltf-transform/core";
import * as THREE from "three";
import {
  MeshBasicMaterial,
  MeshPhongMaterial,
  MeshStandardMaterial,
} from "three";
import { World } from "./World";

export class Trees {
  root: THREE.Object3D;
  world: World;
  treeAcacia?: THREE.Mesh;
  treeReeds?: THREE.Mesh;
  treeEucalyptLeaves?: THREE.Mesh;
  treeEucalyptBase?: THREE.Mesh;
  treeScrub?: THREE.Mesh;

  treeAcaciaInstances?: THREE.InstancedMesh;
  treeReedsInstances?: THREE.InstancedMesh;
  treeEucalyptLeavesInstances?: THREE.InstancedMesh;
  treeEucalyptBaseInstances?: THREE.InstancedMesh;
  treeScrubInstances?: THREE.InstancedMesh;

  treeAcaciaNulls: THREE.Object3D[] = [];
  treeReedsNulls: THREE.Object3D[] = [];
  treeEucalyptNulls: THREE.Object3D[] = [];
  treeScrubNulls: THREE.Object3D[] = [];

  constructor(root: THREE.Object3D, world: World) {
    this.root = root;
    this.world = world;
    //get tree originals
    root.children.forEach((child) => {
      if (child.name === "Trees") {
        child.traverse((tree) => {
          if (tree.name === "tree_acacia") {
            this.treeAcacia = tree as THREE.Mesh;

            this.treeAcacia.material = this.fixMaterial(
              this.treeAcacia.material,
              true
            );
            this.treeAcacia.updateMatrix();
          }
          if (tree.name === "shrub") {
            this.treeScrub = tree as THREE.Mesh;

            this.treeScrub.material = new THREE.MeshBasicMaterial({
              transparent: true,
              alphaTest: 0.5,
              map: (this.treeScrub.material as THREE.MeshStandardMaterial).map,
              envMap: this.world.scene.environment,
              side: THREE.DoubleSide,
              reflectivity: 0,
              color: 0xcccccc,
            });
            this.treeScrub.updateMatrix();
          }
          if (tree.name === "tree_reeds") {
            this.treeReeds = tree as THREE.Mesh;

            this.treeReeds.material = this.fixMaterial(
              this.treeReeds.material,
              true
            );

            (this.treeReeds.material as any).envMap =
              this.world.scene.environment;
            this.treeReeds.updateMatrix();
          }
          if (tree.name === "SM_Tree_L_01002001_1") {
            this.treeEucalyptBase = tree as THREE.Mesh;

            this.treeEucalyptBase.material = this.fixMaterial(
              this.treeEucalyptBase.material,
              false
            );
            this.treeEucalyptBase.updateMatrix();
          }
          if (tree.name === "SM_Tree_L_01002001_2") {
            this.treeEucalyptLeaves = tree as THREE.Mesh;

            this.treeEucalyptLeaves.material = this.fixMaterial(
              this.treeEucalyptLeaves.material,
              true
            );
            this.treeEucalyptLeaves.updateMatrix();
          }
        });
      }

      if (child.name === "TreesInstances") {
        var i = 0;

        child.traverse((obj) => {
          if (obj.name.includes("acacia")) {
            obj.scale.set(4.5, 4.5, 4.5);
            this.treeAcaciaNulls.push(obj);
          }
          if (obj.name.includes("reeds")) {
            obj.scale.set(3, 3, 3);
            this.treeReedsNulls.push(obj);
          }
          if (obj.name.includes("SM_Tree_L")) {
            obj.scale.set(5, 5, 5);
            this.treeEucalyptNulls.push(obj);
          }
          if (obj.name.includes("shrub")) {
            obj.scale.set(5, 5, 5);
            this.treeScrubNulls.push(obj);
          }
        });
      }
    });

    this.treeAcaciaInstances = this.createInstancedMesh(
      this.treeAcacia!,
      this.treeAcaciaNulls
    );
    this.treeReedsInstances = this.createInstancedMesh(
      this.treeReeds!,
      this.treeReedsNulls
    );
    this.treeEucalyptBaseInstances = this.createInstancedMesh(
      this.treeEucalyptBase!,
      this.treeEucalyptNulls
    );
    this.treeEucalyptLeavesInstances = this.createInstancedMesh(
      this.treeEucalyptLeaves!,
      this.treeEucalyptNulls
    );

    this.treeScrubInstances = this.createInstancedMesh(
      this.treeScrub!,
      this.treeScrubNulls
    );

    //remove nulls from scene?

    this.treeAcaciaNulls = [];
    this.treeReedsNulls = [];
    this.treeEucalyptNulls = [];
    this.treeScrubNulls = [];
  }

  createInstancedMesh(
    original: THREE.Mesh,
    nulls: THREE.Object3D[]
  ): THREE.InstancedMesh {
    const instance = new THREE.InstancedMesh(
      original.geometry,
      original.material as any,
      nulls.length
    );

    nulls.forEach((nullObj, i) => {
      nullObj.updateMatrix();
      instance.setMatrixAt(i, nullObj.matrix);
    });

    instance.instanceMatrix.needsUpdate = true;
    this.root.add(instance);
    instance.instanceMatrix.needsUpdate = true;
    return instance;
  }

  fixMaterial(input: any, leaves: boolean) {
    if (input === undefined) input = new MeshStandardMaterial();
    if (leaves) {
      //(input.map as THREE.Texture).anisotropy = 8;
      input.map.encoding = THREE.sRGBEncoding;
      input.map.anisotropy = 8;
      /*
      return new THREE.MeshBasicMaterial({
        // transparent: true,
        alphaTest: 0.1,
        map: input.map,

        //color: 0x777777,
        //envMap: this.world.scene.environment,
        //envMapIntensity: 0.,3,
        side: THREE.DoubleSide,
        //roughness: 1,
        reflectivity: 0,
      });
      */
      return new THREE.MeshStandardMaterial({
        alphaTest: 0.1,
        map: input.map,
        normalMap: input.normalMap,
        color: 0xffffff,
        envMap: this.world.scene.environment,
        envMapIntensity: 0.1,
        emissiveMap: input.map,
        emissive: 0xffffff,
        emissiveIntensity: 0.1,
      });
    }
    return new THREE.MeshStandardMaterial({
      transparent: true,
      alphaTest: 0.5,
      map: input.map,
      envMap: this.world.scene.environment,
      roughness: 1,
      metalness: 0,
    });
  }
}
