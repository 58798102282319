import { Scenario4 } from "./sequences/Scenario4";
import { Scenario3 } from "./sequences/Scenario3";
import { Scenario } from "../world/Scenario";
import { PathNode } from "../world/PathNode";
import { Sequence } from "./sequences/Sequence";
import { World } from "../Scene";
import { Scenario1 } from "./sequences/Scenario1";
import { Scenario2 } from "./sequences/Scenario2";
import { Scenario5 } from "./sequences/Scenario5";
import { Scenario6 } from "./sequences/Scenario6";
//import { Scenario7 } from "./sequences/Scenario7";
import { Scenario8 } from "./sequences/Scenario8";
import { Scenario9 } from "./sequences/Scenario9";
import { Scenario10 } from "./sequences/Scenario10";
import { Scenario11 } from "./sequences/Scenario11";
import { Scenario12 } from "./sequences/Scenario12";
import { Scenario13 } from "./sequences/Scenario13";
import { Scenario14 } from "./sequences/Scenario14";
import { Scenario15 } from "./sequences/Scenario15";
import { Scenario7 } from "./sequences/Scenario7";

export enum PlayState {
  Stopped,
  Playing,
  Paused,
}

export class Timeline {
  queuedSequences: Array<Sequence> = [];
  currentSequenceIndex: number = 0;
  currentSequence: Sequence;
  sequenceState: PlayState = PlayState.Stopped;
  world: World;
  constructor(world: World) {
    this.world = world;
    this.queueSequence(new Scenario1(this.world));
    this.queueSequence(new Scenario2(this.world));
    this.queueSequence(new Scenario3(this.world));
    this.queueSequence(new Scenario4(this.world));
    this.queueSequence(new Scenario5(this.world));
    this.queueSequence(new Scenario6(this.world));
    this.queueSequence(new Scenario7(this.world));
    this.queueSequence(new Scenario8(this.world));
    this.queueSequence(new Scenario9(this.world));
    this.queueSequence(new Scenario10(this.world));
    this.queueSequence(new Scenario11(this.world));
    this.queueSequence(new Scenario12(this.world));
    this.queueSequence(new Scenario13(this.world));
    this.queueSequence(new Scenario14(this.world));
    this.queueSequence(new Scenario15(this.world));
    this.currentSequence = this.queuedSequences[0];
  }

  addScenario(scenario: Scenario) {}

  start() {
    console.log("starting timeline");
    this.currentSequenceIndex = 0;
    this.play();
  }

  play() {
    this.currentSequence.start(this.nextSequence.bind(this));
    this.dispatchUpdate();
  }

  dispatchUpdate() {
    document.dispatchEvent(
      new CustomEvent("currentScenarioChange", {
        detail: this.currentSequence.constructor.name,
      })
    );
  }

  nextSequence() {
    this.currentSequence.stop();
    this.currentSequenceIndex++;
    if (this.currentSequenceIndex >= this.queuedSequences.length) {
      this.sequenceState = PlayState.Stopped;
      return;
    }
    this.currentSequence = this.queuedSequences[this.currentSequenceIndex];
    this.play();
  }

  jumpToSequence(index: number) {
    // TODO - move units back to spawn points (so skipping backwards works)
    this.currentSequence.stop();
    // let prevScenario = this.queuedSequences[index - 1].scenario;
    for (let i = 0; i < index; i++) {
      //this.queuedSequences[i].stop();
      if (this.queuedSequences[i].scenario)
        this.world.changeScenario(this.queuedSequences[i].scenario!);
      this.world.characters.forEach((char) => {
        char.behaviour?.jumpToEndState();
      });
      this.queuedSequences[i].stop();
    }

    this.currentSequenceIndex = index;
    this.currentSequence = this.queuedSequences[this.currentSequenceIndex];
    this.play();
  }

  jumpToScenario(scenario: string) {
    console.log(this.queuedSequences);
    let index = this.queuedSequences.findIndex(
      (seq) => seq.constructor.name === scenario
    );
    if (index !== -1) {
      this.jumpToSequence(index);
    } else {
      throw new Error("Scenario not found:" + scenario);
    }
  }

  queueSequence(sequence: Sequence) {
    this.queuedSequences.push(sequence);
  }

  clearQueue() {
    //this.queuedSequences[this.currentSequenceIndex]?.endedCallback();
    this.queuedSequences = [];
    this.currentSequenceIndex = 0;
    this.currentSequence = this.queuedSequences[0];
  }
}
