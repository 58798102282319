import { Howl, Howler } from "howler";
export class AudioManagerSingleton {
  private sfx: { [key: string]: Howl } = {};
  private vo: { [key: string]: Howl } = {};
  private sfxSrc = [
    "./sfx/WAA021_01_OUT_OF_BREACH.mp3",
    "./sfx/sfx_artilleryFire.mp3",
    "./sfx/sfx_buildingBreach.mp3",
    "./sfx/sfx_explosion_large.mp3",
    "./sfx/sfx_explosion_small.mp3",
    "./sfx/sfx_gunfire_large.mp3",
    "./sfx/sfx_gunfire_small.mp3",
    "./sfx/sfx_radarDetect.mp3",
    "./sfx/sfx_walkieTalkie.mp3",
  ];

  private voSrc = [
    "./vo/vo_01_order.mp3",
    "./vo/vo_01_comms.mp3",
    "./vo/vo_02_comms.mp3",
    "./vo/vo_02_order.mp3",
    "./vo/vo_03_comms.mp3",
    "./vo/vo_03_order.mp3",
    "./vo/vo_04_order.mp3",
    "./vo/vo_05_comms.mp3",
    "./vo/vo_05_order.mp3",
    "./vo/vo_06_comms.mp3",
    "./vo/vo_06_order.mp3",
    "./vo/vo_07a_comms.mp3",
    "./vo/vo_07a_order.mp3",
    "./vo/vo_07b_comms.mp3",
    "./vo/vo_07b_order.mp3",
    "./vo/vo_08_comms.mp3",
    "./vo/vo_09_comms.mp3",
    "./vo/vo_09_order.mp3",
    "./vo/vo_10a_comms.mp3",
    "./vo/vo_10a_order.mp3",
    "./vo/vo_10b_comms.mp3",
    "./vo/vo_10c_comms.mp3",
    "./vo/vo_10d_comms.mp3",
    "./vo/vo_11_comms.mp3",
    "./vo/vo_12a_comms.mp3",
    "./vo/vo_12a_order.mp3",
    "./vo/vo_12b_comms.mp3",
    "./vo/vo_12b_order.mp3",
    "./vo/vo_14_comms.mp3",
    "./vo/vo_14a_order.mp3",
    "./vo/vo_14b_order.mp3",
    "./vo/vo_15_order.mp3",
  ];
  preload(): void {
    this.sfxSrc.forEach((src) => {
      let name = src.split("/").pop()!.split(".").slice(0, -1).join(".");
      let sound = new Howl({
        src: [src],
        preload: true,
      });
      this.sfx[name] = sound;
    });
    this.voSrc.forEach((src) => {
      let name = src.split("/").pop()!.split(".").slice(0, -1).join(".");
      let sound = new Howl({
        src: [src],
        preload: true,
      });
      this.vo[name] = sound;
    });

    this.sfx["WAA021_01_OUT_OF_BREACH"].loop(true);
    this.sfx["WAA021_01_OUT_OF_BREACH"].volume(0.3);
  }

  playSFX(name: string, callback?: () => void): Howl {
    if (this.sfx[name] === undefined) throw new Error("SFX not found: " + name);
    try {
      this.sfx[name].play();
    } catch (error) {
      console.error(
        "Error playing sound: " + name + " likely need user interaction."
      );
      if (callback) callback();
    }

    if (callback) {
      this.sfx[name].once("end", callback);
    }

    return this.sfx[name];
  }

  stopSFX(name: string): void {
    if (this.sfx[name] === undefined) throw new Error("SFX not found: " + name);
    this.sfx[name].stop();
  }

  playVO(name: string, callback?: () => void) {
    if (this.vo[name] === undefined) throw new Error("VO not found: " + name);
    Object.keys(this.sfx).forEach((key) => {
      this.sfx[key].volume(0.5);
    });
    this.sfx["WAA021_01_OUT_OF_BREACH"].volume(0.1);

    Object.keys(this.vo).forEach((key) => {
      this.vo[key].stop();
    });

    try {
      this.vo[name].play();
    } catch (error) {
      console.error(
        "Error playing VO: " + name + " likely need user interaction."
      );
      document.dispatchEvent(
        new CustomEvent("voiceoverEnded", { detail: name })
      );
      if (callback) callback();
    }

    this.vo[name].once("end", () => {
      Object.keys(this.sfx).forEach((key) => {
        this.sfx[key].volume(1);
      });
      this.sfx["WAA021_01_OUT_OF_BREACH"].volume(0.3);
      document.dispatchEvent(
        new CustomEvent("voiceoverEnded", { detail: name })
      );
      if (callback) callback();
    });
  }
}

export let AudioManager = new AudioManagerSingleton();
