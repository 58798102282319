import { Sequence } from "./Sequence";
import { ScenarioStage } from "../../enums/ScenarioStage";
import { World } from "../../world/World";
import * as THREE from "three";
export class Scenario4 extends Sequence {
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_4";
    this.units = ["inf1"];
    this.unitType = "INS";
    this.target = new THREE.Vector3(-15, 100, 70);
  }
}
