import { useEffect, useRef, useState } from "react";

import { Box, Flex, Text, Image } from "@chakra-ui/react";

import { motion } from "framer-motion";
import { RadioOrder } from "../useStore";

const AROrder = ({
  children,
  radioOrder,
}: {
  children?: any;
  radioOrder: RadioOrder;
}) => {
  return (
    <Flex
      marginTop="0 !important"
      width="100%"
      height="110px"
      flexDirection="row"
      background="rgba(0, 0, 0, 0.2)"
      backdropFilter="blur(8px)"
      padding="15px"
      borderTop="1px solid rgba(255, 255, 255, 0.15);"
      as={motion.div}
      onClick={() => {
        document.dispatchEvent(
          new CustomEvent("voiceoverEnded", { detail: {} })
        );
      }}
      animation="all 0.5s ease-in-out"
      /*
      animate={{
        scale: [0.8, 1],
        translateY: [-50, 0],
        scaleY: [0, 1],
      }}
      */
      /*@ts-ignore*/
      transition={{
        duration: 100,
        ease: "easeIn",
        type: "tween",
      }}
    >
      <Flex
        flexDirection="column"
        width="110px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex>
          <Text variant="clock" color="rgba(255, 255, 255, 0.65);">
            {radioOrder.time}
          </Text>
        </Flex>
        <Flex>
          <Image width="40px" src={`./tex/${radioOrder.unit}-badge.png`} />
        </Flex>
      </Flex>
      <Flex flexDirection="column" flexGrow={1} width="100%">
        <Flex>
          <Text variant="subheading" color="rgba(255, 255, 255, 0.65);">
            {radioOrder.title}
          </Text>
        </Flex>
        <Flex flexGrow={1}>
          <Box
            fontSize="13px"
            lineHeight="18px"
            width="100%"
            height="100%"
            color="white"
          >
            {radioOrder.content}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AROrder;
