import { Sequence } from "./scene/timeline/sequences/Sequence";
import { ScenarioStage } from "./scene/enums/ScenarioStage";

import create from "zustand";

export enum ARMode {
  NONE = "none",
  PERMISSIONS = "permissions",
  PLACEMENT = "placement",
  DONE = "done",
}

export enum NavState {
  NONE = "none",
  HOME = "home",
  EXPERIENCE = "splash",
  COMPLETED = "completed",
  ROLES = "roles",
}

export enum WorldState {
  NONE = "none",
  LOADING = "loading",
  PLACEMENT = "placement",
  READY = "ready",
}

export interface RadioComms {
  title: string;
  content: string;
  type: string;
  unit: string;
  time: string;
  audio?: string;
  noSquelch?: boolean | undefined;
}

export interface RadioOrder {
  title: string;
  content: string;
  type: string;
  unit: string;
  time: string;
  audio?: string;
  noSquelch?: boolean | undefined;
}

type AppState = {
  arMode: ARMode;
  XR8Loaded: boolean;
  navState: NavState;
  worldState: WorldState;
  currentScenario?: Sequence;
  scenarioStage: ScenarioStage;
  backNavState: NavState;
  isDesktop: boolean;
  contentLoaded: boolean;
  radioOrder?: RadioOrder;
  radioComms?: RadioComms;
  api: {
    setARMode: (arStatus: ARMode) => void;
    setNavState: (navState: NavState) => void;
    setWorldState: (worldState: WorldState) => void;
    setCurrentScenario: (currentScenario: Sequence) => void;
    setScenarioStage: (scenarioStage: ScenarioStage) => void;
    setContentLoaded: (contentLoaded: boolean) => void;
    setXR8Loaded: (XR8Loaded: boolean) => void;
    setIsDesktop: (isDesktop: boolean) => void;
    setRadioOrder: (radioOrder?: RadioOrder) => void;
    setRadioComms: (radioComms?: RadioComms) => void;
    reset: () => void;
  };
};

const useStore = create<AppState>((set, get) => ({
  arMode: ARMode.NONE,
  useAR: true,
  navState: NavState.NONE,
  worldState: WorldState.NONE,
  backNavState: NavState.NONE,
  currentScenario: undefined,
  scenarioStage: ScenarioStage.None,
  contentLoaded: false,
  XR8Loaded: false,
  isDesktop: false,
  radioOrder: undefined,
  radioComms: undefined,
  api: {
    setARMode: (state: ARMode) => set({ arMode: state }),
    setNavState: (state: NavState) => {
      set({ backNavState: get().navState });
      set({ navState: state });
      window.scrollTo(0, 0);
    },
    setWorldState: (state: WorldState) => {
      console.log("setWorldState", state);
      set({ worldState: state });
    },
    setCurrentScenario: (state: Sequence) => set({ currentScenario: state }),
    setScenarioStage: (state: ScenarioStage) => set({ scenarioStage: state }),
    setXR8Loaded: (XR8Loaded: boolean) => set({ XR8Loaded }),
    setContentLoaded: (contentLoaded: boolean) => set({ contentLoaded }),
    setIsDesktop: (isDesktop: boolean) => set({ isDesktop }),
    setRadioComms: (radioComms?: RadioComms) => set({ radioComms }),
    setRadioOrder: (radioOrder?: RadioOrder) => set({ radioOrder }),
    reset: () => {},
  },
}));

export default useStore;
