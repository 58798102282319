import { Box } from "@chakra-ui/react";
import { css } from "@emotion/react";
import backgroundSrc from "../assets/bg-gradient.svg";
const PaddedLayout = ({ children }: { children: any }) => {
  return (
    <Box
      boxSizing="content-box"
      backgroundColor="red"
      css={css`
        height: calc(100vh);
        height: calc(100dvh);
        touch-action: auto;
        pointer-events: auto;
        background-color: black;
        background-image: url("${backgroundSrc}") !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        user-select: none;
        height: 100%;
      `}
    >
      {children}
    </Box>
  );
};

export default PaddedLayout;
