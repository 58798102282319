import * as CANNON from "cannon-es";
import * as THREE from "three";
import { Object3D } from "three";
//import { threeToCannon } from '../../../lib/utils/three-to-cannon';
// ES6
import { ShapeType, threeToCannon } from "three-to-cannon";

import CannonUtils from "../../../lib/utils/CannonUtils.js";
import * as Utils from "../../core/FunctionLibrary";
import { ICollider } from "../../interfaces/ICollider";

export class TrimeshCollider implements ICollider {
  public mesh: any;
  public options: any;
  public body: CANNON.Body;
  public debugModel: any;

  constructor(mesh: THREE.Mesh, options: any) {
    this.mesh = mesh.clone();

    let defaults = {
      mass: 0,
      position: mesh.position,
      rotation: mesh.quaternion,
      friction: 1,
      restitution: 0.0001,
    };
    options = Utils.setDefaults(options, defaults);
    this.options = options;

    let mat = new CANNON.Material("triMat");
    mat.friction = options.friction;
    // mat.restitution = 0.7;

    //const {shape} = threeToCannon(this.mesh, {type: ShapeType.MESH})!;
    const shape = CannonUtils.CreateTrimesh(mesh.geometry);
    // shape['material'] = mat;

    // Add phys sphere
    let physBox = new CANNON.Body({
      mass: options.mass,
      position: options.position,
      quaternion: options.rotation,
      shape: shape,
    });

    physBox.material = mat;

    this.body = physBox;
  }
}
