import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Box, Flex, Image } from "@chakra-ui/react";
import { RoleData } from "./Roles";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import { css } from "@emotion/react";
import INS from "../../assets/portraits/portrait-INS.png";
import INR from "../../assets/portraits/portrait-INR.png";
import ADO from "../../assets/portraits/portrait-ADO.png";
import AOP from "../../assets/portraits/portrait-AOP.png";
import ASO from "../../assets/portraits/portrait-ASO.png";
import ARV from "../../assets/portraits/portrait-ARV.png";
import AOB from "../../assets/portraits/portrait-AOB.png";
import useStore from "../../useStore";

const Carousel = ({
  roles,
  setRole,
}: {
  roles: RoleData;
  setRole: (name: string) => void;
}) => {
  const { isDesktop } = useStore((state) => state);
  const images = {
    INS,
    INR,
    ADO,
    AOP,
    ARV,
    AOB,
    ASO,
  } as any;
  return (
    <Flex
      css={css`
        touch-action: none;
        pointer-events: auto;
      `}
    >
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={isDesktop ? 3 : 2}
        navigation={true}
        spaceBetween={isDesktop ? 30 : 0}
        modules={[Pagination, Navigation]}
        className="role-swiper"
        onActiveIndexChange={(swiper) => {
          setRole(Object.keys(roles)[swiper.activeIndex]);
        }}
      >
        {Object.keys(roles).map((key, index) => {
          return (
            <SwiperSlide key={key}>
              <div>
                <Image src={images[roles[key].code]} />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Flex>
  );
};

export default Carousel;
