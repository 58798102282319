import { StopState } from "./../enums/StopState";
import { Object3D } from "three";
import { MoveState } from "../enums/MoveState";

import { Formation } from "./Formation";
import { Path } from "./Path";

export class PathNode {
  public object: Object3D;
  public path: Path;
  public nextNode?: PathNode;
  public previousNode?: PathNode;
  public formation?: string;
  public staticAnimation?: string;
  public stopState?: StopState;
  public moveState?: string;
  public speed?: number;
  public alertness?: string;

  constructor(child: THREE.Object3D, path: Path) {
    this.object = child;
    this.path = path;

    if (child.userData.hasOwnProperty("formation")) {
      this.formation = child.userData.formation;
    }

    if (child.userData.hasOwnProperty("staticAnimation")) {
      this.staticAnimation = child.userData.staticAnimation;
    }

    if (child.userData.hasOwnProperty("alertness")) {
      if (
        child.userData.alertness !== "ease" &&
        child.userData.alertness !== "alert"
      ) {
        console.warn("Unknown alertness: " + child.userData.alertness);
        throw new Error("Unknown alertness: " + child.userData.alertness);
      } else {
        this.alertness = child.userData.staticAnimation;
      }
    }

    if (child.userData.hasOwnProperty("speed")) {
      this.speed = parseFloat(child.userData.speed);
    }

    if (child.userData.hasOwnProperty("stopState")) {
      const stopState: StopState | undefined = (
        Object.values(StopState) as unknown as string[]
      ).includes(child.userData.stopState)
        ? (child.userData.stopState as unknown as StopState)
        : undefined;
      if (!stopState) {
        console.warn(
          `Invalid stopState on node ${child.name}: ${child.userData.stopState}`
        );
        throw new Error(
          `Invalid stopState on node ${child.name}: ${child.userData.stopState}`
        );
      } else {
        this.stopState = child.userData.stopState;
      }
    }

    if (child.userData.hasOwnProperty("moveState")) {
      const moveState: MoveState | undefined = (
        Object.values(MoveState) as unknown as string[]
      ).includes(child.userData.moveState)
        ? (child.userData.moveState as unknown as MoveState)
        : undefined;
      if (!moveState) {
        console.warn(
          `Invalid moveState on node ${child.name}: ${child.userData.moveState}`
        );
        throw new Error(
          `Invalid moveState on node ${child.name}: ${child.userData.moveState}`
        );
      } else {
        this.moveState = child.userData.moveState;
      }
    }
  }
}
