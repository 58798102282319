import { VolumetricFireball } from "./../../particles/VolumetricFireball";
import { VolumetricFire } from "./../../particles/VolumetricFire";
import { Sequence } from "./Sequence";
import { World } from "../../world/World";
import * as THREE from "three";

export class Scenario1 extends Sequence {
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_1";
    this.units = ["boxer1"];
    this.unitType = "ARV";
    this.target = new THREE.Vector3(-110, 111, -95);
  }

  introStage(): void {
    super.introStage();

    //const fire = new VolumetricFireball(this.world);
    //fire.position.set(0, 60, 20);
  }
}
