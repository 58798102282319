import * as THREE from "three";

import { Prop } from "../props/Prop";

import * as Utils from "../core/FunctionLibrary";
import { LoadingManager } from "../core/LoadingManager";
import { ISpawnPoint } from "../interfaces/ISpawnPoint";
import { World } from "./World";

export class PropSpawnPoint implements ISpawnPoint {
  public object: THREE.Object3D;
  public entity: Prop | undefined;
  public name: string;
  constructor(object: THREE.Object3D) {
    this.object = object;
    this.name = this.object.userData.name;
  }

  public async spawn(
    loadingManager: LoadingManager,
    world: World
  ): Promise<PropSpawnPoint> {
    return new Promise<PropSpawnPoint>(async (resolve, reject) => {
      await loadingManager
        .loadGLTFAsync("./export-models/" + this.object.userData.type + ".glb")
        .then((gltf) => {
          let prop = new Prop(gltf, world);
          prop.name = this.object.userData.type;
          prop.name = this.object.userData.name;
          let worldPos = new THREE.Vector3();
          let worldQuat = new THREE.Quaternion();
          this.object.getWorldPosition(worldPos);
          this.object.getWorldQuaternion(worldQuat);

          prop.position.copy(worldPos);
          prop.quaternion.copy(worldQuat);
          //prop.scale.set(1, 1, 1);
          //  let forward = Utils.getForward(this.object);
          // player.setOrientation(forward, true);
          world.add(prop);

          this.entity = prop;
          resolve(this);
        });
    });
  }
}
