import { Character } from "../characters/Character";
import { EntityType } from "../enums/EntityType";
import { IWorldEntity } from "../interfaces/IWorldEntity";
import { PathNode } from "../world/PathNode";
import { World } from "../world/World";
import * as THREE from "three";
import { MeshLine, MeshLineMaterial, MeshLineRaycast } from "meshline";
import { DecalGeometry } from "three/examples/jsm/geometries/DecalGeometry";
import { Euler, Sprite } from "three";
import { VideoTextureManager } from "../core/VideoTextureManager";
import { VideoTextureSource } from "../core/VideoTextureSource";
export class RadarScan extends THREE.Object3D implements IWorldEntity {
  private world: World | undefined;

  public height = 10;

  entityType: EntityType;
  updateOrder: number = 10;
  private loop: boolean = false;
  private scanMesh: THREE.Mesh;
  private scanSource!: VideoTextureSource; //set in createHighlgith

  public tapTargets: THREE.Object3D[] = [];
  public tapCallback = () => {};

  //private icon: THREE.Texture;

  constructor(
    world: World,
    position: THREE.Vector3,
    lookAt: THREE.Vector3,
    path: string,
    loop: boolean = false
  ) {
    super();
    this.name = "radarScan";
    this.entityType = EntityType.UIOverlay;
    this.world = world;
    this.loop = loop;
    this.scanMesh = this.createScanMesh(path);
    this.scale.set(250, 250, 250);
    position.y = position.y + this.height;
    lookAt.y = lookAt.y + this.height;
    this.position.copy(position);

    this.lookAt(lookAt);
    this.hide();
    this.world.add(this);
  }

  onTapEvent(event: any): void {
    if (!this.visible) return;

    const match = this.tapTargets.find((target) => {
      return target.uuid === event.detail.uuid;
    });

    if (match) {
      document.dispatchEvent(
        new CustomEvent("pennantTap", { detail: { name: this.name } })
      );
    }
  }

  createScanMesh(path: string): THREE.Mesh {
    const videoSource = VideoTextureManager.getVideoSource(path);
    this.scanSource = videoSource;
    console.log("create scan mesh", videoSource);
    // icon.minFilter = THREE.LinearFilter;
    videoSource.tex.encoding = THREE.sRGBEncoding;

    const scanMat = new THREE.MeshBasicMaterial({
      map: videoSource.tex,
      color: 0xffffff,
      transparent: true,
      side: THREE.DoubleSide,
      blending: THREE.AdditiveBlending,
      depthWrite: false,
    });

    const plane = new THREE.PlaneGeometry(1, 1);

    plane.rotateX(-Math.PI * 0.5); //horizontal
    plane.translate(0.5, 0, -0.5);
    // plane.rotateY(Math.PI * 0.25); // 45 degrees, so scan is forward
    const scanMesh = new THREE.Mesh(plane, scanMat);
    scanMesh.rotateY(Math.PI * -0.75);
    this.add(scanMesh);

    return scanMesh;
  }

  addToWorld(world: World): void {
    this.world = world;
    world.overlays.push(this);
    world.graphicsWorld.add(this);
  }
  removeFromWorld(world: World): void {
    this.world = undefined;
    world.overlays = world.overlays.filter((overlay) => {
      return overlay !== this;
    });
    world.graphicsWorld.remove(this);
  }

  setScenario(data: any): void {
    throw new Error("Method not implemented.");
  }

  update(timestep: number, unscaledTimeStep: number): void {}

  setPosition(position: THREE.Vector3): void {
    this.position.copy(position);
  }

  public getRaycast(): THREE.Intersection {
    return this.world?.getTerrainRaycast(this.position.x, this.position.z)!;
  }

  public show(): void {
    this.visible = true;
    this.scanSource.play(this.loop);
  }

  public dispose(): void {
    //this.scanSource.dispose();
    this.scanSource.stop();
    this.removeFromWorld(this.world!);
  }

  public hide(): void {
    this.visible = false;
    this.scanSource.stop();
  }
}
