import { Sequence } from "./Sequence";
import { ScenarioStage } from "../../enums/ScenarioStage";
import { World } from "../../world/World";
import * as THREE from "three";
export class Scenario15 extends Sequence {
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_15";
    this.promptUnit = false;
    this.units = [];
    this.unitType = "success";
  }

  inputWaitStage(): void {
    document.dispatchEvent(new CustomEvent("missionCompleted", {}));
  }
}
