import { ParticleEmitter, ParticleSystem } from "three.quarks";
import * as THREE from "three";

export class ParticleFX {
  object: THREE.Object3D | undefined;

  constructor(object: THREE.Object3D) {
    this.object = object;
  }

  //permanent - marks for deletion
  endEmit(): void {
    this.object!.traverse((child) => {
      if (child instanceof ParticleEmitter) {
        child.system.endEmit();
      }
    });
  }

  play(): void {
    this.object!.traverse((child) => {
      if (child instanceof ParticleEmitter) {
        child.system.play();
      }
    });
  }

  pause(): void {
    this.object!.traverse((child) => {
      if (child instanceof ParticleEmitter) {
        child.system.pause();
      }
    });
  }

  stop(): void {
    this.object!.traverse((child) => {
      if (child instanceof ParticleEmitter) {
        child.system.endEmit();
      }
    });
  }

  restart(): void {
    this.object!.traverse((child) => {
      if (child instanceof ParticleEmitter) {
        child.system.restart();
      }
    });
  }

  loop(val: boolean): void {
    this.object!.traverse((child) => {
      if (child instanceof ParticleEmitter) {
        child.system.looping = val;
      }
    });
  }

  dispose(): void {
    // any other types to dispose?
    this.object!.traverse((child) => {
      if (child instanceof ParticleEmitter) {
        child.system.dispose();
      }
    });
    this.object = undefined;
  }
}
