import { VideoTextureSource } from "./VideoTextureSource";
import * as THREE from "three";
export class VideoTextureManager {
  static videoSources = new Map<string, VideoTextureSource>();

  static getVideoSource(path: string): VideoTextureSource {
    if (this.videoSources.has(path)) {
      return this.videoSources.get(path)!;
    } else {
      let source = new VideoTextureSource(path);
      this.videoSources.set(path, source);
      console.log("Adding new video texture source", source);
      return source;
    }
  }

  static play(path: string) {
    const source = this.getVideoSource(path);
    try {
      source.el.play();
    } catch (error) {
      console.error(error);
    }
  }

  static stopAll(path: string) {
    this.videoSources.forEach((source) => {
      source.el.pause();
      source.el.currentTime = 0;
    });
  }
}
