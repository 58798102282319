import { Sequence } from "./Sequence";
import { ScenarioStage } from "../../enums/ScenarioStage";
import { World } from "../../world/World";
import { RadarScan } from "../../overlays/RadarScan";
import * as THREE from "three";
export class Scenario9 extends Sequence {
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_9";
    this.units = ["obs1"];
    this.unitType = "AOB";
    this.promptUnit = true;
  }

  movementStage(): void {
    super.movementStage();
    setTimeout(() => {
      this.changeState(ScenarioStage.Ended);
    }, 4500);
  }
}
