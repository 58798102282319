import ButtonBase from "../Components/ButtonBase";

import PaddedLayout from "../Layouts/PaddedLayout";
import SectionHeader from "../Components/SectionHeader";
import { useNavigate } from "react-router-dom";
import { Flex, Heading, Stack, VStack, Image } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import armyChallengeSrc from "../assets/logo-challenge-army.png";

import titleGraphic from "../assets/title-graphic.png";

const Briefing = () => {
  const navigate = useNavigate();
  return (
    <>
      <PaddedLayout>
        <VStack
          spacing={6}
          justifyContent="space-between"
          margin="auto"
          position="absolute"
          bottom="25px"
          top="25px"
          left="25px"
          right="25px"
        >
          <VStack width="100%">
            <SectionHeader>ARMY COMBAT ROLES</SectionHeader>
            <VStack marginTop="100px" width="100%">
              <Image src={titleGraphic}></Image>
            </VStack>
          </VStack>
          <VStack spacing="25px" maxWidth="500px">
            <VStack
              spacing="25px"
              backgroundColor="rgba(51, 51, 51, 0.7)"
              backdropFilter="blur(4px)"
              padding="25px"
              flexDir="column"
              alignItems="center"
            >
              <Text fontWeight="700" textAlign="center" variant="geomLight">
                Welcome to the Interactive Battlespace.
              </Text>
              <Text textAlign="center">
                I'm Lieutenant Harris and I'm an Officer in the Australian Army.
                I'll be leading you through this mission. Your task is to clear
                suspected threat location and deter enemy operations using all
                soldiers and assets available. To achieve the task, you'll learn
                what each role does and how they work together as a team.
              </Text>
              <Text textAlign="center">
                Mission is successful if completed by 1200h.
              </Text>
              <Text textAlign="center">Find your role in the action.</Text>

              <ButtonBase onClick={() => navigate("/instructions")}>
                CONTINUE
              </ButtonBase>
            </VStack>
            <Image
              marginBottom="50px !important"
              width="129px"
              src={armyChallengeSrc}
            />
            <Text
              fontSize="10px"
              opacity="0.5"
              onClick={() => navigate("/terms")}
            >
              Terms &amp; Conditions
            </Text>
          </VStack>
        </VStack>
      </PaddedLayout>
      <audio src={`${process.env.PUBLIC_URL}/briefing.mp3`} autoPlay />
    </>
  );
};

export default Briefing;
