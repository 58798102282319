import { CrouchIdle } from "./CrouchIdle";
import * as Utils from "../../core/FunctionLibrary";
import { Character } from "../Character";
import { CharacterStateBase, ProneIdle, Walk } from "./_stateLibrary";

export class StartProne extends CharacterStateBase {
  isStandToProne = true;
  constructor(character: Character) {
    super(character);
    this.character.rotationSimulator.mass = 20;
    this.character.rotationSimulator.damping = 0.7;
    this.character.setArcadeVelocityTarget(0);

    if (this.character.charState instanceof CrouchIdle) {
      this.isStandToProne = false;
      this.animationLength = character.setAnimation(
        this.character.alertness + "_cr_pr",
        0.1
      );
    } else {
      this.animationLength = character.setAnimation(
        this.character.alertness + "_cr_idle",
        0.1
      );
    }
  }

  public update(timeStep: number): void {
    super.update(timeStep);

    if (this.animationEnded(timeStep)) {
      if (this.isStandToProne) {
        this.character.setAnimation(this.character.alertness + "_cr_pr", 0.1);
        this.isStandToProne = false;
      } else {
        this.character.setState(new ProneIdle(this.character));
      }
    }
  }

  public onInputChange(): void {
    super.onInputChange();
    if (this.anyDirection()) {
      this.character.setState(new Walk(this.character));
    }
  }
}
