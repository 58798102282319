import { Object3D } from "three";

import { Formation } from "./Formation";

export class FormationNode {
  public object: Object3D;
  public formation: Formation;
  public name: string;

  constructor(child: THREE.Object3D, formation: Formation) {
    this.object = child;
    this.formation = formation;
    this.name = child.userData.name;
  }
}
