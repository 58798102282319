import * as THREE from "three";

import { World } from "./World";
import { WaterShader } from "../../lib/shaders/WaterShader";
import { IUpdatable } from "../interfaces/IUpdatable";

export class Water implements IUpdatable {
  public updateOrder: number = 10;
  //public material: THREE.ShaderMaterial;
  public waterNormal: THREE.Texture;
  private world: World;

  constructor(world: World, mesh: THREE.Mesh) {
    this.world = world;

    const normalTex = new THREE.TextureLoader().load(
      "./tex/Water_1_M_Normal.jpg"
    );
    const alphaTex = new THREE.TextureLoader().load("./tex/river_alpha.png");
    normalTex.wrapS = THREE.RepeatWrapping;
    normalTex.wrapT = THREE.RepeatWrapping;
    normalTex.repeat = new THREE.Vector2(1, 0.5);
    this.waterNormal = normalTex;

    const water2Mesh = mesh.clone();
    water2Mesh.renderOrder = 0;
    mesh.visible = false;
    water2Mesh.material = new THREE.MeshPhysicalMaterial({
      color: 0x121201,
      transmission: 0.8,
      opacity: 1,
      metalness: 0,
      roughness: 0,
      ior: 1.3,
      thickness: 1,
      specularIntensity: 2,
      specularColor: 0xffffff,
      envMapIntensity: 0.9,
      envMap: this.world.envMap,
      lightIntensity: 1,
      exposure: 1,
      transparent: true,
      normalMap: normalTex,

      alphaMap: alphaTex,
    } as any);
    this.world.graphicsWorld.add(water2Mesh);
    water2Mesh.position.y += 0.6;

    /*
    let uniforms = THREE.UniformsUtils.clone(WaterShader.uniforms);
    uniforms.iResolution.value.x = window.innerWidth;
    uniforms.iResolution.value.y = window.innerHeight;

    this.material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      fragmentShader: WaterShader.fragmentShader,
      vertexShader: WaterShader.vertexShader,
      transparent: true,
    });
  

    mesh.material = this.material;
*/
  }

  public update(timeStep: number): void {
    /*
    this.material.uniforms.iCameraPos.value.copy(this.world.camera.position);
    this.material.uniforms.lightDir.value.copy(
      //new THREE.Vector3().copy(this.world.sun.position).normalize()
      new THREE.Vector3(1, 1, -1).normalize()
    );
    this.material.uniforms.iGlobalTime.value += timeStep;
    */
    this.waterNormal.offset.y += timeStep * 0.075;
  }
}
