import ButtonBase from "../Components/ButtonBase";
import SubHeader from "../Components/SubHeader";
import PaddedLayout from "../Layouts/PaddedLayout";
import SectionHeader from "../Components/SectionHeader";
import { useNavigate } from "react-router-dom";
import { Flex, Heading, Stack, VStack, Image } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import armyChallengeSrc from "../assets/logo-challenge-army.png";
import titleGraphic from "../assets/title-graphic.png";
import { css } from "@emotion/react";
const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <PaddedLayout>
        <VStack
          spacing={6}
          justifyContent="space-between"
          margin="auto"
          position="absolute"
          bottom="25px"
          top="25px"
          left="25px"
          right="25px"
        >
          <VStack width="100%">
            <SectionHeader>ARMY COMBAT ROLES</SectionHeader>
            <VStack marginTop="100px" width="100%">
              <Image src={titleGraphic}></Image>
            </VStack>
          </VStack>
          <VStack spacing="25px" maxWidth="500px">
            <VStack
              spacing="25px"
              backgroundColor="rgba(51, 51, 51, 0.7)"
              backdropFilter="blur(4px)"
              padding="25px"
              flexDir="column"
              alignItems="center"
            >
              <Text fontWeight="700" textAlign="center" variant="geomLight">
                Get ready to experience the Interactive Battlespace.
              </Text>
              <Text textAlign="center">
                Join the mission, explore Army combat roles and find your place
                in the action.
              </Text>
              <Text>Ready to move out?</Text>

              <VStack spacing="12px">
                <ButtonBase onClick={() => navigate("/briefing")}>
                  BEGIN MISSION
                </ButtonBase>
                <Text align="center">OR</Text>
                <ButtonBase onClick={() => navigate("/roles")}>
                  EXPLORE ROLES
                </ButtonBase>
              </VStack>
            </VStack>
            <Image
              marginBottom="50px !important"
              width="129px"
              src={armyChallengeSrc}
            />
            <Text
              fontSize="10px"
              opacity="0.5"
              onClick={() => navigate("/terms")}
            >
              Terms &amp; Conditions
            </Text>
          </VStack>
        </VStack>
      </PaddedLayout>
    </>
  );
};

export default Home;
