import ButtonBase from "../Components/ButtonBase";

import PaddedLayout from "../Layouts/PaddedLayout";
import SectionHeader from "../Components/SectionHeader";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Heading,
  Stack,
  VStack,
  Image,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import armyChallengeSrc from "../assets/logo-challenge-army.png";
import { useEffect } from "react";
import titleGraphic from "../assets/title-graphic.png";

const Instructions = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }, []);
  return (
    <>
      <PaddedLayout>
        <VStack
          spacing={6}
          justifyContent="space-between"
          margin="auto"
          position="absolute"
          bottom="25px"
          top="25px"
          left="25px"
          right="25px"
        >
          <VStack width="100%">
            <SectionHeader>ARMY COMBAT ROLES</SectionHeader>
            <VStack marginTop="100px" width="100%">
              <Image src={titleGraphic}></Image>
              <Heading marginTop="50px !important" variant="large" isTruncated>
                INSTRUCTIONS
              </Heading>
            </VStack>
          </VStack>
          <VStack spacing="25px" maxWidth="500px">
            <VStack
              spacing="25px"
              backgroundColor="rgba(51, 51, 51, 0.7)"
              backdropFilter="blur(4px)"
              padding="25px"
              flexDir="column"
              alignItems="center"
            >
              <UnorderedList color="white">
                <ListItem>Find a clear, well-lit area to stand.</ListItem>
                <ListItem>Press CONTINUE to start the AR experience.</ListItem>
                <ListItem>
                  Using one finger, position the AR Battlespace and tap PLACE.
                </ListItem>
                <ListItem>
                  Turn sound on for the most immersive experience.
                </ListItem>
              </UnorderedList>
              <Text textAlign="left" width="100%" fontWeight="bold">
                You're the director! To interact with the mission:
              </Text>
              <UnorderedList color="white" paddingLeft="18px">
                <ListItem>
                  Move toward or away from the Battlespace to zoom.
                </ListItem>
                <ListItem>
                  Walk around the Battlespace to see all the action.
                </ListItem>
                <ListItem>
                  When you hear an order, look for the TAP icon.
                </ListItem>
                <ListItem>
                  Tap the information icon 'i' at any time to learn more about
                  the roles.
                </ListItem>
              </UnorderedList>

              <ButtonBase onClick={() => navigate("/experience")}>
                {" "}
                CONTINUE{" "}
              </ButtonBase>
            </VStack>
            <Image
              marginBottom="50px !important"
              width="129px"
              src={armyChallengeSrc}
            />
            <Text
              fontSize="10px"
              opacity="0.5"
              onClick={() => navigate("/terms")}
            >
              Terms &amp; Conditions
            </Text>
          </VStack>
        </VStack>
      </PaddedLayout>
    </>
  );
};

export default Instructions;
