import ButtonBase from "../Components/ButtonBase";
import SubHeader from "../Components/SubHeader";
import PaddedLayout from "../Layouts/PaddedLayout";
import SectionHeader from "../Components/SectionHeader";
import { useNavigate } from "react-router-dom";
import { Flex, Heading, Stack, VStack, Image } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import armyChallengeSrc from "../assets/logo-challenge-army.png";
import { css } from "@emotion/react";
import titleGraphic from "../assets/title-graphic.png";
import ReactGA from "react-ga4";
const Complete = () => {
  const navigate = useNavigate();
  return (
    <>
      <PaddedLayout>
        <VStack
          spacing={6}
          justifyContent="space-between"
          margin="auto"
          position="absolute"
          bottom="25px"
          top="25px"
          left="25px"
          right="25px"
        >
          <VStack width="100%">
            <SectionHeader> </SectionHeader>
            <VStack marginTop="100px" width="100%">
              <Image src={titleGraphic}></Image>
            </VStack>
          </VStack>
          <VStack spacing="25px" maxWidth="500px">
            <Heading variant="large" textTransform="uppercase" isTruncated>
              DEBRIEFING
            </Heading>
            <VStack
              spacing="25px"
              backgroundColor="rgba(51, 51, 51, 0.7)"
              backdropFilter="blur(4px)"
              padding="25px"
              flexDir="column"
              alignItems="center"
            >
              <Text fontWeight="700" textAlign="center" variant="geomLight">
                Mission Successful
              </Text>
              <Text>
                Congratulations on completing the mission. Now it's time to find
                your role in the action.
              </Text>

              <ButtonBase
                onClick={() =>
                  window.open(
                    "https://army.defencejobs.gov.au/jobs/combat-and-security?utm_source=Interactive%20Battlespace&utm_medium=AR&utm_campaign=August%202022&utm_content=Debrief",
                    "_blank"
                  )
                }
              >
                VISIT WEBSITE
              </ButtonBase>

              <ButtonBase onClick={() => (window.location.href = "/roles")}>
                VIEW ROLES
              </ButtonBase>
              <ButtonBase
                onClick={() => {
                  try {
                    ReactGA.event({
                      category: "App Events",
                      action: "Replay",
                    });
                  } catch (error) {}
                  window.location.href = "/experience";
                }}
              >
                REPLAY
              </ButtonBase>
            </VStack>
            <Image
              marginBottom="50px !important"
              width="129px"
              src={armyChallengeSrc}
            />
          </VStack>
        </VStack>
      </PaddedLayout>
    </>
  );
};

export default Complete;
