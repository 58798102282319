import { VehicleHalt } from "./VehicleHalt";
import * as THREE from "three";

import * as Utils from "../../core/FunctionLibrary";
import { EntityType } from "../../enums/EntityType";
import { MoveState } from "../../enums/MoveState";
import { StopState } from "../../enums/StopState";
import { ICharacterAI } from "../../interfaces/ICharacterAI";
import { Car } from "../../vehicles/Car";
import { Vehicle } from "../../vehicles/Vehicle";
import { Character } from "../Character";

export enum ApproachMode {
  Loose,
  Tight,
  Lerp,
  Waiting,
  Finished,
}

export class FollowTarget implements ICharacterAI {
  public character: Character;
  public approachMode: ApproachMode = ApproachMode.Loose;
  public targetPos: THREE.Vector3;
  public targetRot: THREE.Quaternion;
  public exact: boolean = false;
  public terminalDistance: number;

  public targetDebugBox: THREE.Mesh;
  public isVehicle: boolean = false;
  public showDebug: boolean = false;
  constructor(
    character: Character,
    targetPos: THREE.Vector3,
    targetRot: THREE.Quaternion,
    terminalDistance: number = 0.7,
    runDistance = 9999
  ) {
    this.character = character;
    this.targetPos = targetPos;
    this.targetRot = targetRot;

    this.isVehicle = character.controlledObject !== undefined;
    this.terminalDistance = terminalDistance;

    if (this.isVehicle) {
      this.terminalDistance = 10;
      this.character.controlledObject!.triggerAction("brake", false);
    }
    //  debug
    const boxGeo = new THREE.BoxGeometry(0.1, 100, 0.1);
    const boxMat = new THREE.MeshLambertMaterial({
      color: 0x00ff00,
    });

    this.targetDebugBox = new THREE.Mesh(boxGeo, boxMat);
    this.showDebugBox(this.character.world?.params.Debug_Paths);
    this.character.world!.graphicsWorld.add(this.targetDebugBox);
    //console.trace("add box");

    this.setTarget(targetPos, targetRot);
  }
  finish(): void {
    if (this.isVehicle)
      this.character.setBehaviour(new VehicleHalt(this.character));
  }

  public setTarget(
    targetPos: THREE.Vector3,
    targetRot: THREE.Quaternion
  ): void {
    this.targetPos = targetPos;
    this.targetRot = targetRot;
    this.isVehicle = this.character.controlledObject !== undefined;

    this.targetDebugBox.visible = this.showDebug;
    this.targetDebugBox.position.copy(this.targetPos);
    this.targetDebugBox.quaternion.copy(this.targetRot);

    if (this.isVehicle) {
      (this.character.controlledObject as Vehicle).setMobile();
    }
  }

  public jumpToEndState(): void {
    this.approachMode = ApproachMode.Finished;
  }

  _source = new THREE.Vector3();
  _viewVector = new THREE.Vector3();
  _forward = new THREE.Vector3();

  private vehicleUpdate(timeStep: number, vehicle: Vehicle) {
    //in vehicle

    this.character.getWorldPosition(this._source);
    this._viewVector = this._viewVector.subVectors(
      this.targetPos,
      this._source
    );
    let dist = this._viewVector.length();

    if (
      dist < this.terminalDistance &&
      this.approachMode === ApproachMode.Loose
    ) {
      this.reachedTarget();
    }

    if (this.approachMode === ApproachMode.Loose) {
      this._forward.set(0, 0, 1);
      this._forward = this._forward.applyQuaternion(
        (this.character.controlledObject as unknown as THREE.Object3D)
          .quaternion
      );
      this._forward.y = 0;
      this._viewVector.y = 0;
      this._viewVector.normalize();
      this._forward.normalize();
      let angle = Utils.getSignedAngleBetweenVectors(
        this._forward,
        this._viewVector
      );

      const maxSteerVal = 0.8;
      const lerpAngle = THREE.MathUtils.lerp(
        vehicle.getSteeringValue(),
        angle,
        timeStep * 4
      );
      let throttle = false;
      let reverse = false;
      let brake = false;
      if (this._forward.dot(this._viewVector) < -0.7) {
        //target behind

        if (Math.abs(vehicle.speed) < vehicle.targetMoveSpeed - 1) {
          //reverse more if below target speed
          reverse = true;
        }
        if (Math.abs(vehicle.speed) > vehicle.targetMoveSpeed) {
          //brake if above target speed
          brake = true;
        }
        vehicle.setSteeringValue(
          lerpAngle < maxSteerVal ? -lerpAngle : -maxSteerVal
        );
      } else if (dist > this.terminalDistance) {
        //target in front. ignore if in terminal distance to prevent over-fussy steering while in position
        if (Math.abs(vehicle.speed) < vehicle.targetMoveSpeed - 1) {
          //accelerate if below target speed
          throttle = true;
        }
        if (Math.abs(vehicle.speed) > vehicle.targetMoveSpeed) {
          //brake if above target speed
          brake = true;
        }
        vehicle.setSteeringValue(
          lerpAngle < maxSteerVal ? lerpAngle : maxSteerVal
        );
      }
      if (dist < this.terminalDistance) {
        //throttle = false;
      }

      //change inputs if needed
      if (vehicle.actions.brake.isPressed !== brake)
        vehicle.triggerAction("brake", brake);
      if (vehicle.actions.reverse.isPressed !== reverse)
        vehicle.triggerAction("reverse", reverse);
      if (vehicle.actions.throttle.isPressed !== throttle)
        vehicle.triggerAction("throttle", throttle);
    }
  }

  private characterUpdate(timeStep: number) {
    this.targetPos.y = 0;
    this._viewVector = this._viewVector.subVectors(
      this.targetPos,
      this.character.position
    );
    this._viewVector.y = 0;
    this.character.setViewVector(this._viewVector);

    // head to target loosely
    if (
      this._viewVector.length() > this.terminalDistance &&
      this.approachMode === ApproachMode.Loose
    ) {
      if (this.character.moveState === MoveState.Run) {
        this.character.triggerAction("run", true);
        this.character.triggerAction("up", true);
      } else {
        this.character.triggerAction("run", false);
        this.character.triggerAction("up", true);
      }
    }

    if (
      this._viewVector.length() < this.terminalDistance &&
      this.approachMode === ApproachMode.Loose
    ) {
      if (this.exact) {
        this.approachMode = ApproachMode.Lerp;
        console.log("Switching to Lerp mode", this.character.name);
      } else {
        this.reachedTarget();
      }
    }

    if (this.approachMode === ApproachMode.Lerp) {
      let lerp = new THREE.Vector3().copy(this.character.position);
      lerp.lerp(this.targetPos, timeStep * 3);
      this.character.setPosition(lerp.x, this.character.position.y, lerp.z);
      // Look at target Rotation's forward
      var vector = new THREE.Vector3(0, 0, 1);
      this.character.setOrientation(vector.applyQuaternion(this.targetRot));
    }

    this._viewVector.y = 0; //ignore height difference

    if (
      this._viewVector.length() < 0.3 &&
      this.approachMode === ApproachMode.Lerp
    ) {
      this.reachedTarget();
    }
  }

  public update(timeStep: number): void {
    if (this.isVehicle) {
      this.vehicleUpdate(timeStep, this.character.controlledObject! as Vehicle);
    } else {
      this.characterUpdate(timeStep);
    }
  }

  reachedTarget() {
    this.character.triggerAction("run", false);
    this.character.triggerAction("up", false);
    if (this.isVehicle) {
      //console.log("STOP VEHICLE");
      (this.character.controlledObject! as Vehicle).setFullStop();
    }

    // Look at target Rotation's forward
    var vector = new THREE.Vector3(0, 0, 1);

    this.character.setOrientation(vector.applyQuaternion(this.targetRot));

    this.targetDebugBox.visible = false;

    this.approachMode = ApproachMode.Finished;
  }

  public showDebugBox(show: boolean) {
    this.showDebug = show;
    this.targetDebugBox.visible = show;
  }

  dispose(): void {
    if (this.targetDebugBox) {
      this.targetDebugBox.visible = false;
    }
  }

  public setStopState(stopState: StopState | undefined) {
    this.character.triggerAction("cover", false);
    this.character.triggerAction("crouch", false);
    this.character.triggerAction("prone", false);

    switch (stopState) {
      case StopState.Cover:
        this.character.triggerAction("cover", true);
        break;
      case StopState.Crouch:
        this.character.triggerAction("crouch", true);
        break;
      case StopState.Prone:
        this.character.triggerAction("prone", true);
        break;
      default:
        break;
    }
  }

  public setMoveState(moveState: MoveState | undefined) {
    if (moveState !== undefined) {
      this.character.moveState = moveState;
    }
  }
}
