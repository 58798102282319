import { StopState } from "./../../enums/StopState";
import { FollowCharacter } from "./FollowCharacter";
import * as CANNON from "cannon-es";
import { Quaternion } from "cannon-es";
import * as THREE from "three";
import { MathUtils } from "three";

import * as Utils from "../../core/FunctionLibrary";
import { ICharacterAI } from "../../interfaces/ICharacterAI";
import { Vehicle } from "../../vehicles/Vehicle";
import { Formation } from "../../world/Formation";
import { PathNode } from "../../world/PathNode";
import { Character } from "../Character";
import { Idle } from "./../character_states/Idle";
import { ApproachMode, FollowTarget } from "./FollowTarget";
import { Car } from "../../vehicles/Car";

export class FollowPath extends FollowTarget implements ICharacterAI {
  public nodeRadius: number;
  public reverse: boolean = false;
  public targetNode: PathNode;

  private formation: Formation | undefined;
  public targetPos = new THREE.Vector3(0, 0, 0);

  public playedTargetNodeAnim = false;

  constructor(character: Character, firstNode: PathNode, nodeRadius: number) {
    super(
      character,
      firstNode.object.position,
      firstNode.object.quaternion,
      2,
      3
    );

    this.nodeRadius = nodeRadius;
    this.targetNode = firstNode;
    this.character.followers.forEach((follower) => {
      (follower.behaviour as FollowCharacter).targetNode = this.targetNode;
    });
    this.setTargetPathNode(this.targetNode);
  }

  public override jumpToEndState(): void {
    const endTarget = this.targetNode.path.finalNode;
    this.setTargetPathNode(endTarget);

    this.character.teleport(
      endTarget.object.position,
      endTarget.object.quaternion
    );

    this.character.followers.forEach((follower) => {
      (follower.behaviour as FollowCharacter).teleportToFormation(endTarget);
      (follower.behaviour as FollowCharacter).reachedTarget();

      //(follower.behaviour as FollowCharacter).finish();
    });

    this.approachMode = ApproachMode.Finished;

    this.reachedTarget();
    this.finish();
  }

  override finish(newBehaviour?: ICharacterAI): void {
    this.character.resetControls();

    if (this.isVehicle) {
      // Halt vehicle
      (this.character.controlledObject as Vehicle).setSteeringValue(0);
      (this.character.controlledObject as Vehicle).triggerAction(
        "throttle",
        false
      );
      (this.character.controlledObject as Vehicle).triggerAction("brake", true);
      (this.character.controlledObject as Vehicle).triggerAction(
        "reverse",
        false
      );
      (this.character.controlledObject as Vehicle).setBrake(100); // why needed?
      (this.character.controlledObject as Vehicle).setMoveSpeed(0); // why needed?
      if (!newBehaviour) {
        this.character.clearBehaviour();
        if (this.character.isRealCharacter)
          this.character.setState(new Idle(this.character));
      } else {
        this.character.setBehaviour(newBehaviour);
      }
    }
    this.setStopState(this.targetNode.stopState);
    this.targetDebugBox.visible = false;
    this.character.completedScenario();
    this.character.followers.forEach((follower) => {
      // follower.completedScenario();
    });

    this.approachMode = ApproachMode.Finished;
  }

  public setTargetPathNode(pathNode: PathNode) {
    this.targetNode = pathNode;
    this.updateFromNodeData();
    this.targetPos = this.getTargetPosition();
    let flippedRot = new THREE.Quaternion();
    flippedRot = flippedRot.copy(this.targetNode.object.quaternion);
    flippedRot = flippedRot.multiply(
      new THREE.Quaternion().setFromAxisAngle(
        new THREE.Vector3(0, 1, 0),
        Math.PI
      )
    );

    this.targetRot = flippedRot;
    super.setTarget(this.targetPos, flippedRot);
    if (this.targetNode.speed)
      this.character.setMoveSpeed(this.targetNode.speed);
    this.character.followers.forEach((follower) => {
      (follower.behaviour as FollowCharacter).targetNode = this.targetNode;
    });
  }

  getTargetPosition(): THREE.Vector3 {
    if (this.formation && this.character.formationSlot !== 0) {
      return this.formation.getFormationTargetPosition(
        this.character.formationSlot,
        this.targetNode!.object
      );
    } else {
      const target = new THREE.Vector3();
      this.targetNode!.object.getWorldPosition(target);
      return target;
    }
  }

  getNextTargetPosition(): THREE.Vector3 {
    if (this.formation && this.character.formationSlot !== 0) {
      return this.formation.getFormationTargetPosition(
        this.character.formationSlot,
        this.targetNode.nextNode!.object
      );
    } else {
      const target = new THREE.Vector3();
      this.targetNode.nextNode!.object.getWorldPosition(target);
      return target;
    }
  }

  getPreviousTargetPosition(): THREE.Vector3 {
    if (this.formation && this.character.formationSlot !== 0) {
      return this.formation.getFormationTargetPosition(
        this.character.formationSlot,
        this.targetNode.previousNode!.object
      );
    } else {
      const target = new THREE.Vector3();
      this.targetNode.previousNode!.object.getWorldPosition(target);
      return target;
    }
  }

  public update(timeStep: number): void {
    if (this.approachMode === ApproachMode.Lerp) {
      if (this.targetNode.staticAnimation) {
        // if this is prep for an animation, we will use the formation nodes literal position and rotation.
        this.targetRot = this.formation!.getFormationTargetRotation(
          this.character.formationSlot,
          this.targetNode.object
        );
        this.targetPos = this.formation!.getFormationAbsolutePosition(
          this.character.formationSlot,
          this.targetNode.object
        );
      }
    }

    super.update(timeStep);

    // Todo only compute once in followTarget
    let source = new THREE.Vector3();

    this.character.getWorldPosition(source);

    let viewVector = new THREE.Vector3().subVectors(this.targetPos, source);
    viewVector.y = 0;

    if (this.approachMode === ApproachMode.Waiting) {
      let straggler = this.character.followers.find((follower) => {
        return (
          (follower.behaviour! as FollowCharacter).approachMode !==
          ApproachMode.Finished
        );
      });
      if (!straggler) {
        this.approachMode = ApproachMode.Finished;
        document.dispatchEvent(
          new CustomEvent("pathFinished", {
            detail: { name: this.character.name },
          })
        );
        if (this.targetNode.staticAnimation) {
          this.playStaticAnimation(this.targetNode.staticAnimation!);
        } else {
          this.finish();
        }
      }
    }
  }

  public override reachedTarget() {
    // if node play anim
    super.reachedTarget();
    if (
      this.targetNode.staticAnimation &&
      this.playedTargetNodeAnim === false
    ) {
      //If we are leading characters, wait until they arrive
      if (this.approachMode === ApproachMode.Finished) {
        if (
          this.targetNode.staticAnimation &&
          this.character.followers.length > 0
        ) {
          this.approachMode = ApproachMode.Waiting;
        } else {
          this.playCharacterAnimation(this.targetNode.staticAnimation);
        }
      }

      this.targetDebugBox.visible = false;

      return;
    }

    if (!this.targetNode.nextNode) {
      //target reached. eventually we will want this to be the terminal behaviour - lerping, or closing on position.
      //this.finish();
      this.approachMode = ApproachMode.Waiting; // wait for followers to finish
      return;
    }

    super.setTarget(
      this.getNextTargetPosition(),
      this.targetNode.nextNode.object.quaternion
    );
    this.targetNode = this.targetNode.nextNode;
    this.character.followers.forEach((follower) => {
      (follower.behaviour as FollowCharacter).targetNode = this.targetNode;
    });
    this.updateFromNodeData();

    this.playedTargetNodeAnim = false;
    console.log("setting next node: " + this.targetNode.object.name);
    this.approachMode = ApproachMode.Loose;
  }

  updateFromNodeData() {
    if (this.targetNode.speed) {
      this.character.setMoveSpeed(this.targetNode.speed);
    }

    if (this.targetNode.formation) {
      let formation = this.character.world?.getFormationByName(
        this.targetNode.formation
      );
      this.formation = formation;

      this.character.followers.forEach(
        (follower) =>
          ((follower.behaviour as FollowCharacter).formation = formation)
      );
    }
  }

  finishedStaticAnimation() {
    //console.log("I'M FINISSSSHED");
    this.playedTargetNodeAnim = true;
    this.approachMode = ApproachMode.Finished;
    this.character.mixer.removeEventListener(
      "finished",
      this.finishedStaticAnimation.bind(this)
    );

    this.reachedTarget();
    this.character.followers.forEach((follower) => {
      //(follower.behaviour as FollowCharacter).approachMode = ApproachMode.Loose; //reset followers ready for next target
    });
  }

  playStaticAnimation(name: string) {
    this.character.setAnimation(
      name + "_" + this.character.formationSlot,
      0.1,
      this.finishedStaticAnimation.bind(this)
    );

    this.character.followers.forEach((follower) => {
      // follower.character.setState(new Idle(follower.character)); //reset existing anim behaviour
      //follower.character.setAnimation(name, 0.1, () => {});
      (follower.behaviour as FollowCharacter).approachMode =
        ApproachMode.Finished;
      console.log("playing anim: " + name);
      (follower.behaviour as FollowCharacter).playStaticAnimation(name);
    });
    //add animation listener?
  }

  playCharacterAnimation(name: string) {
    this.character.setAnimation(name, 0.1);
  }

  dispose(): void {
    if (this.targetDebugBox) this.targetDebugBox.visible = false;
    super.dispose();
  }
}
