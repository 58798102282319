import { MoveState } from "./../enums/MoveState";
import * as CANNON from "cannon-es";
import * as THREE from "three";
import { AnimationMixer, Euler, Object3D } from "three";
import {
  acceleratedRaycast,
  computeBoundsTree,
  disposeBoundsTree,
} from "three-mesh-bvh";

import { ClosestObjectFinder } from "../core/ClosestObjectFinder";
import * as Utils from "../core/FunctionLibrary";
import { KeyBinding } from "../core/KeyBinding";
import { CollisionGroups } from "../enums/CollisionGroups";
import { EntityType } from "../enums/EntityType";
import { SeatType } from "../enums/SeatType";
import { ICharacterAI } from "../interfaces/ICharacterAI";
import { ICharacterState } from "../interfaces/ICharacterState";
import { IControllable } from "../interfaces/IControllable";
import { IWorldEntity } from "../interfaces/IWorldEntity";
import { CapsuleCollider } from "../physics/colliders/CapsuleCollider";
import { RelativeSpringSimulator } from "../physics/spring_simulation/RelativeSpringSimulator";
import { VectorSpringSimulator } from "../physics/spring_simulation/VectorSpringSimulator";
import { Vehicle } from "../vehicles/Vehicle";
import { VehicleSeat } from "../vehicles/VehicleSeat";
import { World } from "../world/World";
import { FollowCharacter } from "./character_ai/FollowCharacter";
import { FollowPath } from "./character_ai/FollowPath";
import { FollowTarget } from "./character_ai/FollowTarget";
import { Idle } from "./character_states/Idle";
import { Driving } from "./character_states/vehicles/Driving";
import { GroundImpactData } from "./GroundImpactData";
import { VehicleEntryInstance } from "./VehicleEntryInstance";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";
import { Pennant } from "../overlays/Pennant";
import { Car } from "../vehicles/Car";
import { CharacterPennant } from "../overlays/CharacterPennant";
import { CharacterType } from "../enums/CharacterType";
// Add the extension functions
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

export class Character extends THREE.Object3D implements IWorldEntity {
  public updateOrder: number = 1;
  public entityType: EntityType = EntityType.Character;

  public height: number = 0;
  public tiltContainer: THREE.Group;
  public modelContainer: THREE.Group;
  public materials: THREE.Material[] = [];
  public mixer: THREE.AnimationMixer;
  public animations: any[] = [];
  public currentClipName = "idle";
  public isPlayer = false;
  public alertness = "alert";
  public moveState = MoveState.Run;
  public followers: Character[] = [];

  public pennant?: Pennant;

  // Movement
  public acceleration: THREE.Vector3 = new THREE.Vector3();
  public velocity: THREE.Vector3 = new THREE.Vector3();
  public arcadeVelocityInfluence: THREE.Vector3 = new THREE.Vector3();
  public velocityTarget: THREE.Vector3 = new THREE.Vector3();
  public arcadeVelocityIsAdditive: boolean = false;

  public defaultVelocitySimulatorDamping: number = 0.7;
  public defaultVelocitySimulatorMass: number = 50;
  public velocitySimulator: VectorSpringSimulator;
  private moveSpeed: number = 12;
  private targetMoveSpeed: number = 12;
  private defaultMoveSpeed: number = 12;
  private moveSpeedMultiplier: number = 1;
  public angularVelocity: number = 0;
  public orientation: THREE.Vector3 = new THREE.Vector3(0, 0, 1);
  public orientationTarget: THREE.Vector3 = new THREE.Vector3(0, 0, 1);
  public defaultRotationSimulatorDamping: number = 0.7; //original 0.5
  public defaultRotationSimulatorMass: number = 12;
  public rotationSimulator: RelativeSpringSimulator;
  public viewVector: THREE.Vector3;
  public actions: { [action: string]: KeyBinding };
  public characterCapsule: CapsuleCollider;
  //public formationOffset: THREE.Vector3 = new THREE.Vector3(0,0,0);

  public formationSlot: number = 0;

  // Ray casting
  public bvhRayResult: THREE.Intersection | undefined;
  public rayHasHit: boolean = false;
  public bvhRayHasHit: boolean = false;
  public rayCastLength: number = 0.57;
  public raySafeOffset: number = 0.03;
  public wantsToJump: boolean = false;
  public initJumpSpeed: number = -1;
  public groundImpactData: GroundImpactData = new GroundImpactData();

  public world: World | undefined;
  public charState: ICharacterState | undefined;
  public behaviour: ICharacterAI | undefined;

  // Vehicles
  public controlledObject: IControllable | undefined;
  public occupyingSeat: VehicleSeat | null = null;
  public vehicleEntryInstance: VehicleEntryInstance | null = null;
  public isRealCharacter = true;
  private physicsEnabled: boolean = true;

  public characterType: CharacterType;
  private hideMeshPatterns = ["ADO_", "AOB_", "INR_", "INS_", "AOP_"];

  constructor(
    gltf: any,
    world: World,
    realCharacter = true,
    type = CharacterType.None
  ) {
    super();
    this.world = world;
    this.characterType = type;
    const scene = (SkeletonUtils as any).clone(gltf.scene);
    scene.scale.set(3, 3, 3);
    this.isRealCharacter = realCharacter;
    this.readCharacterData(scene);
    this.setAnimations(gltf.animations);

    // The visuals group is centered for easy character tilting
    this.tiltContainer = new THREE.Group();
    this.add(this.tiltContainer);

    // Model container is used to reliably ground the character, as animation can alter the position of the model itself
    this.modelContainer = new THREE.Group();
    this.modelContainer.position.y = -0.57;
    this.tiltContainer.add(this.modelContainer);
    this.modelContainer.add(scene);

    this.toggleCharTypeMeshes();

    this.mixer = new THREE.AnimationMixer(scene);

    this.velocitySimulator = new VectorSpringSimulator(
      60,
      this.defaultVelocitySimulatorMass,
      this.defaultVelocitySimulatorDamping
    );
    this.rotationSimulator = new RelativeSpringSimulator(
      60,
      this.defaultRotationSimulatorMass,
      this.defaultRotationSimulatorDamping
    );

    this.viewVector = new THREE.Vector3();

    // Actions
    this.actions = {
      up: new KeyBinding("KeyW"),
      down: new KeyBinding("KeyS"),
      left: new KeyBinding("KeyA"),
      right: new KeyBinding("KeyD"),
      run: new KeyBinding("ShiftLeft"),
      crouch: new KeyBinding("KeyC"),
      cover: new KeyBinding("KeyV"),
      prone: new KeyBinding("KeyB"),
    };

    // Physics
    // Player Capsule
    this.characterCapsule = new CapsuleCollider({
      mass: 1,
      position: new CANNON.Vec3(),
      height: 0.5,
      radius: 0.25,
      segments: 8,
      friction: 0.0,
    });
    // capsulePhysics.physical.collisionFilterMask = ~CollisionGroups.Trimesh;

    this.characterCapsule.body.shapes.forEach((shape) => {
      // tslint:disable-next-line: no-bitwise
      shape.collisionFilterMask = ~CollisionGroups.TrimeshColliders;
    });
    this.characterCapsule.body.allowSleep = false;

    // Move character to different collision group for raycasting
    this.characterCapsule.body.collisionFilterGroup = 2;

    // Disable character rotation
    this.characterCapsule.body.fixedRotation = true;
    this.characterCapsule.body.updateMassProperties();

    // Physics pre/post step callback bindings

    this.world!.physicsWorld.addEventListener("preStep", () => {
      this.physicsPreStep(this);
    });
    this.world!.physicsWorld.addEventListener("postStep", () => {
      this.physicsPostStep(this);
    });
    //this.characterCapsule.body.preStep = (body: CANNON.Body) => { this.physicsPreStep(body, this); };
    //this.characterCapsule.body.postStep = (body: CANNON.Body) => { this.physicsPostStep(body, this); };

    // States
    if (this.isRealCharacter) {
      this.setState(new Idle(this));
    }
  }

  private toggleCharTypeMeshes() {
    let trimList = this.hideMeshPatterns.filter(
      (item) => item !== this.characterType + "_"
    );
    this.modelContainer.traverse((child) => {
      trimList.forEach((pattern) => {
        if (child.name.includes(pattern)) child.visible = false;
      });
    });
  }

  public setAnimations(animations: []): void {
    this.animations = animations;
  }

  public setArcadeVelocityInfluence(
    x: number,
    y: number = x,
    z: number = x
  ): void {
    this.arcadeVelocityInfluence.set(x, y, z);
  }

  public setViewVector(vector: THREE.Vector3): void {
    this.viewVector.copy(vector).normalize();
  }

  /**
   * Set state to the player. Pass state class (function) name.
   * @param {function} State
   */
  public setState(state: ICharacterState): void {
    this.charState = state;
    // this.charState.onInputChange();
  }

  public setPosition(x: number, y: number, z: number): void {
    if (this.physicsEnabled) {
      this.characterCapsule.body.previousPosition = new CANNON.Vec3(x, y, z);
      this.characterCapsule.body.position = new CANNON.Vec3(x, y, z);
      this.characterCapsule.body.interpolatedPosition = new CANNON.Vec3(
        x,
        y,
        z
      );
    } else {
      this.position.x = x;
      this.position.y = y;
      this.position.z = z;
    }
  }

  public resetVelocity(): void {
    this.velocity.x = 0;
    this.velocity.y = 0;
    this.velocity.z = 0;

    this.characterCapsule.body.velocity.x = 0;
    this.characterCapsule.body.velocity.y = 0;
    this.characterCapsule.body.velocity.z = 0;

    this.velocitySimulator.init();
  }

  public setArcadeVelocityTarget(
    velZ: number,
    velX: number = 0,
    velY: number = 0
  ): void {
    this.velocityTarget.z = velZ;
    this.velocityTarget.x = velX;
    this.velocityTarget.y = velY;
  }

  public setOrientation(
    vector: THREE.Vector3,
    instantly: boolean = false
  ): void {
    let lookVector = new THREE.Vector3().copy(vector).setY(0).normalize();
    this.orientationTarget.copy(lookVector);

    if (instantly) {
      this.orientation.copy(lookVector);
    }
  }

  public resetOrientation(): void {
    const forward = Utils.getForward(this);
    this.setOrientation(forward, true);
  }

  public setBehaviour(behaviour: ICharacterAI): void {
    if (this.behaviour) {
      this.behaviour.dispose();
    }
    this.behaviour = behaviour;
  }

  public clearBehaviour(): void {
    this.behaviour = undefined;
  }

  public setPhysicsEnabled(value: boolean): void {
    this.physicsEnabled = value;

    if (value === true) {
      this.world!.physicsWorld.addBody(this.characterCapsule.body);
    } else {
      this.world!.physicsWorld.removeBody(this.characterCapsule.body);
    }
  }

  public readCharacterData(scene: THREE.Object3D): void {
    scene.traverse((child: THREE.Object3D) => {
      if ((child as THREE.Mesh).isMesh) {
        Utils.setupMeshProperties(child, this.world);

        if ((child as THREE.Mesh).material !== undefined) {
          this.materials.push((child as THREE.Mesh).material as THREE.Material);
        }
      }
    });
  }

  public completedScenario(): void {
    //behaviour idle?
    console.log("completed scenario", this.name);

    document.dispatchEvent(
      new CustomEvent("scenarioCompleted", { detail: { name: this.name } })
    );
  }

  public setScenario(data: any): void {
    if (data.formationSlot !== undefined) {
      //console.log("Setting formation data", data.formationSlot, this.name);
      this.formationSlot = data.formationSlot;

      //console.log(this.formationSlot);
    }

    //determine if this unit is involved in the scenario

    if (data.name !== undefined) {
      this.name = data.name;
    }

    if (data.firstNode !== undefined) {
      let nodeFound = false;
      for (const pathName in this.world!.paths) {
        if (this.world!.paths.hasOwnProperty(pathName)) {
          const path = this.world!.paths[pathName];

          for (const nodeName in path.nodes) {
            if (Object.prototype.hasOwnProperty.call(path.nodes, nodeName)) {
              const node = path.nodes[nodeName];
              if (node.object.name === data.firstNode) {
                //console.log("Retarget goto", node.object.position);
                this.setBehaviour(new FollowPath(this, node, 3));
                nodeFound = true;
              }
            }
          }
        }
      }

      if (!nodeFound) {
        console.error("Path node " + data.firstNode + "not found.");
      }
    }

    if (data.follow !== undefined) {
      let target = this.world?.getEntityByName(data.follow);
      if (!target) {
        throw new Error("Follow Target entity " + data.follow + "not found.");
      }
      if (target instanceof Vehicle) {
        target = target.controllingCharacter;
      }
      this.setBehaviour(new FollowCharacter(this, target as Character));
    }

    //add pennants to lead units and single units
    if ((!this.formationSlot || this.formationSlot === 0) && !this.pennant) {
      if (
        this.name !== "boxer2" &&
        this.name !== "boxer3" &&
        this.name !== "obs2" &&
        !this.name.includes("BRDM")
      ) {
        this.pennant = new CharacterPennant(
          this,
          this.world!,
          Utils.unitNameToUnitType(this.name)
        );
      }
    }
  }

  teleport(targetPos: THREE.Vector3, targetQuaternion: THREE.Quaternion): void {
    const result = this.world?.getTerrainRaycast(targetPos.x, targetPos.z)!;
    let quaternion = new THREE.Quaternion().copy(targetQuaternion);
    let position = new THREE.Vector3(
      result.point.x,
      result.point.y + 1,
      result.point.z
    );

    if (this.controlledObject) {
      (this.controlledObject as Car).collision.position.set(
        position.x,
        position.y,
        position.z
      );
      let floorQuat = quaternion.setFromEuler(
        new Euler(
          result.face?.normal.x,
          result.face?.normal.y,
          result.face?.normal.z,
          "XYZ"
        )
      );
      quaternion.multiply(floorQuat);

      (this.controlledObject as Car).collision.quaternion.set(
        quaternion.x,
        quaternion.y,
        quaternion.z,
        quaternion.w
      );
      this.quaternion.copy(quaternion);
    } else {
      this.setPosition(position.x, position.y, position.z);
      //this.quaternion.copy(quaternion)s;

      this.setOrientation(
        new THREE.Vector3(0, 0, 1).applyQuaternion(quaternion)
      );
    }
  }

  public handleKeyboardEvent(
    event: KeyboardEvent,
    code: string,
    pressed: boolean
  ): void {}

  public handleMouseButton(
    event: MouseEvent,
    code: string,
    pressed: boolean
  ): void {
    if (this.controlledObject !== undefined) {
      this.controlledObject.handleMouseButton(event, code, pressed);
    } else {
      for (const action in this.actions) {
        if (this.actions.hasOwnProperty(action)) {
          const binding = this.actions[action];

          if (binding.eventCodes.includes(code)) {
            this.triggerAction(action, pressed);
          }
        }
      }
    }
  }

  public handleMouseMove(
    event: MouseEvent,
    deltaX: number,
    deltaY: number
  ): void {
    if (this.controlledObject !== undefined) {
      this.controlledObject.handleMouseMove(event, deltaX, deltaY);
    }
  }

  public showPromptPennant() {
    if (this.pennant) {
      this.pennant.show();
      this.pennant.showPrompt();
    }
  }

  public hidePromptPennant() {
    if (this.pennant) {
      this.pennant.hidePrompt();
    }
  }

  public handleMouseWheel(event: WheelEvent, value: number): void {
    if (this.controlledObject !== undefined) {
      this.controlledObject.handleMouseWheel(event, value);
    } else {
      this.world!.scrollTheTimeScale(value);
    }
  }

  public triggerAction(actionName: string, value: boolean): void {
    // Get action and set it's parameters
    let action = this.actions[actionName];

    if (action.isPressed !== value) {
      // Set value
      action.isPressed = value;

      // Reset the 'just' attributes
      action.justPressed = false;
      action.justReleased = false;

      // Set the 'just' attributes
      if (value) action.justPressed = true;
      else action.justReleased = true;

      // Tell player to handle states according to new input
      this.charState!.onInputChange();

      // Reset the 'just' attributes
      action.justPressed = false;
      action.justReleased = false;
    }
  }

  public takeControl(): void {
    this.isPlayer = true;
  }

  public setFollowTarget(target: Character): void {
    if (this.isPlayer) return;
    this.setBehaviour(new FollowCharacter(this, target));
  }

  public resetControls(): void {
    for (const action in this.actions) {
      if (this.actions.hasOwnProperty(action)) {
        this.triggerAction(action, false);
      }
    }
  }
  _newPos = new THREE.Vector3();

  public update(timeStep: number): void {
    this.behaviour?.update(timeStep);
    this.vehicleEntryInstance?.update(timeStep);
    // console.log(this.occupyingSeat);
    this.charState?.update(timeStep);

    // this.visuals.position.copy(this.modelOffset);
    if (this.physicsEnabled) this.springMovement(timeStep);
    if (this.physicsEnabled) this.springRotation(timeStep);
    if (this.physicsEnabled) this.rotateModel();
    if (this.mixer !== undefined) this.mixer.update(timeStep);

    // Sync physics/graphics
    if (this.physicsEnabled) {
      this.position.set(
        this.characterCapsule.body.interpolatedPosition.x,
        this.characterCapsule.body.interpolatedPosition.y,
        this.characterCapsule.body.interpolatedPosition.z
      );
    } else {
      this.getWorldPosition(this._newPos);

      this.characterCapsule.body.position.copy(
        Utils.cannonVector(this._newPos)
      );
      this.characterCapsule.body.interpolatedPosition.copy(
        Utils.cannonVector(this._newPos)
      );
    }

    if (this.controlledObject) {
      this.quaternion.copy((this.controlledObject as Car).quaternion);
    }

    this.updateMatrixWorld();
  }

  public inputReceiverInit(): void {
    if (this.controlledObject !== undefined) {
      this.controlledObject.inputReceiverInit();
      return;
    }
  }

  public inputReceiverUpdate(timeStep: number): void {
    if (this.controlledObject !== undefined) {
      this.controlledObject.inputReceiverUpdate(timeStep);
    } else {
      // Look in camera's direction
      this.viewVector = new THREE.Vector3().subVectors(
        this.position,
        this.world!.camera.position
      );
    }
  }

  public setAnimation(
    clipName: string,
    fadeIn: number,
    callback:
      | undefined
      | THREE.EventListener<
          THREE.Event,
          "finished",
          AnimationMixer
        > = undefined,
    randomStart = false
  ): number | void {
    if (this.mixer === undefined) throw new Error("No mixer");

    // gltf

    let clip = this.animations.find((anim) => anim.name.includes(clipName));
    let oldClip = this.animations.find((anim) =>
      anim.name.includes(this.currentClipName)
    );

    if (clip === undefined) {
      console.error(this.animations);
      console.warn(
        `Animation ${clipName} not found. Does it exist in the model file at this alertness level?`
      );
      throw new Error(`Animation clip ${clipName} not found.`);
    }

    let action = this.mixer.clipAction(clip);
    let oldAction = this.mixer.clipAction(oldClip);

    if (clipName === this.currentClipName) return action.getClip().duration;

    if (action === null || oldAction === null) {
      console.warn(
        `Animation ${clipName} not found. Does it exist in the model file at this alertness level?`
      );
      console.error(
        `Animation ${clipName} or ${this.currentClipName} not found!`
      );
      return 0;
    }

    (this.mixer as any)._listeners = undefined;
    this.mixer.stopAllAction();
    oldAction.fadeOut(fadeIn);
    oldAction.play();
    action.fadeIn(fadeIn);
    if (randomStart) {
      action.time = Math.random() * action.getClip().duration;
    }
    action.play();

    if (callback) {
      action.loop = THREE.LoopOnce;
      this.mixer.addEventListener("finished", callback);
    }

    //console.log(`Switched to animation ${clipName} from ${this.currentClipName}`);
    //console.log(`clip duration is ${action.getClip().duration}`);
    this.currentClipName = clipName;

    return action.getClip().duration;
  }

  public springMovement(timeStep: number): void {
    // Simulator
    this.velocitySimulator.target!.copy(this.velocityTarget);
    this.velocitySimulator.simulate(timeStep);

    // Update values
    this.velocity.copy(this.velocitySimulator.position!);
    this.acceleration.copy(this.velocitySimulator.velocity!);
  }

  public springRotation(timeStep: number): void {
    // Spring rotation
    // Figure out angle between current and target orientation
    let angle = Utils.getSignedAngleBetweenVectors(
      this.orientation,
      this.orientationTarget
    );

    // Simulator
    this.rotationSimulator.target = angle;
    this.rotationSimulator.simulate(timeStep);
    let rot = this.rotationSimulator.position;

    // Updating values
    this.orientation.applyAxisAngle(new THREE.Vector3(0, 1, 0), rot);
    this.angularVelocity = this.rotationSimulator.velocity;
  }

  public getLocalMovementDirection(): THREE.Vector3 {
    const positiveX = this.actions.right.isPressed ? -1 : 0;
    const negativeX = this.actions.left.isPressed ? 1 : 0;
    const positiveZ = this.actions.up.isPressed ? 1 : 0;
    const negativeZ = this.actions.down.isPressed ? -1 : 0;

    return new THREE.Vector3(
      positiveX + negativeX,
      0,
      positiveZ + negativeZ
    ).normalize();
  }

  public getCameraRelativeMovementVector(): THREE.Vector3 {
    const localDirection = this.getLocalMovementDirection();
    const flatViewVector = new THREE.Vector3(
      this.viewVector.x,
      0,
      this.viewVector.z
    ).normalize();

    return Utils.appplyVectorMatrixXZ(flatViewVector, localDirection);
  }

  public setCameraRelativeOrientationTarget(): void {
    if (this.vehicleEntryInstance === null) {
      let moveVector = this.getCameraRelativeMovementVector();

      if (moveVector.x === 0 && moveVector.y === 0 && moveVector.z === 0) {
        this.setOrientation(this.orientation);
      } else {
        this.setOrientation(moveVector);
      }
    }
  }

  public rotateModel(): void {
    this.lookAt(
      this.position.x + this.orientation.x,
      this.position.y + this.orientation.y,
      this.position.z + this.orientation.z
    );
    this.tiltContainer.rotation.z =
      -this.angularVelocity * 2.3 * this.velocity.length();
    this.tiltContainer.position.setY(
      Math.cos(Math.abs(this.angularVelocity * 2.3 * this.velocity.length())) /
        2 -
        0.5
    );
  }

  public jump(initJumpSpeed: number = -1): void {
    this.wantsToJump = true;
    this.initJumpSpeed = initJumpSpeed;
  }

  public teleportToVehicle(vehicle: Vehicle, seat: VehicleSeat): void {
    this.resetVelocity();
    this.rotateModel();
    this.setPhysicsEnabled(false);
    (vehicle as unknown as THREE.Object3D).attach(this);

    this.setPosition(
      seat.seatPointObject.position.x,
      seat.seatPointObject.position.y + 0.6,
      seat.seatPointObject.position.z
    );
    this.quaternion.copy(seat.seatPointObject.quaternion);

    this.occupySeat(seat);
    //this.setState(new Driving(this, seat.vehicle as Vehicle));

    this.startControllingVehicle(vehicle, seat);
  }

  public startControllingVehicle(
    vehicle: IControllable,
    seat: VehicleSeat
  ): void {
    if (this.controlledObject !== vehicle) {
      this.transferControls(vehicle);
      this.resetControls();

      this.controlledObject = vehicle;
      this.controlledObject.allowSleep(false);
      vehicle.inputReceiverInit();

      vehicle.controllingCharacter = this;
    }
  }

  public transferControls(entity: IControllable): void {
    // Currently running through all actions of this character and the vehicle,
    // comparing keycodes of actions and based on that triggering vehicle's actions
    // Maybe we should ask input manager what's the current state of the keyboard
    // and read those values... TODO
    for (const action1 in this.actions) {
      if (this.actions.hasOwnProperty(action1)) {
        for (const action2 in entity.actions) {
          if (entity.actions.hasOwnProperty(action2)) {
            let a1 = this.actions[action1];
            let a2 = entity.actions[action2];

            a1.eventCodes.forEach((code1) => {
              a2.eventCodes.forEach((code2) => {
                if (code1 === code2) {
                  entity.triggerAction(action2, a1.isPressed);
                }
              });
            });
          }
        }
      }
    }
  }

  public stopControllingVehicle(): void {
    if (this.controlledObject?.controllingCharacter === this) {
      this.controlledObject.allowSleep(true);
      this.controlledObject.controllingCharacter = undefined;
      this.controlledObject.resetControls();
      this.controlledObject = undefined;
      this.inputReceiverInit();
    }
  }

  public exitVehicle(): void {
    if (this.occupyingSeat !== null) {
      //this.setState(new ExitingVehicle(this, this.occupyingSeat));

      this.stopControllingVehicle();
    }
  }

  public occupySeat(seat: VehicleSeat): void {
    this.occupyingSeat = seat;
    seat.occupiedBy = this;
  }

  public leaveSeat(): void {
    if (this.occupyingSeat !== null) {
      this.occupyingSeat.occupiedBy = null;
      this.occupyingSeat = null;
    }
  }

  public setMoveSpeed(speed: number): void {
    this.moveSpeed = speed;
    this.targetMoveSpeed = this.moveSpeed * this.moveSpeedMultiplier;
    if (this.controlledObject) {
      this.controlledObject.setMoveSpeed(this.targetMoveSpeed);
      this.followers.forEach((follower) =>
        follower.controlledObject?.setMoveSpeed(speed)
      );
    }
    this.followers.forEach((follower) => follower.setMoveSpeed(speed));
  }
  public setMoveSpeedMultiplier(speed: number): void {
    this.moveSpeedMultiplier = speed;
    this.targetMoveSpeed = this.moveSpeed * this.moveSpeedMultiplier;
    if (this.controlledObject) {
      this.controlledObject.setMoveSpeed(this.targetMoveSpeed);
    }
    this.followers.forEach((follower) => follower.setMoveSpeed(speed));
  }

  public physicsPreStep(character: Character): void {
    character.feetRaycast();
  }

  public feetRaycast(secondPass = false): void {
    if (this.controlledObject !== undefined) {
      this.bvhRayResult = undefined;
      this.rayHasHit = false;
      return;
    }
    // Player ray casting
    // Create ray
    let body = this.characterCapsule.body;

    // bvh
    let origin = new THREE.Vector3(
      body.position.x,
      body.position.y + 10,
      body.position.z
    );

    let direction = new THREE.Vector3(0, -1, 0);

    if (secondPass) {
      //cast up in case we went through floor
      origin = new THREE.Vector3(
        body.position.x,
        body.position.y + 300,
        body.position.z
      );
      direction = new THREE.Vector3(0, -1, 0);
    }

    const raycaster = new THREE.Raycaster(origin, direction);
    raycaster.layers.set(99);
    raycaster.firstHitOnly = true;

    let invMat = new THREE.Matrix4();
    invMat.copy(this.world?.physicsTerrainMesh.matrixWorld!).invert();

    // raycasting
    // ensure the ray is in the local space of the geometry being cast against
    raycaster.ray.applyMatrix4(invMat);

    const result = raycaster.intersectObjects(
      [this.world?.physicsTerrainMesh as THREE.Object3D],
      false
    );
    if (result.length > 0) {
      this.rayHasHit = true;
      this.bvhRayResult = result[0];
    } else {
      this.bvhRayResult = undefined;
      this.rayHasHit = false;
      if (!secondPass) this.feetRaycast(true); //second pass with upward cast
    }
  }

  public physicsPostStep(character: Character): void {
    // Get velocities
    let simulatedVelocity = new THREE.Vector3(
      this.characterCapsule.body.velocity.x,
      this.characterCapsule.body.velocity.y,
      this.characterCapsule.body.velocity.z
    );

    // Take local velocity
    let arcadeVelocity = new THREE.Vector3()
      .copy(character.velocity)
      .multiplyScalar(character.targetMoveSpeed);
    // Turn local into global
    arcadeVelocity = Utils.appplyVectorMatrixXZ(
      character.orientation,
      arcadeVelocity
    );

    let newVelocity = new THREE.Vector3();

    // Additive velocity mode
    if (character.arcadeVelocityIsAdditive) {
      newVelocity.copy(simulatedVelocity);

      let globalVelocityTarget = Utils.appplyVectorMatrixXZ(
        character.orientation,
        character.velocityTarget
      );
      let add = new THREE.Vector3()
        .copy(arcadeVelocity)
        .multiply(character.arcadeVelocityInfluence);

      if (
        Math.abs(simulatedVelocity.x) <
          Math.abs(globalVelocityTarget.x * character.targetMoveSpeed) ||
        Utils.haveDifferentSigns(simulatedVelocity.x, arcadeVelocity.x)
      ) {
        newVelocity.x += add.x;
      }
      if (
        Math.abs(simulatedVelocity.y) <
          Math.abs(globalVelocityTarget.y * character.targetMoveSpeed) ||
        Utils.haveDifferentSigns(simulatedVelocity.y, arcadeVelocity.y)
      ) {
        newVelocity.y += add.y;
      }
      if (
        Math.abs(simulatedVelocity.z) <
          Math.abs(globalVelocityTarget.z * character.targetMoveSpeed) ||
        Utils.haveDifferentSigns(simulatedVelocity.z, arcadeVelocity.z)
      ) {
        newVelocity.z += add.z;
      }
    } else {
      newVelocity = new THREE.Vector3(
        THREE.MathUtils.lerp(
          simulatedVelocity.x,
          arcadeVelocity.x,
          character.arcadeVelocityInfluence.x
        ),
        THREE.MathUtils.lerp(
          simulatedVelocity.y,
          arcadeVelocity.y,
          character.arcadeVelocityInfluence.y
        ),
        THREE.MathUtils.lerp(
          simulatedVelocity.z,
          arcadeVelocity.z,
          character.arcadeVelocityInfluence.z
        )
      );
    }

    // If we're hitting the ground, stick to ground
    if (character.rayHasHit) {
      // Flatten velocity
      newVelocity.y = 0;

      // Measure the normal vector offset from direct "up" vector
      // and transform it into a matrix
      let up = new THREE.Vector3(0, 1, 0);
      let normal = new THREE.Vector3(
        character.bvhRayResult?.face?.normal.x,
        character.bvhRayResult?.face?.normal.y,
        character.bvhRayResult?.face?.normal.z
      );
      let q = new THREE.Quaternion().setFromUnitVectors(up, normal);
      let m = new THREE.Matrix4().makeRotationFromQuaternion(q);

      // Rotate the velocity vector
      newVelocity.applyMatrix4(m);

      // Compensate for gravity
      // newVelocity.y -= body.world.physicsWorld.gravity.y / body.character.world.physicsFrameRate;

      // Apply velocity
      this.characterCapsule.body.velocity.x = newVelocity.x;
      this.characterCapsule.body.velocity.y = newVelocity.y;
      this.characterCapsule.body.velocity.z = newVelocity.z;
      // Ground character
      this.characterCapsule.body.position.y =
        character.bvhRayResult!.point.y +
        character.rayCastLength +
        newVelocity.y / character.world!.physicsFrameRate;
    } else {
      // If we're in air
      this.characterCapsule.body.velocity.x = newVelocity.x;
      this.characterCapsule.body.velocity.y = newVelocity.y;
      this.characterCapsule.body.velocity.z = newVelocity.z;

      // Save last in-air information
      character.groundImpactData.velocity.x =
        this.characterCapsule.body.velocity.x;
      character.groundImpactData.velocity.y =
        this.characterCapsule.body.velocity.y;
      character.groundImpactData.velocity.z =
        this.characterCapsule.body.velocity.z;
    }
  }

  public addToWorld(world: World): void {
    if (world.characters.includes(this)) {
      console.warn("Adding character to a world in which it already exists.");
    } else {
      // Set world
      this.world = world;

      // Register character
      world.characters.push(this);

      // Register physics
      world.physicsWorld.addBody(this.characterCapsule.body);

      // Add to graphicsWorld
      world.graphicsWorld.add(this);

      // Shadow cascades
      /*
      this.materials.forEach((mat) => {
        world.sky.csm.setupMaterial(mat);
      });
      */
    }
  }

  public removeFromWorld(world: World): void {
    if (!world.characters.includes(this)) {
      console.warn(
        "Removing character from a world in which it isn't present."
      );
    } else {
      this.world = undefined;

      // Remove from characters
      world.characters = world.characters.filter((character) => {
        return character !== this;
      });

      // Remove physics
      world.physicsWorld.removeBody(this.characterCapsule.body);

      // Remove visuals
      world.graphicsWorld.remove(this);
    }
  }
}
