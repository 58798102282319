import { Box, Flex, Text, Image } from "@chakra-ui/react";

import { motion } from "framer-motion";
import { RadioOrder } from "../useStore";

const ARStatus = ({
  children,
  radioOrder,
}: {
  children?: any;
  radioOrder: RadioOrder;
}) => {
  return (
    <Flex
      marginTop="25px !important"
      width="100%"
      height="110px"
      flexDirection="row"
      background="rgba(0, 0, 0, 0.2)"
      backdropFilter="blur(8px)"
      padding="15px"
      borderTop="1px dashed #48E000"
      as={motion.div}
      onClick={() => {
        document.dispatchEvent(
          new CustomEvent("voiceoverEnded", { detail: {} })
        );
      }}
      animation="all 0.5s ease-in-out"
      /*
      animate={{
        scale: [0.8, 1],
        translateY: [-50, 0],
        scaleY: [0, 1],
      }}
      */
      /*@ts-ignore*/
      transition={{
        duration: 100,
        ease: "easeIn",
        type: "tween",
      }}
    >
      <Flex flexDirection="column" flexGrow={1} width="100%">
        <Flex justifyContent="center">
          <Text variant="subheading" color="#48E000" textAlign="center">
            {radioOrder.title}
          </Text>
        </Flex>
        <Flex flexGrow={1}>
          <Box
            fontSize="13px"
            lineHeight="18px"
            width="100%"
            height="100%"
            color="white"
            textAlign="center"
          >
            {radioOrder.content}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ARStatus;
