import { Sequence } from "./Sequence";
import { ScenarioStage } from "../../enums/ScenarioStage";
import { World } from "../../world/World";
import * as THREE from "three";
import { AudioManager } from "../../core/AudioManager";
export class Scenario6 extends Sequence {
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_6";
    this.units = ["inf1"];
    this.unitType = "INS";
    this.target = new THREE.Vector3(77, 100, 18);
  }
  /*
  override pathFinished(e: any): void {
    document.removeEventListener("pathFinished", this.pathFinished.bind(this));
    if (!this.active) return;
    super.pathFinished(e);
    AudioManager.playSFX("sfx_buildingBreach");
    this.world
      .getPropByName("breach_house1")!
      .setAnimation("HS_gun_flashes", false);
  }
  override dispose(): void {
    super.dispose();
    document.removeEventListener("pathFinished", this.pathFinished.bind(this));
  }
  */
}
