import * as THREE from "three";
import { IUpdatable } from "../interfaces/IUpdatable";
import { World } from "../world/World";
import { VolumetricFire } from "./VolumetricFire";

export class VolumetricFireball extends THREE.Object3D implements IUpdatable {
  private fires: VolumetricFire[] = [];
  private riseRates: number[] = [0.985, 1.02, 0.97, 1];
  private rotateRates: number[] = [-3, 0.2, -1, 2.2];
  private world: World;

  updateOrder: number = 10;

  constructor(world: World) {
    super();
    this.world = world;
    this.world.graphicsWorld.add(this);
    this.world.registerUpdatable(this);
    const fire1 = new VolumetricFire(this.world);
    const fire2 = new VolumetricFire(this.world);
    const fire3 = new VolumetricFire(this.world);
    const fire4 = new VolumetricFire(this.world);

    this.add(fire1);
    this.add(fire2);
    this.add(fire3);
    this.add(fire4);

    fire1.position.set(3.5, 3.7, 3.9);
    fire2.position.set(-4.5, 1.8, -4);
    fire3.position.set(-3, 2.7, 2.1);
    fire4.position.set(1, 2.1, -1);

    // natural scale this.scale.set(0.25, 0.37, 0.25);

    fire1.scale.set(0.55, 0.37, 0.35);
    fire2.scale.set(0.48, 0.35, 0.28);
    //fire3.scale.set(1, 1, 1);
    fire4.scale.set(0.37, 0.31, 0.32);

    this.fires.push(fire1);
    this.fires.push(fire2);
    this.fires.push(fire3);
    this.fires.push(fire4);
  }
  update(timestep: number, unscaledTimeStep: number): void {
    this.fires.forEach((fire, i) => {
      fire.position.y += timestep * this.riseRates[i] * 14;
      fire.rotateY(timestep * this.rotateRates[i] * 0.5);
      fire.material.uniforms["fade"].value -= timestep * 0.3;
      //TODO - dispose once gone
    });
  }
  dispose(): void {
    this.fires.forEach((fire) => {
      fire.dispose();
      this.world.unregisterUpdatable(fire);
      this.world.graphicsWorld.remove(fire);
    });
    this.world.unregisterUpdatable(this);
    this.world.graphicsWorld.remove(this);
  }
}
