import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Briefing from "./Briefing";
import Complete from "./Complete";
import Experience from "./Experience";
import Home from "./Home";
import Instructions from "./Instructions";
import Roles from "./Roles/Roles";
import Terms from "./Terms";
import ReactGA from "react-ga4";
const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    // track pageview with gtag / react-ga / react-ga4, for example:
    try {
      ReactGA.pageview(window.location.pathname + window.location.search);
    } catch (e) {}
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/experience" element={<Experience />} />
      <Route path="/briefing" element={<Briefing />} />
      <Route path="/instructions" element={<Instructions />} />
      <Route path="/complete" element={<Complete />} />
      <Route path="/roles" element={<Roles />} />
      <Route path="/terms" element={<Terms />} />
    </Routes>
  );
};

export default AppRoutes;
