import "./index.css";
import { StrictMode } from "react";
import App from "./App";
import { XR8 } from "./XR8/XR8";
import * as THREE from "three";
import { createRoot } from "react-dom/client";
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga4";

ReactGA.initialize("G-9DX8CX4W4C");

Sentry.init({
  dsn: "https://6567a84150854d2d887130094d8793b8@o1134907.ingest.sentry.io/6473789",
  environment: process.env.NODE_ENV,
});

declare global {
  interface Window {
    XR8: XR8;
    XRExtras: any;
    BATTLE: any;
    THREE: typeof THREE;
  }
}

window.THREE = THREE;
window.BATTLE = window.BATTLE ? window.BATTLE : {};
window.BATTLE.USER_HEIGHT = 700;
window.BATTLE.PLINTH_HEIGHT = 600;
window.BATTLE.bufferLength = 2;
const colors = {
  brand: {
    yellow: "#F1B700",
    lightGrey: "#D8D8D8",
    mediumGrey: "#595959",
    darkGrey: "#333333",
    green: "#48E000",
    turquoise: "#15EEE1",
    gradientLight: "#89AEAF",
    gradientDark: "#1C1C1C",
  },
};

const fonts = {
  heading: "GeomGraphic-Light, sans-serif",
  body: "GeomGraphic-Semibold, sans-serif",
};

const textVariants = {
  large: {
    fontSize: "31px",
    lineHeight: "40px",
    letterSpacing: "0.15em",
    whiteSpace: "normal !important",
  },
  yellow: {
    color: colors.brand.yellow,
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.1em",
    fontWeight: "300",
  },
  subheading: {
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.1em",
    fontFamily: fonts.heading,
  },
  clock: {
    fontSize: "15px",
    lineHeight: "18px",
    letterSpacing: "0.05em",
    fontFamily: "GeomGraphic-Light, sans-serif",
  },
  label: {
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.1em",
    fontFamily: "GeomGraphic-Semibold, sans-serif",
  },
  labelYellow: {
    color: colors.brand.yellow,
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.1em",
    textAlign: "left",
    borderBottom: "1px solid rgba(255, 255, 255, 0.15)",
    paddingBottom: "9px",
    width: "100%",
    margin: "0",
  },
  geomLight: {
    fontFamily: "GeomGraphic-Light, sans-serif",
  },
  geom: {
    fontFamily: "GeomGraphic-Semibold, sans-serif",
  },
};

const components = {
  Text: {
    baseStyle: {
      color: "white",
    },
    variants: textVariants,
  },
  Heading: {
    baseStyle: {
      fontWeight: "600",
      fontSize: "18px",
      margin: "auto",
      textAlign: "center",
      color: "white",
      textTransform: "uppercase",
      letterSpacing: "0.15em",
      lineHeight: "22px",
      fontFamily: "GeomGraphic-Semibold, sans-serif",
      whiteSpace: "normal !important",
    },
    sizes: {
      xl: {
        fontSize: "18px",
        lineHeight: "22px",
        letterSpacing: "0.15em",
      },
      sm: {
        fontSize: "12px",
        lineHeight: "14px",
        letterSpacing: "0.1em",
        fontFamily: "GeomGraphic-Semibold, sans-serif",
      },
      xs: {
        fontSize: "12px",
        lineHeight: "14px",
        letterSpacing: "0.1em",
      },
    },
    variants: textVariants,
  },
};

const theme = extendTheme({ colors, components });

const container = document.getElementById("root") as Element;
const root = createRoot(container!);
root.render(
  <ChakraProvider resetCSS={true} theme={theme}>
    <App />
  </ChakraProvider>
);
