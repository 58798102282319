import { AudioManager } from "./../../core/AudioManager";
import { Sequence } from "./Sequence";
import { ScenarioStage } from "../../enums/ScenarioStage";
import { World } from "../../world/World";
import * as THREE from "three";
export class Scenario3 extends Sequence {
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_3";
    this.units = ["infHigh1"];
    this.target = new THREE.Vector3(90, 140, 90);
    this.unitType = "INR";
  }
}
