import { AudioManager } from "../../core/AudioManager";
import { Sequence } from "./Sequence";
import { ScenarioStage } from "../../enums/ScenarioStage";
import { World } from "../../world/World";

export class Scenario13 extends Sequence {
  // Enemy vehicles turn
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_13";
    this.units = ["BRDM1"];
    this.unitType = "OPFOR";
    this.promptUnit = false;
  }

  override introStage(): void {
    // don't do usual setup since we control this move.
    this.hidePrompts();
    this.hideTargetPennants();
    this.hideUnitPennants();
    this.changeState(ScenarioStage.Movement);
    AudioManager.playSFX("sfx_gunfire_small").loop(true);
  }

  override inputWaitStage() {
    this.hidePrompts();
    this.hideTargetPennants();
    this.hideUnitPennants();
    document.addEventListener("pennantTap", this.pennantTapped);
  }

  override movementStage(): void {
    //super.movementStage();
    this.hidePrompts();
    this.hideTargetPennants();
    this.hideUnitPennants();
    if (this.scenario) {
      const match = this.world.scenarios.find(
        (scenario) => scenario.name === this.scenario
      );
      if (match) this.world.changeScenario(this.scenario);
    }
  }
}
