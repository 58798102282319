import * as CANNON from "cannon-es";
import * as THREE from "three";
import { Object3D } from "three";

import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";

import * as Utils from "../core/FunctionLibrary";
import { EntityType } from "../enums/EntityType";
import { IWorldEntity } from "../interfaces/IWorldEntity";
import { World } from "../world/World";
import anime from "animejs";

export class Prop extends THREE.Object3D implements IWorldEntity {
  public updateOrder: number = 1;
  public entityType: EntityType = EntityType.Prop;
  public world: World;

  public materials: THREE.Material[] = [];
  public mixer: THREE.AnimationMixer;
  public animations: any[] = [];
  public currentClipName = "idle";

  constructor(gltf: any, world: World) {
    super();
    this.world = world;
    //const scene = gltf.scene.clone();
    let scene;
    if (gltf.scene) {
      scene = (SkeletonUtils as any).clone(gltf.scene);
      gltf.scene.visible = false;
    } else {
      scene = gltf; // we are referencing a mesh already in the world.glb
    }
    scene.scale.set(3, 3, 3);
    this.mixer = new THREE.AnimationMixer(scene);
    this.readPropData(scene);
    this.setAnimations(gltf.animations);
    this.add(scene);
    this.world.add(this);
  }

  public setAnimations(animations: []): void {
    this.animations = animations;
  }

  public readPropData(scene: any): void {
    scene.traverse((child: THREE.Object3D) => {
      if ((child as THREE.Mesh).isMesh) {
        Utils.setupMeshProperties(child, this.world);

        if ((child as THREE.Mesh).material !== undefined) {
          this.materials.push((child as THREE.Mesh).material as THREE.Material);
        }
      }
    });
  }

  public setScenario(data: any): void {
    if (data.name !== undefined) {
      this.name = data.name;
    }
  }

  public update(timeStep: number): void {
    this.mixer.update(timeStep);
    this.updateMatrixWorld();
    //console.log("prop update");
  }

  public setVisible(visible: boolean, duration = 3000): void {
    if (visible) this.visible = true; // need to see it appearing

    anime({
      targets: this.materials,
      opacity: visible ? 1 : 0,
      easing: "linear",
      duration: duration,
      complete: () => {
        this.visible = visible;
        console.log("completed setVisible", this.name);
        console.log(this.materials);
      },
    }).play();
  }

  public setOutline(outline: boolean): void {
    this.traverse((child: THREE.Object3D) => {
      if (child.type === "Mesh") {
        if (outline) this.world!.outlineObjects.add(child);
        else this.world!.outlineObjects.delete(child);
      }
    });
    if (this.type === "Mesh") {
      if (outline) this.world!.outlineObjects.add(this);
      else this.world!.outlineObjects.delete(this);
    }
  }

  public setAnimation(clipName: string, loop = false): number | void {
    let clip = this.animations.find((anim) => anim.name === clipName);
    let action = this.mixer.clipAction(clip);

    if (action === null) {
      console.error(`Animation ${clipName}  not found!`);
      return 0;
    }
    this.mixer.stopAllAction();
    if (loop) {
      (action as any).setLoop(THREE.LoopRepeat);
    } else {
      (action as any).setLoop(THREE.LoopOnce);
      action.clampWhenFinished = true;
    }

    action.reset().play();
    return action.getClip().duration;
  }

  public addToWorld(world: World): void {
    if (world.props.includes(this)) {
      console.warn("Adding prop to a world in which it already exists.");
    } else {
      // Set world
      this.world = world;

      // Register character
      world.props.push(this);

      // Add to graphicsWorld
      world.graphicsWorld.add(this);
      // Shadow cascades
      /*
      this.materials.forEach((mat) => {
        world.sky.csm.setupMaterial(mat);
      });
      */
    }
  }

  public removeFromWorld(world: World): void {
    if (!world.props.includes(this)) {
      console.warn("Removing prop from a world in which it isn't present.");
    } else {
      // Remove from props
      world.props = world.props.filter((prop) => {
        return prop !== this;
      });

      // Remove visuals
      world.graphicsWorld.remove(this);
    }
  }
}
