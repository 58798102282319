import "./App.css";
import backgroundSrc from "./assets/bg-gradient.svg";
import { useState, useEffect, Component, useRef } from "react";
import useStore, { NavState, WorldState } from "./useStore";

import Home from "./Pages/Home";
import { Box } from "@chakra-ui/react";

import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Test from "./Pages/Test";
import Experience from "./Pages/Experience";
import { experiencePipelineModule } from "./XR8/experiencePipelineModule";
import Complete from "./Pages/Complete";
import Roles from "./Pages/Roles/Roles";
import Briefing from "./Pages/Briefing";
import Instructions from "./Pages/Instructions";
import Terms from "./Pages/Terms";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import AppRoutes from "./Pages/AppRoutes";

export const App = () => {
  const { isDesktop, XR8Loaded, worldState } = useStore((state) => state);
  const {
    setIsDesktop,
    setXR8Loaded,
    setWorldState,
    setScenarioStage,
    setCurrentScenario,
    setRadioComms,
    setRadioOrder,
  } = useStore((state) => state.api);

  const [error, setError] = useState<Error>();
  if (error) throw error;

  const isDesktopRef = useRef(isDesktop);

  function isMobile() {
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      (navigator.platform === "MacIntel" &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 1)
    );
  }

  function scenarioStageChanged(e: any) {
    setScenarioStage(e.detail.state);
    setCurrentScenario(e.detail);
  }

  function onSetRadio(e: any) {
    if (e.detail.type === "comms") {
      setRadioComms(e.detail);
      setRadioOrder(undefined);
    } else {
      setRadioComms(undefined);
      setRadioOrder(e.detail);
    }
  }

  function sceneLoaded(e: any) {
    console.log("set placement");
    console.log(isDesktopRef.current, XR8Loaded);

    if (isDesktopRef.current) {
      //setWorldState(WorldState.PLACEMENT);
      setWorldState(WorldState.READY);
      document.dispatchEvent(new CustomEvent("sceneReady", {}));
    } else {
      window.XR8.XrController.recenter();
      setWorldState(WorldState.PLACEMENT);
    }
  }

  useEffect(() => {
    //set XR8 loaded state on sdk load
    if (!isDesktop) {
      window.onload = () => {
        window.XRExtras
          ? setXR8Loaded(true)
          : window.addEventListener("xrextrasloaded", () => setXR8Loaded(true));
      };
    } else {
      setXR8Loaded(true);
      document.getElementById("root")!.style.touchAction = "none";
      document.getElementById("root")!.style.pointerEvents = "none";
    }
  }, [isDesktop, setXR8Loaded]);

  // convenience function to set the navState while building
  useEffect(() => {
    setIsDesktop(!isMobile());
    isDesktopRef.current = !isMobile();

    document.addEventListener("scenarioStateChanged", scenarioStageChanged);

    document.addEventListener("sceneLoaded", sceneLoaded);
    document.addEventListener("setRadio", onSetRadio);

    return () => {
      document.removeEventListener(
        "scenarioStageChanged",
        scenarioStageChanged
      );
      document.removeEventListener("sceneLoaded", sceneLoaded);
      document.removeEventListener("setRadio", onSetRadio);
    };
  }, []);

  return (
    <ErrorBoundary onError={setError}>
      <Box
        className="App"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        zIndex={10}
      >
        <BrowserRouter>
          <AppRoutes></AppRoutes>
        </BrowserRouter>
      </Box>
    </ErrorBoundary>
  );
};

class ErrorBoundary extends Component<{
  children: any;
  onError: (error: Error) => void;
}> {
  static getDerivedStateFromError = (error: Error) => ({ error });

  state: { error?: Error } = {};

  componentDidCatch(error: Error) {
    this.props.onError(error);
  }

  render() {
    if (this.state.error) return null;
    return this.props.children;
  }
}

export default App;
