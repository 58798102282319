import anime from "animejs";
import * as CANNON from "cannon-es";
import { Vec3 } from "cannon-es";
import * as THREE from "three";
import { Euler } from "three";
import {
  acceleratedRaycast,
  computeBoundsTree,
  disposeBoundsTree,
} from "three-mesh-bvh";

import { Character } from "../characters/Character";
import { FollowPath } from "../characters/character_ai/FollowPath";
import { VehicleHalt } from "../characters/character_ai/VehicleHalt";
import * as Utils from "../core/FunctionLibrary";
//import _ = require('lodash');
import { KeyBinding } from "../core/KeyBinding";
import { CollisionGroups } from "../enums/CollisionGroups";
import { EntityType } from "../enums/EntityType";
import { IWorldEntity } from "../interfaces/IWorldEntity";
import { World } from "../world/World";
import BvhRaycastVehicle from "./BvhRaycastVehicle";
import { VehicleSeat } from "./VehicleSeat";
import { Wheel } from "./Wheel";

// Add the extension functions
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

export abstract class Vehicle extends THREE.Object3D implements IWorldEntity {
  public updateOrder: number = 2;
  public abstract entityType: EntityType;

  public targetMoveSpeed = 27;
  public defaultMoveSpeed = 27;

  public controllingCharacter: Character | undefined;
  public actions: { [action: string]: KeyBinding } = {};
  public rayCastVehicle: BvhRaycastVehicle;
  public seats: VehicleSeat[] = [];
  public wheels: Wheel[] = [];
  public drive: string | undefined;
  public camera: any;
  public world: World | undefined;
  public help: THREE.AxesHelper;
  public collision: CANNON.Body;
  public materials: THREE.Material[] = [];
  public spawnPoint: THREE.Object3D | undefined;
  private modelContainer: THREE.Group;
  private firstPerson: boolean = false;
  private haltTimeout: any;

  public speed: number = 0;
  constructor(gltf: any, world: World, handlingSetup?: any) {
    super();
    this.world = world;
    if (handlingSetup === undefined) handlingSetup = {};
    handlingSetup.chassisConnectionPointLocal = new CANNON.Vec3();
    handlingSetup.axleLocal = new CANNON.Vec3(-1, 0, 0);
    handlingSetup.directionLocal = new CANNON.Vec3(0, -1, 0);

    // Physics mat
    let mat = new CANNON.Material("Mat");
    mat.friction = 10000000;
    mat.restitution = 0.00000000000001;

    // Collision body
    this.collision = new CANNON.Body({ mass: 5000 });
    this.collision.material = mat;

    const scene = gltf.scene.clone();
    scene.scale.set(3, 3, 3);

    // Read GLTF
    this.readVehicleData(scene);

    this.modelContainer = new THREE.Group();

    this.add(this.modelContainer);
    this.modelContainer.add(scene);

    // Raycast vehicle component
    this.rayCastVehicle = new BvhRaycastVehicle({
      chassisBody: this.collision,
      indexUpAxis: 1,
      indexRightAxis: 0,
      indexForwardAxis: 2,
    });

    this.wheels.forEach((wheel) => {
      handlingSetup.chassisConnectionPointLocal.set(
        wheel.position.x * 3,
        wheel.position.y * 3 + 0.6,
        wheel.position.z * 3
      );
      //console.log(handlingSetup.radius);
      handlingSetup.radius = 1.5;
      const index = this.rayCastVehicle.addWheel(handlingSetup);
      wheel.rayCastWheelInfoIndex = index;
    });

    this.help = new THREE.AxesHelper(2);
  }

  public setMoveSpeed(speed: number): void {
    this.targetMoveSpeed = speed;
  }

  public noDirectionPressed(): boolean {
    return true;
  }

  public update(timeStep: number): void {
    this.position.set(
      this.collision.interpolatedPosition.x,
      this.collision.interpolatedPosition.y,
      this.collision.interpolatedPosition.z
    );

    this.quaternion.set(
      this.collision.interpolatedQuaternion.x,
      this.collision.interpolatedQuaternion.y,
      this.collision.interpolatedQuaternion.z,
      this.collision.interpolatedQuaternion.w
    );

    this.seats.forEach((seat: VehicleSeat) => {
      seat.update(timeStep);
    });
  }

  public log(msg: string) {
    if (this.name === "boxer1") {
      console.log(msg);
    }
  }

  public physicsPreStep(body: CANNON.Body): void {
    /*
    for (let i = 0; i < this.rayCastVehicle.wheelInfos.length; i++) {
      if (
        this.rayCastVehicle.wheelInfos[i].engineForce === 0 &&
        this.rayCastVehicle.wheelInfos[i].brake > 0 &&
        this.rayCastVehicle.wheelInfos[i].skidInfo < 1 &&
        this.collision.velocity.length() < 3 &&
        this.rayCastVehicle.numWheelsOnGround >=
          this.rayCastVehicle.wheelInfos.length - 2
      ) {
        this.rayCastVehicle.shouldBeStationary = true;
      } else {
        this.rayCastVehicle.shouldBeStationary = false;
      }
    }
    */
  }

  public physicsPostStep(): void {
    //if skidding and no input, reduce speed. skidinfo 1 = not skidding.
    for (let i = 0; i < this.rayCastVehicle.wheelInfos.length; i++) {
      this.rayCastVehicle.updateWheelTransform(i);
      let transform = this.rayCastVehicle.wheelInfos[i].worldTransform;
      let wheelObject = this.wheels[i].wheelObject;
      wheelObject.position.copy(Utils.threeVector(transform.position));
      wheelObject.quaternion.copy(Utils.threeQuat(transform.quaternion));
    }

    this.updateMatrixWorld();
  }

  public setFullStop(): void {
    this.triggerAction("brake", true);
    this.triggerAction("throttle", false);
    this.triggerAction("reverse", false);
    this.setMoveSpeed(0);
    this.haltTimeout = setTimeout(() => {
      this.rayCastVehicle.shouldBeStationary = true;
    }, 3000);
  }

  public setMobile(): void {
    clearTimeout(this.haltTimeout);
    this.triggerAction("brake", false);
    this.setMoveSpeed(this.defaultMoveSpeed);
    this.rayCastVehicle.shouldBeStationary = false;
  }

  public onInputChange(): void {}

  public resetControls(): void {
    for (const action in this.actions) {
      if (this.actions.hasOwnProperty(action)) {
        this.triggerAction(action, false);
      }
    }
  }

  public allowSleep(value: boolean): void {
    this.collision.allowSleep = value;

    if (value === false) {
      this.collision.wakeUp();
    }
  }

  public handleKeyboardEvent(
    event: KeyboardEvent,
    code: string,
    pressed: boolean
  ): void {}

  public triggerAction(actionName: string, value: boolean): void {
    // Get action and set it's parameters
    let action = this.actions[actionName];

    if (action.isPressed !== value) {
      // Set value
      action.isPressed = value;

      // Reset the 'just' attributes
      action.justPressed = false;
      action.justReleased = false;

      // Set the 'just' attributes
      if (value) action.justPressed = true;
      else action.justReleased = true;

      this.onInputChange();

      // Reset the 'just' attributes
      action.justPressed = false;
      action.justReleased = false;
    }
  }

  public handleMouseButton(
    event: MouseEvent,
    code: string,
    pressed: boolean
  ): void {
    return;
  }

  public handleMouseMove(
    event: MouseEvent,
    deltaX: number,
    deltaY: number
  ): void {}

  public handleMouseWheel(event: WheelEvent, value: number): void {
    this.world!.scrollTheTimeScale(value);
  }

  public inputReceiverInit(): void {
    this.collision.allowSleep = false;
  }

  public inputReceiverUpdate(timeStep: number): void {}

  public setPosition(x: number, y: number, z: number): void {
    this.collision.position.x = x;
    this.collision.position.y = y;
    this.collision.position.z = z;
  }

  public setSteeringValue(val: number): void {
    this.wheels.forEach((wheel) => {
      if (wheel.steering)
        this.rayCastVehicle.setSteeringValue(val, wheel.rayCastWheelInfoIndex!);
    });
  }

  public getSteeringValue(): number {
    let steering = 0;
    this.wheels.forEach((wheel) => {
      if (wheel.steering)
        steering =
          this.rayCastVehicle.wheelInfos[wheel.rayCastWheelInfoIndex!].steering;
    });
    return steering;
  }

  public applyEngineForce(force: number): void {
    this.wheels.forEach((wheel) => {
      if (this.drive === wheel.drive || this.drive === "awd") {
        this.rayCastVehicle.applyEngineForce(
          force,
          wheel.rayCastWheelInfoIndex!
        );
      }
    });
  }

  public setBrake(brakeForce: number, driveFilter?: string): void {
    this.wheels.forEach((wheel) => {
      if (driveFilter === undefined || driveFilter === wheel.drive) {
        this.rayCastVehicle.setBrake(brakeForce, wheel.rayCastWheelInfoIndex!);
      }
    });
  }

  public addToWorld(world: World): void {
    if (world.vehicles.includes(this)) {
      console.warn("Adding character to a world in which it already exists.");
    } else if (this.rayCastVehicle === undefined) {
      console.error("Trying to create vehicle without raycastVehicleComponent");
    } else {
      this.world = world;
      world.vehicles.push(this);
      world.graphicsWorld.add(this);
      // world.physicsWorld.addBody(this.collision);
      this.rayCastVehicle.addToWorld(world);

      this.wheels.forEach((wheel) => {
        //this.world?.physicsWorld.addBody(wheel.collision)
        world.graphicsWorld.attach(wheel.wheelObject);
      });

      /*
      this.materials.forEach((mat) => {
        world.sky.csm.setupMaterial(mat);
      });
      */
      this.world!.physicsWorld.addEventListener("postStep", () => {
        this.physicsPostStep();
      });
      this.world!.physicsWorld.addEventListener("preStep", () => {
        this.physicsPreStep(this.collision);
      });
    }
  }

  public removeFromWorld(world: World): void {
    if (!world.vehicles.includes(this)) {
      console.warn(
        "Removing character from a world in which it isn't present."
      );
    } else {
      this.world = undefined;
      world.vehicles = world.vehicles.filter((v) => v !== this);
      world.graphicsWorld.remove(this);
      // world.physicsWorld.remove(this.collision);
      this.rayCastVehicle.removeFromWorld(world.physicsWorld);

      this.wheels.forEach((wheel) => {
        world.graphicsWorld.remove(wheel.wheelObject);
      });
    }
  }

  public readVehicleData(scene: THREE.Object3D): void {
    if (scene.type === "Mesh") {
      if ((scene as THREE.Mesh).material !== undefined) {
        this.materials.push((scene as THREE.Mesh).material as THREE.Material);
      }
    }
    scene.traverse((child: THREE.Object3D) => {
      if ((child as THREE.Mesh).isMesh) {
        Utils.setupMeshProperties(child, this.world);

        if ((child as THREE.Mesh).material !== undefined) {
          this.materials.push((child as THREE.Mesh).material as THREE.Material);
        }

        if (child.name === "brdmBurnt") {
          child.visible = false;
        }
      }

      if (child.hasOwnProperty("userData")) {
        if (child.userData.hasOwnProperty("data")) {
          // CHANGE forcing seat existence in arbitrary location
          const seat = new THREE.Object3D();
          scene.add(seat);
          seat.position.set(0, 0.5, 0);
          this.seats.push(new VehicleSeat(this, seat, scene));

          if (child.userData.data === "seat") {
            //this.seats.push(new VehicleSeat(this, child, gltf));
          }
          if (child.userData.data === "camera") {
            this.camera = child;
          }
          if (child.userData.data === "wheel") {
            this.wheels.push(new Wheel(child));
          }
          if (child.userData.data === "collision") {
            if (child.userData.shape === "box") {
              child.visible = false;

              /*
              let phys = new CANNON.Box(
                new CANNON.Vec3(child.scale.x, child.scale.y, child.scale.z / 3)
              );
              */

              let phys = new CANNON.Box(
                new CANNON.Vec3(child.scale.x, child.scale.y, child.scale.z / 2)
              );

              //let phys = CannonUtils.CreateConvexPolyhedron(child.geometry);
              phys.collisionFilterMask = ~CollisionGroups.TrimeshColliders;

              this.collision.addShape(
                phys,
                new CANNON.Vec3(
                  child.position.x * 3,
                  child.position.y * 3,
                  child.position.z * 3
                ),
                new CANNON.Quaternion().setFromEuler(1.5708, 0, 0)
              );
            } else if (child.userData.shape === "sphere") {
              child.visible = false;
            }
          }
          if (child.userData.data === "navmesh") {
            child.visible = false;
          }
        }
      }
    });

    if (this.collision.shapes.length === 0) {
      console.warn("Vehicle " + typeof this + " has no collision data.");
    }
    if (this.seats.length === 0) {
      console.warn("Vehicle " + typeof this + " has no seats.");
    } else {
      this.connectSeats();
    }
  }

  private connectSeats(): void {
    for (const firstSeat of this.seats) {
      if (firstSeat.connectedSeatsString !== undefined) {
        // Get list of connected seat names
        let conn_seat_names = firstSeat.connectedSeatsString.split(";");
        for (const conn_seat_name of conn_seat_names) {
          // If name not empty
          if (conn_seat_name.length > 0) {
            // Run through seat list and connect seats to this seat,
            // based on this seat's connected seats list
            for (const secondSeat of this.seats) {
              if (secondSeat.seatPointObject.name === conn_seat_name) {
                firstSeat.connectedSeats.push(secondSeat);
              }
            }
          }
        }
      }
    }
  }

  setScenario(data: any): void {
    this.controllingCharacter!.setBehaviour(
      new VehicleHalt(this.controllingCharacter!)
    );

    if (data.name !== undefined) {
      this.name = data.name;
    }
    this.controllingCharacter!.setScenario(data);
  }

  public setOutline(outline: boolean): void {
    this.traverse((child: THREE.Object3D) => {
      if (child.type === "Mesh") {
        if (outline) this.world!.outlineObjects.add(child);
        else this.world!.outlineObjects.delete(child);
      }
    });
  }

  public setVisible(visible: boolean, duration = 3000): void {
    if (visible) this.visible = visible;
    anime({
      targets: this.materials,
      opacity: visible ? 1 : 0,
      easing: "linear",
      duration: duration,
      complete: () => {
        this.visible = visible;
      },
    }).play();
  }
}
