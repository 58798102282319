import { Sequence } from "./Sequence";
import { ScenarioStage } from "../../enums/ScenarioStage";
import { World } from "../../world/World";
import * as THREE from "three";
export class Scenario5 extends Sequence {
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_5";
    this.units = ["obs1"];
    this.unitType = "AOB";
    this.target = new THREE.Vector3(-50, 100, 55);
  }
}
