import * as THREE from "three";
export class VideoTextureSource {
  public path: string;
  public name: string;
  public tex: THREE.VideoTexture;
  public el!: HTMLVideoElement;
  constructor(path: string) {
    this.path = path;
    this.name = path.split("/").pop()!.split(".").slice(0, -1).join(".");
    this.tex = new THREE.VideoTexture(this.getOrAddVideoElement());
  }

  getOrAddVideoElement(): HTMLVideoElement {
    let video = document.getElementById(this.name) as HTMLVideoElement;
    if (!video) {
      video = document.createElement("video");
      document.body.appendChild(video);
      video.id = this.name;
      video.src = this.path;
      video.muted = true;
      video.setAttribute("playsinline", "");
      video.setAttribute("autoplay", "");
      video.setAttribute("loop", "");
      video.style.display = "none";
    }
    video.pause();
    video.currentTime = 0;
    this.el = video;
    return video;
  }

  play(loop = true) {
    this.el.loop = loop;
    try {
      this.el.play();
    } catch (error) {
      console.error(error);
    }
  }
  stop() {
    this.el.pause();
    this.el.currentTime = 0;
  }
  dispose() {
    // TODO - dispose of video texture, and recreate if missing when play is called again in future
  }
}
