import { ICharacterAI } from "../../interfaces/ICharacterAI";
import { Character } from "../Character";

export class VehicleHalt implements ICharacterAI {
  public character: Character;

  constructor(character: Character) {
    this.character = character;
    this.character.controlledObject!.triggerAction("brake", true);
    this.character.controlledObject!.triggerAction("throttle", false);
    this.character.controlledObject!.triggerAction("reverse", false);
  }
  finish(): void {
    throw new Error("Method not implemented.");
  }

  public jumpToEndState(): void {}

  public setTarget(): void {}

  public update(timeStep: number): void {}

  reachedTarget() {}

  public showDebugBox(show: boolean) {}

  dispose(): void {
    //this.character.controlledObject!.triggerAction("brake", false);
  }
}
