import * as THREE from "three";

import { World } from "./World";
import { WaterShader } from "../../lib/shaders/WaterShader";

import SplatStandardMaterial from "../../lib/shaders/SplatMaterial";

export class Terrain {
  public updateOrder: number = 10;

  private splat1Src = new THREE.TextureLoader().load("./tex/terrain/splat.jpg");

  //private white = new THREE.TextureLoader().load("./tex/terrain/white.jpg");
  //private whites = [this.white, this.white, this.white];

  private splats = [this.splat1Src, this.splat1Src, this.splat1Src];
  private noise = new THREE.TextureLoader().load("./tex/simplex-noise.png");
  private normal = new THREE.TextureLoader().load("./tex/terrain/normal.jpg");
  //private flatNormal = new THREE.TextureLoader().load("./tex/terrain/flat-normal.png");
  private rock = new THREE.TextureLoader().load("./tex/terrain/rock.jpg");

  private rockNormal = new THREE.TextureLoader().load(
    "./tex/terrain/rock-normal.jpg"
  );

  private mud = new THREE.TextureLoader().load("./tex/terrain/mud.jpg");

  private mudNormal = new THREE.TextureLoader().load(
    "./tex/terrain/mud-normal.jpg"
  );

  private grass = new THREE.TextureLoader().load("./tex/terrain/grass.jpg");
  private grassNormal = new THREE.TextureLoader().load(
    "./tex/terrain/grass-normal.jpg"
  );

  //private blankNormal = new THREE.TextureLoader().load("./tex/blank-normal.jpg");

  private diffuseMaps = [this.grass, this.rock, this.mud];
  private normalMaps = [this.grassNormal, this.rockNormal, this.mudNormal];

  constructor(object: THREE.Mesh, world: World) {
    this.splat1Src.flipY = false;
    this.normal.flipY = false;
    this.grass.encoding = THREE.sRGBEncoding;
    this.rock.encoding = THREE.sRGBEncoding;
    this.mud.encoding = THREE.sRGBEncoding;
    this.grass.anisotropy = 8;
    this.rock.anisotropy = 8;
    this.mud.anisotropy = 8;

    this.grass.anisotropy = 8;
    this.rock.anisotropy = 8;
    this.mud.anisotropy = 8;

    const shaderVals = {
      splats: this.splats,
      noise: this.noise,
      //normalMap: this.flatNormal,
      normalMap: this.normal,
      //color: new THREE.Color(0.5, 0.5, 0.5),
      //brightness: [-0.7, -0.7, -0.7],
      normalScale: new THREE.Vector2(2, 2),
      diffuseMaps: this.diffuseMaps,
      normalMaps: this.normalMaps,
      normalMapType: THREE.TangentSpaceNormalMap,
      //normalMapType: THREE.ObjectSpaceNormalMap,
      scale: [16, 16, 16],
      //scale: [1, 1, 1],
      normalWeights: [2, 2, 2],
      envMapIntensity: 0.5,
      envMap: world.envMap,
      metallic: 0,
      roughness: 1,
      //side: THREE.DoubleSide,
    };

    //this.splat1Src.rotation = 90;
    //console.log((object.material as THREE.MeshStandardMaterial).map);
    let material = new SplatStandardMaterial(shaderVals);
    /*let material2 = new THREE.MeshStandardMaterial({
      normalMap: this.normal,
      normalMapType: THREE.TangentSpaceNormalMap,
    });
*/
    //object.geometry.computeVertexNormals();
    //object.geometry.computeTangents();
    object.material = material;
    //console.log((object.material as THREE.MeshStandardMaterial).map);
    object.material.needsUpdate = true;
    //(object.material as THREE.MeshStandardMaterial).map = this.simpleSplat;
  }
}
