import * as THREE from "three";

import { Character } from "../characters/Character";
import { FollowPath } from "../characters/character_ai/FollowPath";
import * as Utils from "../core/FunctionLibrary";
import { LoadingManager } from "../core/LoadingManager";
import { CharacterType } from "../enums/CharacterType";
import { ISpawnPoint } from "../interfaces/ISpawnPoint";
import { FollowTarget } from "./../characters/character_ai/FollowTarget";
import { VehicleSpawnPoint } from "./VehicleSpawnPoint";
import { World } from "./World";

export class CharacterSpawnPoint implements ISpawnPoint {
  public object: THREE.Object3D;
  public entity: Character | undefined;
  public name: string;

  constructor(object: THREE.Object3D, gender = "male") {
    this.object = object;
    this.name = this.object.userData.name;
  }

  public async spawn(
    loadingManager: LoadingManager,
    world: World
  ): Promise<CharacterSpawnPoint> {
    return new Promise<CharacterSpawnPoint>(async (resolve, reject) => {
      let gender = this.object.userData.gender;
      const path =
        gender === "female"
          ? "./export-models/infantryf.glb"
          : "./export-models/infantry.glb";

      await loadingManager.loadGLTFAsync(path).then((gltf) => {
        const type = this.object.userData.type as CharacterType;
        let player = new Character(gltf, world, true, type);
        player.name = this.object.userData.name;

        let worldPos = new THREE.Vector3();
        this.object.getWorldPosition(worldPos);
        player.setPosition(worldPos.x, worldPos.y, worldPos.z);
        let forward = Utils.getForward(this.object);
        player.setOrientation(forward, true);
        world.add(player);
        //player.updateCharacterData(this.object.userData);
        this.entity = player;
        resolve(this);
      });
    });
  }
}
