import { Terrain } from "./Terrain";
import { AudioManager } from "./../core/AudioManager";
import { Timeline } from "./../timeline/Timeline";

import * as CANNON from "cannon-es";
import CannonDebugger from "cannon-es-debugger";
import { EXRLoader } from "three/examples/jsm/loaders/EXRLoader";
import * as THREE from "three";
import {
  BlendFunction,
  BloomEffect,
  CopyMaterial,
  EdgeDetectionMode,
  EffectComposer,
  EffectPass,
  KernelSize,
  NoiseEffect,
  OutlineEffect,
  PredicationMode,
  RenderPass,
  Selection,
  SelectiveBloomEffect,
  ShaderPass,
  SMAAEffect,
  SMAAImageLoader,
  SMAAPreset,
  SSAOEffect,
} from "postprocessing";

import {
  acceleratedRaycast,
  computeBoundsTree,
  disposeBoundsTree,
} from "three-mesh-bvh";
import { Pane } from "tweakpane";

import { Character } from "../characters/Character";
import { CameraOperator } from "../core/CameraOperator";
import * as Utils from "../core/FunctionLibrary";
import { InputManager } from "../core/InputManager";
import { TouchManager } from "../core/TouchManager";
import { UIManager } from "../core/UIManager";
import { CollisionGroups } from "../enums/CollisionGroups";
import { IUpdatable } from "../interfaces/IUpdatable";
import { IWorldEntity } from "../interfaces/IWorldEntity";
import { BoxCollider } from "../physics/colliders/BoxCollider";
import { TrimeshCollider } from "../physics/colliders/TrimeshCollider";
import { Prop } from "../props/Prop";
import { Vehicle } from "../vehicles/Vehicle";
import { LoadingManager } from "./../core/LoadingManager";
import { Formation } from "./Formation";
import { Path } from "./Path";
import { Scenario } from "./Scenario";
import { Trees } from "./Trees";
import { ParticleFxManager } from "../particles/ParticleFXManager";
import { WaterShader } from "../../lib/shaders/WaterShader";
import { Water } from "./Water";
import { PropertyBinding, Vector2 } from "three";
import GroundScatter from "./GroundScatter";
import anime from "animejs";
import Placement from "./Placement";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

// Add the extension functions
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

export class World {
  public renderer: THREE.WebGLRenderer;
  public camera: THREE.PerspectiveCamera;

  public composer: EffectComposer;
  public graphicsWorld: THREE.Group;
  public sceneContainer: THREE.Group;
  public cameraContainer: THREE.Group;
  public scene: THREE.Scene;

  //public sky: Sky;
  public physicsWorld: CANNON.World;
  public physicsTerrainMesh: THREE.Mesh = new THREE.Mesh();
  public graphicsTerrainMesh: THREE.Mesh = new THREE.Mesh();
  public parallelPairs: any[];
  public modelCache: { [key: string]: Promise<any> } = {};

  public physicsFrameRate: number;
  public physicsFrameTime: number;
  public physicsMaxPrediction: number;
  public clock: THREE.Clock;
  public renderDelta: number;
  public logicDelta: number;
  public requestDelta: number = 1;
  public justRendered: boolean;
  public params: any;
  public inputManager: InputManager;
  public touchManager: TouchManager;
  //public cameraOperator: CameraOperator;
  public timeScaleTarget: number = 1;
  public scenarios: Scenario[] = [];
  public characters: Character[] = [];
  public vehicles: Vehicle[] = [];
  public props: Prop[] = [];
  public paths: Path[] = [];
  public formations: Formation[] = [];
  public overlays: IWorldEntity[] = [];
  public scenarioGUIFolder: any;
  public updatables: IUpdatable[] = [];
  public cannonDebugger: any;
  public tweakpane = new Pane();
  public bloomObjects = new Selection([], 40);
  public outlineObjects = new Selection([], 41);
  public placement: Placement;
  //private hemiLight: THREE.HemisphereLight;
  //private ambient: THREE.AmbientLight;
  public sun: THREE.DirectionalLight;
  private lastScenarioID: string = "default";
  public timeline: Timeline;
  public particleManager: ParticleFxManager;
  public envMap?: THREE.Texture;
  public isDesktop: boolean;
  public loadingManager: LoadingManager;
  constructor(
    worldScenePath: string,
    scene: THREE.Scene,
    camera: THREE.PerspectiveCamera,
    renderer: THREE.WebGLRenderer,
    isDesktop: boolean
  ) {
    const scope = this;
    this.isDesktop = isDesktop;
    this.params = {
      Pointer_Lock: false,
      Mouse_Sensitivity: 0.3,
      Time_Scale: 1,
      Shadows: true,
      FXAA: true,
      Debug_Physics: false,
      Debug_FPS: false,
      Debug_Paths: false,
      Sun_Elevation: 50,
      Sun_Rotation: 145,
    };

    //THREE.Cache.enabled = true;

    // Renderer
    this.renderer = renderer; //new THREE.WebGLRenderer();
    //this.renderer.setPixelRatio(window.devicePixelRatio);
    //this.renderer.setSize(window.innerWidth, window.innerHeight);
    // this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    // this.renderer.toneMappingExposure = 1.0;
    //  this.renderer.shadowMap.enabled = true;
    //this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    let pixelRatio = this.renderer.getPixelRatio();
    // Auto window resize
    function onWindowResize(): void {
      if (isDesktop) {
        scope.camera.aspect = window.innerWidth / window.innerHeight;
        scope.camera.updateProjectionMatrix();
        scope.renderer.setSize(window.innerWidth, window.innerHeight);
        scope.composer.setSize(window.innerWidth, window.innerHeight);
      }
    }
    window.addEventListener("resize", onWindowResize, false);

    // Three.js scene

    this.camera = camera;

    this.graphicsWorld = new THREE.Group();
    this.sceneContainer = new THREE.Group();
    this.cameraContainer = new THREE.Group();

    this.graphicsWorld.scale.set(1, 1, 1);
    this.sceneContainer.scale.set(1, 1, 1);
    this.scene = scene;

    this.scene.add(this.sceneContainer);
    this.sceneContainer.add(this.graphicsWorld);

    this.scene.add(this.cameraContainer);
    if (!this.isDesktop) {
      this.cameraContainer.add(this.camera);
      this.cameraContainer.position.set(
        0,
        -window.BATTLE.USER_HEIGHT +
          (window.BATTLE.USER_HEIGHT - window.BATTLE.PLINTH_HEIGHT),
        300
      );
    }

    this.sun = new THREE.DirectionalLight(0xffffdd, 0.0);
    this.sun.position.set(1, 0.7, 1);
    this.graphicsWorld.add(this.sun);

    // ENVMAPS
    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();
    new EXRLoader().load(
      "./tex/syferfontein_18d_clear_1k.exr",
      (texture: THREE.Texture) => {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        let exrCubeRenderTarget = pmremGenerator.fromEquirectangular(texture);
        this.envMap = exrCubeRenderTarget.texture;
        //this.graphicsWorld.background = this.envMap;
        this.scene.environment = this.envMap;

        texture.dispose();
        pmremGenerator.dispose();
      }
    );

    const smaaEffect = new SMAAEffect({
      preset: SMAAPreset.MEDIUM,
      edgeDetectionMode: EdgeDetectionMode.COLOR,
    });

    const bloomOptions = {
      blendFunction: BlendFunction.ADD,
      kernelSize: KernelSize.MEDIUM,
      luminanceThreshold: 0.01,
      luminanceSmoothing: 0.01,
      intensity: 0.5,
      height: 480,
    };

    const bloomEffect = new BloomEffect(bloomOptions);

    const bloom = new SelectiveBloomEffect(
      this.scene,
      this.camera,
      bloomOptions
    );
    bloom.selection = this.bloomObjects;
    const outline = new OutlineEffect(this.scene, this.camera, {
      blendFunction: BlendFunction.SCREEN,
      edgeStrength: 2.5,
      pulseSpeed: 0.4,
      visibleEdgeColor: 0x18ffff,
      hiddenEdgeColor: 0x18ffff,
      height: 480,
      blur: true,
      xRay: true,
    });
    outline.selection = this.outlineObjects;
    outline.selection.exclusive = false;

    const noise = new NoiseEffect({
      blendFunction: BlendFunction.OVERLAY,
    });
    noise.blendMode.opacity = new THREE.Uniform(0.175);

    this.composer = new EffectComposer(this.renderer);
    this.composer.autoRenderToScreen = true;
    this.composer.addPass(new RenderPass(this.scene, this.camera));
    //this.composer.addPass(new EffectPass(this.camera, outline));
    const effectPass = new EffectPass(this.camera, noise, outline);
    this.composer.addPass(effectPass);

    // Physics
    this.physicsWorld = new CANNON.World();
    this.physicsWorld.gravity.set(0, -9.81, 0);
    this.physicsWorld.broadphase = new CANNON.SAPBroadphase(this.physicsWorld);
    //this.physicsWorld.solver.iteratons = 10;
    this.physicsWorld.allowSleep = true;

    this.parallelPairs = [];
    this.physicsFrameRate = 60;
    this.physicsFrameTime = 1 / this.physicsFrameRate;
    this.physicsMaxPrediction = this.physicsFrameRate;

    // RenderLoop
    this.clock = new THREE.Clock();
    this.renderDelta = 0;
    this.logicDelta = 0;
    //this.sinceLastFrame = 0;
    this.justRendered = false;

    this.createParamsGUI(scope);

    //Particles
    this.particleManager = new ParticleFxManager(this);
    this.particleManager.preload();

    this.placement = new Placement(this);

    //cache particles

    /// BREAK HERE FOR XR8 LOAD?

    // Set the initial camera position relative to the scene we just laid out. This must be at a
    // height greater than y=0.
    this.camera.position.set(0, 3, 0);

    // Initialization
    AudioManager.preload();
    this.inputManager = new InputManager(this, this.renderer.domElement);
    this.touchManager = new TouchManager(this);
    if (this.isDesktop) {
      //set background and orbit cam
      const loader = new THREE.TextureLoader();
      loader.load("./tex/bg-gradient.png", function (texture) {
        texture.repeat = new THREE.Vector2(1, 1);
        texture.offset = new THREE.Vector2(0, 0);
        texture.encoding = THREE.sRGBEncoding;
        //texture.magFilter = THREE.NearestFilter;
        texture.minFilter = THREE.LinearFilter;
        scene.background = texture;
      });

      const controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.camera.position.set(-80, 100, 230);
      controls.update();

      const effectPass = new EffectPass(this.camera, smaaEffect);
      this.composer.addPass(effectPass);
    }
    // this.sky = new Sky(this);

    // DEBUG
    if (this.params.Debug_Physics) {
      /* @ts-ignore */
      this.cannonDebugger = new CannonDebugger(
        this.scene,
        this.physicsWorld,
        {}
      );
    }

    if (process.env.NODE_ENV === "development") {
      (document.querySelector(".tp-dfwv") as HTMLElement)!.style.display =
        "block";
    }

    this.loadingManager = new LoadingManager(this);
    this.loadingManager.onFinishedCallback = () => {
      //terrain loaded, spawnpoints will now load
      this.update(1, 1);
      this.setTimeScale(1);
    };
    this.loadingManager.loadGLTF(worldScenePath, (gltf) => {
      this.loadScene(this.loadingManager, gltf);
    });

    this.timeline = new Timeline(this);

    this.render(this);

    document.addEventListener("sceneReady", this.sceneReady.bind(this));
  }

  // Update
  // Handles all logic updates.
  public update(timeStep: number, unscaledTimeStep: number): void {
    this.updatePhysics(timeStep);

    // Update registred objects
    this.updatables.forEach((entity) => {
      entity.update(timeStep, unscaledTimeStep);
    });

    // Lerp time scale
    this.params.Time_Scale = THREE.MathUtils.lerp(
      this.params.Time_Scale,
      this.timeScaleTarget,
      0.2
    );

    // Physics debug
    if (this.params.Debug_Physics && this.cannonDebugger)
      this.cannonDebugger.update();
  }

  public updatePhysics(timeStep: number): void {
    // Step the physics world
    this.physicsWorld.step(this.physicsFrameTime, timeStep);

    this.characters.forEach((char) => {
      if (this.isOutOfBounds(char.characterCapsule.body.position)) {
        //handled by raycast second path now
        // console.log(char.name, "is out of bounds", char.position);
        // this.outOfBoundsRespawn(char.characterCapsule.body);
      }
    });

    this.vehicles.forEach((vehicle) => {
      // TODO - respawn at target waypoint if one exists
      if (this.isOutOfBounds(vehicle.rayCastVehicle.chassisBody.position)) {
        /*
        console.error(vehicle.name, "is out of bounds. respawning");
        let worldPos = new THREE.Vector3();
        vehicle.spawnPoint!.getWorldPosition(worldPos);
        worldPos.y += 3;

        this.outOfBoundsRespawn(
          vehicle.rayCastVehicle.chassisBody,
          Utils.cannonVector(worldPos)
        );
       */
      }
    });
  }

  public isOutOfBounds(position: CANNON.Vec3): boolean {
    let inside =
      position.x > -300 &&
      position.x < 300 &&
      position.z > -300 &&
      position.z < 300 &&
      position.y > 100000;
    let belowSeaLevel = position.y < -50.0;

    return !inside && belowSeaLevel;
  }

  public outOfBoundsRespawn(body: CANNON.Body, position: CANNON.Vec3): void {
    let newPos = position;
    let newQuat = new CANNON.Quaternion(0, 0, 0, 1);

    body.position.copy(newPos);
    body.interpolatedPosition.copy(newPos);
    body.quaternion.copy(newQuat);
    body.interpolatedQuaternion.copy(newQuat);
    body.velocity.setZero();
    body.angularVelocity.setZero();
  }

  /**
   * Rendering loop.
   * Implements fps limiter and frame-skipping
   * Calls world's "update" function before rendering.
   * @param {World} world
   */
  public render(world: World): void {
    this.requestDelta = this.clock.getDelta();

    requestAnimationFrame(() => {
      world.render(world);
    });

    // Getting timeStep
    let unscaledTimeStep =
      this.requestDelta + this.renderDelta + this.logicDelta;
    let timeStep = unscaledTimeStep * this.params.Time_Scale;
    //  timeStep = Math.min(timeStep, 1 / 30); // min 30 fps

    // Logic
    world.update(timeStep, unscaledTimeStep);

    // Measuring logic time
    this.logicDelta = this.clock.getDelta();

    this.composer.render();

    // Measuring render time
    this.renderDelta = this.clock.getDelta();
  }

  public setTimeScale(value: number): void {
    this.params.Time_Scale = value;
    this.timeScaleTarget = value;
  }

  public add(worldEntity: IWorldEntity): void {
    worldEntity.addToWorld(this);
    this.registerUpdatable(worldEntity);
  }

  public registerUpdatable(registree: IUpdatable): void {
    this.updatables.push(registree);
    this.updatables.sort((a, b) => (a.updateOrder > b.updateOrder ? 1 : -1));
  }

  public remove(worldEntity: IWorldEntity): void {
    worldEntity.removeFromWorld(this);
    this.unregisterUpdatable(worldEntity);
  }

  public unregisterUpdatable(registree: IUpdatable): void {
    //_.pull(this.updatables, registree);
    this.updatables = this.updatables.filter(function (value) {
      return value !== registree;
    });
  }

  public async loadScene(
    loadingManager: LoadingManager,
    gltf: any
  ): Promise<void> {
    console.log(gltf.scene.children.length);
    let waterMesh: THREE.Mesh;
    let enemyBuildings: THREE.Mesh;
    gltf.scene.traverse(async (child: THREE.Object3D) => {
      // TODO - use data, make more robustsa
      if (
        child.name === "Terrain_002_hiiRes" ||
        child.name === "Terrain_visual"
      ) {
        this.graphicsTerrainMesh = child as THREE.Mesh;
      }

      if (child.name === "Terrain_river") {
        waterMesh = child as THREE.Mesh;
      }

      if (child.name === "BuildingsEnemy") {
        enemyBuildings = child as THREE.Mesh;
      }

      if (child.hasOwnProperty("userData")) {
        if (child.type === "Mesh") {
          Utils.setupMeshProperties(child as THREE.Mesh, this);
          //this.sky.csm.setupMaterial((child as THREE.Mesh).material);
        }

        if (child.userData.hasOwnProperty("data")) {
          if (child.userData.data === "physics") {
            child.visible = false;
            if (child.userData.hasOwnProperty("type")) {
              if (child.userData.type === "terrain_bvh") {
                // CREATE PHYSICS WORLD
                child.visible = false;

                if (child.type === "Mesh") {
                  child.parent = null;
                  child.position.set(0, 0, 0);
                  child.scale.set(1, 1, 1);
                  child.updateMatrixWorld();
                  child.matrixWorldNeedsUpdate = true;
                  (child as THREE.Mesh).geometry.computeBoundsTree();
                  let phys = new TrimeshCollider(child as THREE.Mesh, {
                    friction: 0.3,
                    restitution: 0,
                    contactEquationStiffness: 1000,
                  });

                  phys.body.collisionFilterMask =
                    ~CollisionGroups.TrimeshColliders;
                  this.physicsWorld.addBody(phys.body);

                  // bvh approach
                  const bvhMesh = child.clone() as THREE.Mesh;
                  bvhMesh.layers.set(99);
                  bvhMesh.geometry.computeBoundsTree();
                  this.physicsTerrainMesh = bvhMesh;
                }
              }
            }
          }

          if (child.userData.data === "path") {
            this.paths.push(new Path(child));
          }

          if (child.userData.data === "formation") {
            this.formations.push(new Formation(child));
          }

          if (child.userData.data === "scenario") {
            this.scenarios.push(new Scenario(child, this));
          }
        }
      }
    });

    // perform scene updates that can't happen in traverse as they mutate scene.

    // TERRAIN
    new Terrain(this.graphicsTerrainMesh, this);

    // ground scatter
    // this.registerUpdatable(new GroundScatter(this, loadingManager));

    // WATER
    this.registerUpdatable(new Water(this, waterMesh! as THREE.Mesh));

    // ENEMY BUILDINGS
    /*
    let prop = new Prop(enemyBuildings!, this);
    prop.children[0].scale.set(1, 1, 1);
    prop.name = "enemyBase";
    this.props.push(prop);
    prop.materials.forEach((mat) => {
      mat.transparent = true;
      mat.opacity = 1;
    });
    prop.setVisible(false, 1);
    */
    enemyBuildings!.visible = false;

    this.graphicsWorld.add(gltf.scene);
    const trees = new Trees(gltf.scene, this);

    // Launch default scenario
    let defaultScenarioID: string = "default";
    for (const scenario of this.scenarios) {
      if (scenario.default) {
        defaultScenarioID = scenario.id as string;
        //scenario.spawn(loadingManager, this);
        break;
      }
    }

    for (const scenario of this.scenarios) {
    }
    if (defaultScenarioID !== undefined)
      this.launchScenario(defaultScenarioID, loadingManager);

    // DEBUG PENNANT
    /*  const pennant = new Pennant(
      this.characters[0],
      this,
      "./tex/icon-recon.png"

    );
        pennant.position.set(0, 50, 0);
    console.log(pennant);
    */
  }

  public launchScenario(
    scenarioID: string,
    loadingManager?: LoadingManager
  ): void {
    this.lastScenarioID = scenarioID;

    this.clearEntities();

    // Launch default scenario
    this.loadingManager.onFinishedCallback = () => {
      // START EXPERIENCE
      setTimeout(() => {
        //this.initTerrain();
        console.log("loading finished");
        this.sceneLoaded();
      }, 100); // wait for remaining setup of chars
    };

    for (const scenario of this.scenarios) {
      if (scenario.id === scenarioID || scenario.spawnAlways) {
        scenario.spawn(this.loadingManager, this);
      }
    }
  }

  public sceneLoaded() {
    if (!this.isDesktop) {
      this.placement.start();
    }

    document.dispatchEvent(new CustomEvent("sceneLoaded", {}));
  }

  public sceneReady() {
    console.log("SCENE READY WORLD");
    if (!this.isDesktop) this.placement.stop();
    this.timeline.start();
    AudioManager.playSFX("WAA021_01_OUT_OF_BREACH").volume(0.3);
  }

  public changeScenario(scenarioID: string): void {
    this.lastScenarioID = scenarioID;

    for (const scenario of this.scenarios) {
      if (scenario.id === scenarioID || scenario.spawnAlways) {
        scenario.change(this);
      }
    }
  }

  public restartScenario(): void {
    if (this.lastScenarioID !== undefined) {
      document.exitPointerLock();
      this.launchScenario(this.lastScenarioID);
    } else {
      console.warn("Can't restart scenario. Last scenarioID is undefined.");
    }
  }

  public clearEntities(): void {
    for (let i = 0; i < this.characters.length; i++) {
      this.remove(this.characters[i]);
      i--;
    }

    for (let i = 0; i < this.vehicles.length; i++) {
      this.remove(this.vehicles[i]);
      i--;
    }
  }

  public scrollTheTimeScale(scrollAmount: number): void {
    // Changing time scale with scroll wheel
    const timeScaleBottomLimit = 0.003;
    const timeScaleChangeSpeed = 1.3;

    if (scrollAmount > 0) {
      this.timeScaleTarget /= timeScaleChangeSpeed;
      if (this.timeScaleTarget < timeScaleBottomLimit) this.timeScaleTarget = 0;
    } else {
      this.timeScaleTarget *= timeScaleChangeSpeed;
      if (this.timeScaleTarget < timeScaleBottomLimit)
        this.timeScaleTarget = timeScaleBottomLimit;
      this.timeScaleTarget = Math.min(this.timeScaleTarget, 20);
    }
    this.tweakpane.refresh();
  }

  private createParamsGUI(scope: World): void {
    // Scenario

    this.scenarioGUIFolder = this.tweakpane.addFolder({
      title: "Scenarios",
      expanded: false,
    });

    for (let i = 1; i < 15; i++) {
      const btn = this.scenarioGUIFolder.addButton({
        title: "Scenario_" + i,
      });
      btn.on("click", () => {
        //this.world.changeScenario(this.id as string);
        this.timeline.jumpToSequence(i - 1);
      });
    }

    // World
    const worldFolder = this.tweakpane.addFolder({
      title: "World",
      expanded: false,
    });

    worldFolder
      .addInput(this.params, "Time_Scale", {
        min: 0,
        max: 30,
      })
      .on("change", (e) => {
        scope.timeScaleTarget = e.value;
      });

    const btn = worldFolder.addButton({
      title: "Reset Timescale",
    });

    btn.on("click", () => {
      this.setTimeScale(1);
      this.tweakpane.refresh();
    });

    // Input

    worldFolder.addInput(this.params, "FXAA");

    worldFolder.addInput(this.params, "Debug_Paths").on("change", (e) => {
      this.characters.forEach((char) => {
        if (char.behaviour) char.behaviour.showDebugBox(e.value);
      });
    });

    worldFolder.addInput(this.params, "Debug_Physics").on("change", (e) => {
      if (!e.value) {
        this.cannonDebugger = undefined;
      } else {
        /* @ts-ignore */
        this.cannonDebugger = new CannonDebugger(
          this.scene,
          this.physicsWorld,
          {}
        );
      }
    });

    worldFolder.addInput(window.BATTLE, "bufferLength", {
      label: "Smoothing",
      options: {
        off: 1,
        medium: 2,
        high: 3,
      },
    });
  }

  public getTerrainRaycast(x: number, z: number): THREE.Intersection {
    const raycaster = new THREE.Raycaster();
    raycaster.firstHitOnly = true;
    raycaster.layers.set(99);
    const origin = new THREE.Vector3(x, 300, z);
    const direction = new THREE.Vector3(0, -1, 0);
    raycaster.set(origin, direction);

    const intersects = raycaster.intersectObject(this.physicsTerrainMesh);
    if (intersects.length === 0) {
      console.error("Invalid terrain raycast - likely outside bounds", x, z);
      intersects.push({
        point: new THREE.Vector3(x, 0, z),
        face: { normal: new THREE.Vector3(0, 1, 0) },
      } as any);
    }
    return intersects[0];
  }
  public getCharacterByName(name: string): Character {
    var char = this.characters.find((char) => {
      return char.name === name;
    });
    if (char === undefined) throw new Error("Invalid Character name " + name);
    return char;
  }

  public getVehicleByName(name: string): Vehicle {
    const vehicle = this.vehicles.find((vehicle) => {
      return vehicle.name === name;
    });
    if (vehicle === undefined) throw new Error("Invalid Vehicle name " + name);
    return vehicle;
  }

  public getPropByName(name: string): Prop {
    const prop = this.props.find((prop) => {
      return prop.name === name;
    });
    if (prop === undefined) throw new Error("Invalid Prop name " + name);
    return prop;
  }

  public getEntityByName(name: string): Vehicle | Character | Prop {
    let result: Vehicle | Character | Prop | undefined = this.vehicles.find(
      (vehicle) => {
        return vehicle.name === name;
      }
    );
    if (!result) {
      result = this.characters.find((char) => {
        return char.name === name;
      });
    }
    if (!result) {
      result = this.props.find((prop) => {
        return prop.name === name;
      });
    }
    if (result === undefined) throw new Error("Invalid Entity name " + name);
    return result;
  }

  public getFormationByName(name: string): Formation {
    const result = this.formations.find((formation) => {
      return formation.name === name;
    });
    if (!result) {
      throw new Error(`Formation  ${name} not found`);
    }
    return result;
  }
}
