import { useRef, useState } from "react";

import { Box, Heading, HStack, Image } from "@chakra-ui/react";
import { Role } from "./Roles";
//import badgeSrc from "../../../public/tex/ADO-badge.png";
import closeSrc from "../../assets/close.svg";
import { useNavigate } from "react-router-dom";
const RolesHeader = ({
  iconL,
  iconR,
  role,
}: {
  iconL?: string;
  iconR?: string;
  role: Role;
}) => {
  const navigate = useNavigate();
  return (
    <HStack
      padding="25px"
      paddingTop="50px"
      color="rgba(255, 255, 255, 1)"
      fontSize="18px"
      lineHeight="22px"
      justifyContent="space-between"
      height="64px"
      width="100%"
    >
      <Image
        height="auto"
        width="32px"
        src={`${process.env.PUBLIC_URL}/tex/${role.code}-badge.png`}
      />

      <Box textTransform="uppercase">
        {" "}
        <Heading fontSize="14px" isTruncated>
          ARMY COMBAT ROLES
        </Heading>
        <Heading
          variant="subheading"
          color="brand.yellow"
          marginTop="9px"
          fontSize="10px"
          isTruncated
        >
          {role.name.toLocaleUpperCase()}
        </Heading>
      </Box>

      <Image src={closeSrc} onClick={() => navigate(-1)} />
    </HStack>
  );
};

export default RolesHeader;
