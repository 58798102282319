import { useRef, useState } from "react";

import { Box } from "@chakra-ui/react";
import { DragState, PinchState, useDrag, useGesture } from "@use-gesture/react";
import { css } from "@emotion/react";
import { setConstantValue } from "typescript";

const InputPane = ({
  children,
}: //setScale,
{
  children?: any;
  //setScale: (value: number) => void;
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isPinching, setIsPinching] = useState(false);
  const scaleOffsetMax = 2;
  const scaleOffsetMin = 0.2;

  const bind = useGesture(
    {
      onPinch: (state: PinchState) => onPinch(state),
      onDrag: (state: DragState) => onDrag(state),
    },
    {
      drag: {
        delay: 200,
        filterTaps: true,
        threshold: [5, 5],
        eventOptions: {
          capture: false,
          passive: true,
        },
      },
      pinch: {
        eventOptions: {
          capture: false,
          passive: true,
        },
        scaleBounds: { min: scaleOffsetMin, max: scaleOffsetMax },
      },
    }
  );

  const onPinch = ({
    offset,
    delta,
    active,
    timeStamp,
    canceled,
  }: PinchState) => {
    if (!active || canceled) {
      setIsPinching(false);
      return;
    }
    if (delta[0] === 0) return;
    if (active) {
      setScale(scaleOffsetMax - scaleOffsetMin - offset[0]);
    }
    setIsPinching(active);
    return timeStamp;
  };

  const setScale = (val: number) => {
    document.dispatchEvent(new CustomEvent("setScale", { detail: val }));
  };

  const setDrag = (x: number, y: number, isDragging: boolean) => {
    document.dispatchEvent(
      new CustomEvent("setDrag", { detail: { x, y, isDragging } })
    );
  };

  const onDrag = ({ active, timeStamp, xy, event, touches, dragging }: any) => {
    if (touches > 1) return;
    if (isPinching) return;

    setDrag(xy[0], xy[1], active);

    setIsDragging(active);

    return timeStamp;
  };

  return (
    <Box
      {...bind()}
      zIndex="0"
      position="absolute"
      top="0"
      bottom="0"
      right="0"
      left="0"
      width="100%"
      height="100%"
      css={css`
        touch-action: none;
      `}
    ></Box>
  );
};

export default InputPane;
