import { IUpdatable } from "./../interfaces/IUpdatable";
import { World } from "./World";
import * as THREE from "three";
import {
  acceleratedRaycast,
  computeBoundsTree,
  disposeBoundsTree,
} from "three-mesh-bvh";
import anime from "animejs";
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

export default class Placement implements IUpdatable {
  private floor: THREE.Mesh;
  private world: World;
  private raycaster: THREE.Raycaster;
  updateOrder: number = 1;
  screenPos = new THREE.Vector2(0, 0);
  plinth: THREE.Object3D;
  scale: number = 1;
  rotParent: THREE.Object3D;
  scaleParent: THREE.Group;
  isDragging: boolean = false;
  constructor(world: World) {
    this.world = world;
    //new THREE.ShadowMaterial({opacity: 0.5,})
    this.floor = new THREE.Mesh(
      new THREE.PlaneGeometry(100000, 100000, 1, 1),
      new THREE.ShadowMaterial({ opacity: 0.5 })
    );
    this.floor.name = "CAMERAFLOOR";
    this.floor.rotateX(-Math.PI / 2);
    this.floor.receiveShadow = true;
    this.floor.layers.enable(88);
    //this.world.scene.add(this.floor);

    this.world.cameraContainer.add(this.floor);
    this.floor.position.y = -window.BATTLE.USER_HEIGHT;
    this.raycaster = new THREE.Raycaster();
    let plinthGeo = new THREE.BoxGeometry(
      290,
      window.BATTLE.PLINTH_HEIGHT - 10,
      290
    );
    const material = new THREE.MeshStandardMaterial({
      color: 0x000000,
      transparent: true,
      roughness: 0.8,
      metalness: 0.3,
      envMap: this.world.envMap,
    });
    this.plinth = new THREE.Mesh(plinthGeo, material);
    this.world.graphicsWorld.add(this.plinth);
    this.plinth.position.y = -window.BATTLE.PLINTH_HEIGHT / 2 - 10;
    this.plinth.visible = false;
    this.rotParent = new THREE.Object3D();
    this.scaleParent = new THREE.Group();

    this.world.scene.add(this.scaleParent);
    this.scaleParent.add(this.world.cameraContainer);
    this.setScale = this.setScale.bind(this); // allow for removing event listener
    this.setDrag = this.setDrag.bind(this); // allow for removing event listener
  }

  /* Use camera parent to move camera. raycast from where camera 'thinks' it is to avoid brain-strain?*/
  update(timestep: number, unscaledTimeStep: number): void {
    if (this.isDragging) this.moveToScreenPos();
  }

  start() {
    this.world.registerUpdatable(this);

    //document.addEventListener("setScale", this.setScale);
    document.addEventListener("setDrag", this.setDrag);
    //need to see far enough for user to move around

    /*
    this.setDrag({
      detail: { x: window.innerWidth / 2, y: window.innerHeight / 2 },
    });
    */
  }
  stop() {
    this.world.unregisterUpdatable(this);
    this.plinth.visible = false;
    // document.removeEventListener("setScale", this.setScale);
    document.removeEventListener("setDrag", this.setDrag);
  }

  setScale(e: any) {
    this.scale = e.detail;
    //use 0,0,0 as scale pivot, so we dont move the plinthw while scaling
    this.scaleParent.scale.set(this.scale, this.scale, this.scale);
  }

  moveToScreenPos() {
    this.raycaster.setFromCamera(this.screenPos, this.world.camera);
    this.raycaster.firstHitOnly = true;
    this.raycaster.layers.set(88);
    const intersects = this.raycaster.intersectObject(this.floor);
    if (
      intersects.length === 1 &&
      intersects[0].object.name === "CAMERAFLOOR"
    ) {
      // set position
      this.world.cameraContainer.position.x =
        -this.world.camera.position.x - intersects[0].point.x / 2;
      this.world.cameraContainer.position.z =
        -this.world.camera.position.z - intersects[0].point.z / 2;
      let camPos = new THREE.Vector3();
      this.world.camera.getWorldPosition(camPos);
      this.world.sceneContainer.lookAt(camPos.x, 0, camPos.z);
    }
  }

  setDrag(e: any) {
    this.isDragging = e.detail.isDragging;

    this.screenPos.x = (e.detail.x / window.innerWidth) * 2 - 1;
    this.screenPos.y = ((e.detail.y / window.innerHeight) * 2 - 1) * -1;

    //this.moveToScreenPos();
  }
}
