import { ParticleEmitter } from "three.quarks";
import { ParticleSystem } from "three.quarks";
import { VolumetricFireball } from "../../particles/VolumetricFireball";
import * as CANNON from "cannon-es";
import { AudioManager } from "../../core/AudioManager";
import { Sequence } from "./Sequence";
import { ScenarioStage } from "../../enums/ScenarioStage";
import { World } from "../../world/World";
import * as THREE from "three";
import { Car } from "../../vehicles/Car";

export class Scenario14 extends Sequence {
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_14";
    this.promptUnit = true;
    this.units = ["boxer1"];
    this.unitType = "ARV";
  }

  override introStage(): void {
    super.introStage();
    const boxer1 = this.world.getVehicleByName("boxer1");
    const takingFire = this.world.particleManager.get("takingFire");
    boxer1.add(takingFire.object!);
    takingFire.object!.name = "takingFire";
    // takingFire.restart();

    const boxer2 = this.world.getVehicleByName("boxer2");
    const takingFire2 = this.world.particleManager.get("takingFire");
    boxer2.add(takingFire2.object!);
    takingFire2.object!.name = "takingFire";

    //const pos = new THREE.Vector3(-110, 23, -135);
    //takingFire.object?.position.copy(pos!);
  }

  override movementStage(): void {
    this.hidePrompts();
    this.hideTargetPennants();
    this.hideUnitPennants();
    AudioManager.playSFX("sfx_gunfire_large");
    this.boxerFire("boxer1");

    setTimeout(() => {
      AudioManager.playSFX("sfx_gunfire_large");
      this.boxerFire("boxer2");
      setTimeout(() => {
        this.brdmExplode("BRDM1");
        AudioManager.playSFX("sfx_explosion_small");
      }, 200);
    }, 2000);

    setTimeout(() => {
      AudioManager.playSFX("sfx_gunfire_large");
      this.boxerFire("boxer3");
      setTimeout(() => {
        this.brdmExplode("BRDM3");
        AudioManager.playSFX("sfx_explosion_small");
      }, 230);
    }, 3000);

    setTimeout(() => {
      AudioManager.playSFX("sfx_gunfire_large");
      this.boxerFire("boxer2");
      this.boxerFire("boxer1");
      setTimeout(() => {
        this.brdmExplode("BRDM2");
        AudioManager.playSFX("sfx_explosion_small");
        AudioManager.stopSFX("sfx_gunfire_small");
      }, 280);
    }, 5000);

    setTimeout(() => {
      this.changeState(ScenarioStage.Ended);
    }, 9000);
  }

  boxerFire(name: string) {
    let vehicle = this.world.getVehicleByName(name);
    const muzzle = this.world.particleManager.get("muzzleFlashVehicle");
    vehicle.add(muzzle.object!);
    muzzle.object!.rotateY(-Math.PI / 2);
    muzzle.object!.position.set(0, 7.5, 7);
    muzzle.restart();
    muzzle.loop(false);

    vehicle.children.forEach((child: THREE.Object3D) => {
      console.log(child.name);
      if (child.name === "takingFire" && child.children[0]) {
        (
          (child.children[0] as ParticleEmitter).system as ParticleSystem
        ).dispose();
      }
    });
  }

  brdmExplode(name: string) {
    let vehicle = this.world.getVehicleByName(name);
    const explosion = this.world.particleManager.get("vehicleExplosion");
    const fireParticle = this.world.particleManager.get("fire");
    vehicle.add(explosion.object!);
    vehicle.add(fireParticle.object!);
    explosion.object!.position.set(0, 2, 0);
    fireParticle.object!.position.set(0, 0, 0);
    explosion.restart();
    fireParticle.restart();

    const fire = new VolumetricFireball(this.world);

    fire.position.set(
      vehicle.position.x,
      vehicle.position.y + 6,
      vehicle.position.z
    );
    fire.scale.set(1.4, 1.4, 1.4);

    fire.rotateY(Math.random() * Math.PI * 2);
    setTimeout(() => {
      fire.dispose();
    }, 12000);
    //throw vehicle then return to stationary
    vehicle.rayCastVehicle.shouldBeStationary = false;
    //TODO random directions
    setTimeout(() => {
      vehicle.collision.applyImpulse(
        new CANNON.Vec3(
          5000 + Math.random() * 5000,
          10000 + Math.random() * 5000,
          +Math.random() * 5000
        ),
        new CANNON.Vec3(1, 0, 2)
      );
      vehicle.collision.applyForce(
        new CANNON.Vec3(
          5000 + Math.random() * 5000,
          10000 + Math.random() * 5000,
          100 + Math.random() * 5000
        ),
        new CANNON.Vec3(1, 0, 2)
      );
    }, 100);
    setTimeout(() => {
      vehicle.rayCastVehicle.shouldBeStationary = true;
    }, 5000);
    vehicle.traverse((child: any) => {
      if (child.isMesh === true) {
        child.material.transparent = false;
        if (child.name === "brdmBurnt") {
          child.visible = true;

          console.log("BRDM BURNT");
        } else {
          child.visible = false;
        }
      }
    });

    vehicle.setVisible(true);
  }

  override endedStage(): void {
    AudioManager.stopSFX("sfx_gunfire_large");
    AudioManager.stopSFX("sfx_gunfire_small");
    AudioManager.stopSFX("sfx_explosion_small");
    setTimeout(() => {
      super.endedStage();
    }, 4000);
  }
}
