import { World } from "../world/World";
import * as THREE from "three";
export class TouchManager {
  public world: World;
  constructor(world: World) {
    this.world = world;
    window.BATTLE.tap = this.tap.bind(this);

    const el = document.body;
    el.addEventListener("touchstart", this.handleEnd.bind(this));
    el.addEventListener("touchend", this.handleEnd);
    el.addEventListener("mouseup", this.mouseUp.bind(this));
  }

  handleEnd(e: TouchEvent) {
    //console.log("touchend", e);
    //this.tap(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
  }

  mouseUp(e: MouseEvent) {
    this.tap(e.clientX, e.clientY);
  }

  tap(x: number, y: number) {
    const pointer = new THREE.Vector2();

    pointer.x = (x / window.innerWidth) * 2 - 1;
    pointer.y = -(y / window.innerHeight) * 2 + 1;
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(pointer, this.world.camera);

    const results = raycaster.intersectObjects(
      this.world.graphicsWorld.children,
      true
    );
    if (results.length > 0) {
      //console.log("tap", x, y, results);
      results.forEach((result) => {
        document.dispatchEvent(
          new CustomEvent("tap3D", { detail: { uuid: result.object.uuid } })
        );
      });
    }
  }
}
