import { IWorldEntity } from "./../interfaces/IWorldEntity";
import * as THREE from "three";

import { LoadingManager } from "../core/LoadingManager";
import { ISpawnPoint } from "../interfaces/ISpawnPoint";
import { World } from "../world/World";
import { Character } from "./../characters/Character";
import { CharacterSpawnPoint } from "./CharacterSpawnPoint";
import { VehicleSpawnPoint } from "./VehicleSpawnPoint";
import { Vehicle } from "../vehicles/Vehicle";
import { Car } from "../vehicles/Car";
import { PropSpawnPoint } from "./PropSpawnPoint";

export class Scenario {
  public id: string | undefined;
  public name: string = "";
  public spawnAlways: boolean = false;
  public default: boolean = false;
  public world: World;

  private rootNode: THREE.Object3D;
  public spawnPoints: ISpawnPoint[] = [];
  private invisible: boolean = false;

  private activeEntities: IWorldEntity[] = [];

  private initialCameraAngle: number = 0;

  constructor(root: THREE.Object3D, world: World) {
    this.rootNode = root;
    this.world = world;
    this.id = root.name;

    // Scenario
    if (root.userData.hasOwnProperty("name")) {
      this.name = root.name;
    }
    if (
      root.userData.hasOwnProperty("default") &&
      root.userData.default === "true"
    ) {
      this.default = true;
    }
    if (root.userData.hasOwnProperty("camera_angle")) {
      this.initialCameraAngle = root.userData.camera_angle;
    }

    //if (!this.invisible) this.createChangeLink();

    // Find all scenario spawns and enitites
    root.traverse((child) => {
      if (
        child.hasOwnProperty("userData") &&
        child.userData.hasOwnProperty("data")
      ) {
        if (child.userData.data === "vehicle") {
          //is vehicle
          let sp = new VehicleSpawnPoint(child);
          this.spawnPoints.push(sp);
        } else if (child.userData.data === "person") {
          let sp = new CharacterSpawnPoint(child);
          this.spawnPoints.push(sp);
        } else if (child.userData.data === "prop") {
          let sp = new PropSpawnPoint(child);
          this.spawnPoints.push(sp);
        }
      }
    });
  }

  public spawn(loadingManager: LoadingManager, world: World): void {
    const promises: Promise<
      CharacterSpawnPoint | VehicleSpawnPoint | PropSpawnPoint
    >[] = [];
    this.spawnPoints.forEach((sp) => {
      if (!sp.entity) {
        promises.push(sp.spawn(loadingManager, world));
      }
    });

    Promise.all(promises).then((spawnpoints) => {
      spawnpoints.forEach((sp) => {
        sp.entity!.setScenario(sp.object.userData);
      });
    });
  }

  public change(world: World): void {
    console.log("Change scenario:", this.name);
    this.activeEntities = [];
    console.log(this.spawnPoints);
    this.spawnPoints.forEach((sp) => {
      const entity = this.world.getEntityByName(sp.name);
      if (!entity)
        throw new Error(
          "Unit not found in world. is it specified in the default/spawn scenario? : " +
            sp.name
        );
      this.activeEntities.push(entity);
      entity!.setScenario(sp.object.userData);

      if (entity instanceof Character && (entity as Character).pennant) {
        (entity as Character).pennant?.show();
      }

      if (
        entity instanceof Car &&
        (entity as Car).controllingCharacter!.pennant
      ) {
        (entity as Car).controllingCharacter!.pennant?.show();
      }
    });
    this.started();
  }

  private ended() {
    const event = new Event("scenarioEnded", { detail: this.id } as any);
    document.dispatchEvent(event);
  }

  private started() {
    const event = new Event("scenarioStart", { detail: this.id } as any);
    document.dispatchEvent(event);
  }
}
