import { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { useMediaQuery } from "@chakra-ui/react";
import {
  Box,
  Heading,
  Image,
  Text,
  VStack,
  Spinner,
  Flex,
} from "@chakra-ui/react";

import armyChallengeSrc from "../assets/logo-challenge-army.png";
const LoadingScreen = ({ children }: { children?: React.ReactNode }) => {
  const [progress, setProgress] = useState(0);
  const [isLandscape] = useMediaQuery("(orientation: landscape)");

  useEffect(() => {
    const loadProgress = (event: any) => {
      const { progress } = event.detail;
      setProgress(progress);
      console.log(progress);
    };
    document.addEventListener("loadProgress", loadProgress);
    return () => {
      document.removeEventListener("loadProgress", loadProgress);
    };
  }, [progress]);
  return (
    <VStack
      overflow="hidden"
      css={css`
        max-height: calc(100vh);
        max-height: calc(100dvh);
        height: calc(100vh);
        height: calc(100dvh);
      `}
      paddingTop="50px"
      paddingBottom="50px"
      justifyContent="space-between"
      margin="auto"
      background="linear-gradient(180deg, #89AEAF -38.51%, #1C1C1C 80.51%) !important;"
    >
      <VStack width="100%">
        <Heading variant="large" isTruncated>
          LOADING...
        </Heading>
      </VStack>
      <Flex
        position="absolute"
        alignItems="center"
        justifyContent="center"
        left="0"
        right="0"
        top="0"
        bottom="0"
        css={css`
          max-height: calc(100vh);
          max-height: calc(100dvh);
          height: calc(100vh);
          height: calc(100dvh);
        `}
      >
        <Box>
          <Spinner
            height={isLandscape ? "33vh" : "50vw"}
            width={isLandscape ? "33vh" : "50vw"}
            maxWidth="200px"
            maxHeight="200px"
            thickness="8px"
            speed="0.65s"
            emptyColor="#1c1c1c"
            color="brand.yellow"
            size="xl"
          />
        </Box>
      </Flex>
      <VStack spacing="25px" maxWidth="700px">
        <Image width="129px" src={armyChallengeSrc} />
      </VStack>
      <Flex
        position="absolute"
        alignItems="center"
        justifyContent="center"
        left="0"
        right="0"
        top="0"
        bottom="0"
        css={css`
          max-height: calc(100vh);
          max-height: calc(100dvh);
          height: calc(100vh);
          height: calc(100dvh);
        `}
      >
        <Text variant="clock" fontSize="26px" display="block">
          {Math.round(progress)}%
        </Text>
      </Flex>
    </VStack>
  );
};

export default LoadingScreen;
