import { AudioManager } from "./../../core/AudioManager";
import { Sequence } from "./Sequence";
import { ScenarioStage } from "../../enums/ScenarioStage";
import { World } from "../../world/World";
import * as THREE from "three";
import { Vehicle } from "../../vehicles/Vehicle";
export class Scenario10 extends Sequence {
  constructor(world: World) {
    super(world);
    this.scenario = "Scenario_10";
    this.units = ["AOP1"];
    this.unitType = "AOP";
    this.promptUnit = true;
  }

  override pennantTapped(e: any) {
    super.pennantTapped(e);
    const brdm2 = this.world.getVehicleByName("BRDM2");
    const brdm3 = this.world.getVehicleByName("BRDM3");
    setTimeout(() => {
      AudioManager.playSFX("sfx_artilleryFire");
      this.fireArtillery("artillery1");
    }, 1500);
    setTimeout(() => {
      AudioManager.playSFX("sfx_artilleryFire");
      this.fireArtillery("artillery1");
    }, 3500);
    setTimeout(() => {
      AudioManager.playSFX("sfx_explosion_large");
      const explosion = this.world.particleManager.get("explosion");
      const smoke = this.world.particleManager.get("smoke");
      explosion.stop();
      brdm2.add(smoke.object!);
      brdm2.add(explosion.object!);
      //explosion.object!.position.copy(brdm2.position);
      //smoke.object!.position.copy(brdm2.position);

      explosion.restart();

      // smoke.restart();
    }, 5500);
    setTimeout(() => {
      AudioManager.playSFX("sfx_explosion_large");
      const explosion = this.world.particleManager.get("explosion");
      explosion.stop();
      brdm3.add(explosion.object!);
      explosion.restart();
    }, 6600);
    setTimeout(() => {
      this.changeState(ScenarioStage.Ended);
    }, 8000);
  }

  fireArtillery(name: string) {
    const flash = this.world.particleManager.get("muzzleFlash");
    const arty1 = this.world.getPropByName(name);
    arty1.add(flash.object!);
    flash.object?.rotateY(Math.PI / 2);
    flash.object?.rotateZ(Math.PI / 4);
    flash.object!.position.set(0, 12.5, -11.5);
    flash.restart();

    const dust = this.world.particleManager.get("artilleryDust");
    arty1.add(dust.object!);
    dust.restart();
  }
}
