import { RadioOrder, RadioComms } from "./../../../useStore";
import { FollowPath } from "./../../characters/character_ai/FollowPath";
import { World } from "../../world/World";
import { ScenarioStage } from "../../enums/ScenarioStage";
import { Pennant } from "../../overlays/Pennant";
import { AudioManager } from "../../core/AudioManager";
import radioJson from "../../../data/radio.json";
import ReactGA from "react-ga4";
export interface RadioData {
  [scenario: string]: {
    [stage: string]: RadioComms[] | RadioOrder[];
  };
}
const radioData: RadioData = radioJson as any;

export class Sequence {
  world: World;
  state: ScenarioStage = ScenarioStage.None;
  scenario!: string;
  target?: THREE.Vector3;
  units: string[] = [];
  unitType!: string;
  active = false;
  promptUnit = false;
  promptTarget = true;
  targetPennants: Pennant[] = [];
  waitTimeout?: number;
  //orderVO?: string;
  //commsVO?: string;
  radio: RadioComms[] | RadioOrder[] = [];
  radioIndex = 0;

  public endedCallback?: () => void;

  constructor(world: World) {
    this.world = world;
    // replace with bound versions so removeEventListener will work.
    this.pennantTapped = this.pennantTapped.bind(this);
    this.pathFinished = this.pathFinished.bind(this);
    this.scenarioCompleted = this.scenarioCompleted.bind(this);
    this.voiceoverEnded = this.voiceoverEnded.bind(this);
  }

  changeState(newState: ScenarioStage) {
    this.radioIndex = 0;
    this.updateRadioData(newState);

    switch (newState) {
      case ScenarioStage.Intro:
        console.log(" Intro", this.constructor.name);
        this.introStage();
        try {
          ReactGA.event({
            category: "App Events",
            action: this.scenario,
          });
        } catch (error) {}
        break;
      case ScenarioStage.InputWait:
        console.log(" InputWait", this.constructor.name);
        this.inputWaitStage();
        break;
      case ScenarioStage.Movement:
        console.log(" Movement", this.constructor.name);
        document.addEventListener("pathFinished", this.pathFinished);
        document.addEventListener("scenarioCompleted", this.scenarioCompleted, {
          once: true,
        });
        this.movementStage();
        break;
      case ScenarioStage.Ended:
        console.log(" Ended ", this.constructor.name);
        this.endedStage();
        break;
    }
    if (!this.active) return; // we stopped during state changes
    this.state = newState;
    document.dispatchEvent(
      new CustomEvent("scenarioStateChanged", { detail: this })
    );
  }

  introStage() {
    if (this.radio[this.radioIndex]) {
      this.playRadio(this.radio[this.radioIndex]);
    } else {
      //need to change state manually as no callback
      this.waitTimeout = window.setTimeout(
        () => this.changeState(ScenarioStage.InputWait),
        4000
      );
    }
  }

  createTargetPennant(position: THREE.Vector3): Pennant {
    console.log("Create target pennant", position);
    const pennant = new Pennant(this.world, "./tex/pennant-target.png");
    pennant.setPosition(position);
    pennant.show();
    pennant.name = this.units[0] + "Pennant";
    pennant.showPrompt();
    return pennant;
  }

  createTargetPennantFromUnit(unit: string) {
    const behaviour = this.world.getCharacterByName(unit)?.behaviour;
    console.log(behaviour);
    if (behaviour && behaviour instanceof FollowPath) {
      console.log("Create target pennant", behaviour);
      this.createTargetPennant(
        (behaviour as FollowPath).targetNode.path.finalNode.object.position
      );
    }
  }

  voiceoverEnded(e: any) {
    if (!this.active) return;
    this.radioIndex++;
    console.log("next up", this.radio[this.radioIndex]);
    if (this.radio[this.radioIndex]) {
      setTimeout(() => {
        this.playRadio(this.radio![this.radioIndex]);
      }, 500 + Math.random() * 1000);
    } else {
      this.waitTimeout = window.setTimeout(() => this.nextStage(), 1500);
    }
  }

  nextStage() {
    if (this.state === ScenarioStage.Intro) {
      this.changeState(ScenarioStage.InputWait);
    }
    if (this.state === ScenarioStage.Ended) {
      //we played the radio instead of ending, so end now.
      this.endedStage();
    }
  }

  inputWaitStage() {
    if (this.radio[this.radioIndex]) {
      this.playRadio(this.radio[this.radioIndex]);
    }

    this.showUnitPennants();
    // this.units.forEach((unit) => this.createTargetPennantFromUnit(unit));
    if (this.promptUnit) {
      this.showUnitPrompts();
    }
    if (this.target) {
      this.targetPennants.push(this.createTargetPennant(this.target));
    }
    document.addEventListener("pennantTap", this.pennantTapped);
  }

  movementStage() {
    if (this.radio[this.radioIndex]) {
      this.playRadio(this.radio[this.radioIndex]);
    }
    this.hidePrompts();
    this.targetPennants.forEach((pennant) => pennant.hide());
    if (this.scenario) {
      const match = this.world.scenarios.find(
        (scenario) => scenario.name === this.scenario
      );
      if (match) this.world.changeScenario(this.scenario);
    }
  }

  endedStage() {
    if (this.radio && this.radio[this.radioIndex]) {
      this.playRadio(this.radio[this.radioIndex]);
      return;
    }
    if (!this.active) return;
    this.stop();
    this.dispose();
    if (this.endedCallback) {
      this.endedCallback();
    }
  }

  pennantTapped(e: any) {
    if (!this.active) return;
    if (this.state !== ScenarioStage.InputWait) return;
    if (e.detail.name !== this.units[0] + "Pennant") return;
    console.log("pennant tap");

    this.changeState(ScenarioStage.Movement);
  }

  scenarioCompleted(e: any) {
    if (!this.active) return;
    // check if is in units?
    //if (this.units.includes(e.detail.name)) {}
    this.changeState(ScenarioStage.Ended);
  }

  pathFinished(e: any) {
    if (!this.active) return;
    console.log("partial path");
    if (this.units.includes(e.detail.name)) {
      console.log("Path finished", e.detail.name);
    }
  }

  start(endedCallback: () => void): void {
    this.active = true;
    this.endedCallback = endedCallback;
    this.changeState(ScenarioStage.Intro);
  }

  stop() {
    console.log("Stopping sequence", this.constructor.name);
    this.hidePrompts();
    this.hideUnitPennants();
    this.hideTargetPennants();
    this.active = false;
    this.world.characters.forEach((character) => {
      character.pennant?.hide();
      character.pennant?.hidePrompt();
    });

    window.clearTimeout(this.waitTimeout);
    this.dispose();
  }

  showUnitPennants() {
    this.units.forEach((unit) => {
      this.world.getCharacterByName(unit)?.pennant?.show();
    });
  }

  showUnitPrompts() {
    this.units.forEach((unit) => {
      this.world.getCharacterByName(unit)?.pennant?.showPrompt();
    });
  }

  hidePrompts() {
    this.units.forEach((unit) => {
      this.world.getCharacterByName(unit)?.pennant?.hidePrompt();
    });

    this.targetPennants.forEach((pennant) => {
      pennant.hidePrompt();
    });
  }
  showTargetPrompts() {
    // TODO - SHOW TARGET PENNANT
  }

  hideUnitPennants() {
    this.units.forEach((unit) => {
      this.world.getCharacterByName(unit)?.pennant?.hide();
    });
  }

  hideTargetPennants() {
    this.targetPennants.forEach((pennant) => {
      pennant.hidePrompt();
      pennant.hide();
    });
  }

  updateRadioData(newState: ScenarioStage) {
    if (this.scenario === "none") return;
    if (!radioData[this.scenario])
      throw new Error("Invalid scenario " + this.scenario);
    this.radio = radioData[this.scenario][newState];
  }

  playRadio(radio: RadioComms | RadioOrder) {
    document.addEventListener("voiceoverEnded", this.voiceoverEnded, {
      once: true,
    });
    //radio.unit = radio.unit ?? this.unitType; //allow override from radio.json
    if (!radio.unit) {
      radio.unit = this.unitType;
    }
    //radio.unit = this.unitType;
    if (radio.noSquelch === undefined || radio.noSquelch === false) {
      AudioManager.playSFX("sfx_walkieTalkie");
    }
    AudioManager.playVO(radio.audio!);
    document.dispatchEvent(new CustomEvent("setRadio", { detail: radio }));
  }

  dispose(): void {
    this.active = false;
    document.removeEventListener("pennantTap", this.pennantTapped);
    document.removeEventListener("scenarioCompleted", this.scenarioCompleted);
    document.removeEventListener("pathFinished", this.pathFinished);
    document.removeEventListener("voiceoverEnded", this.voiceoverEnded);
    window.clearTimeout(this.waitTimeout);
    this.hideUnitPennants();
    this.hideTargetPennants();
    this.targetPennants.forEach((pennant) => {
      pennant.dispose();
    });

    this.targetPennants = [];
  }
}
