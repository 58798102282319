import { TextureLoader } from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader";

import { World } from "../world/World";
import { LoadingTrackerEntry } from "./LoadingTrackerEntry";
import { UIManager } from "./UIManager";

export class LoadingManager {
  public firstLoad: boolean = true;
  public onFinishedCallback: () => void = () => {};

  private world: World;
  private gltfLoader: GLTFLoader;
  private textureLoader: TextureLoader;
  private loadingTracker: LoadingTrackerEntry[] = [];
  private assetCache: { [key: string]: Promise<any> | undefined } = {};
  private percentageMultiplier = 0.5;
  constructor(world: World) {
    this.world = world;
    this.gltfLoader = new GLTFLoader();
    this.textureLoader = new TextureLoader();

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("decoders/");

    this.gltfLoader.setDRACOLoader(dracoLoader);

    var ktx2Loader = new KTX2Loader();
    ktx2Loader.setTranscoderPath("decoders/");
    ktx2Loader.detectSupport(this.world.renderer);
    this.gltfLoader.setKTX2Loader(ktx2Loader);

    this.world.setTimeScale(0);
    UIManager.setUserInterfaceVisible(false);
    UIManager.setLoadingScreenVisible(true);
  }

  public loadGLTFAsync(path: string): Promise<any> {
    let trackerEntry = this.addLoadingEntry(path);

    if (this.assetCache[path] === undefined) {
      this.assetCache[path] = new Promise<any>((resolve, reject) => {
        this.gltfLoader.load(
          path,
          (gltf) => {
            resolve(gltf);
            this.doneLoading(trackerEntry);
            this.getLoadingPercentage();
          },
          (xhr) => {
            if (xhr.lengthComputable) {
              trackerEntry.progress = xhr.loaded / xhr.total;
            }
            this.getLoadingPercentage();
          },
          (error) => {
            console.error(path, error);
          }
        );
      });
    }

    return this.assetCache[path]!;
  }

  public loadGLTF(path: string, onLoadingFinished: (gltf: any) => void) {
    let trackerEntry = this.addLoadingEntry(path);

    this.gltfLoader.load(
      path,
      (gltf) => {
        onLoadingFinished(gltf);
        this.doneLoading(trackerEntry);
        this.getLoadingPercentage();
      },
      (xhr) => {
        if (xhr.lengthComputable) {
          trackerEntry.progress = xhr.loaded / xhr.total;
        }
        this.getLoadingPercentage();
      },
      (error) => {
        console.error(path, error);
      }
    );
  }

  public loadTexture(
    path: string,
    onLoadingFinished: (tex: any) => void
  ): THREE.Texture {
    let trackerEntry = this.addLoadingEntry(path);

    return this.textureLoader.load(
      path,
      (tex) => {
        onLoadingFinished(tex);
        this.doneLoading(trackerEntry);
        this.getLoadingPercentage();
      },
      (xhr) => {
        if (xhr.lengthComputable) {
          trackerEntry.progress = xhr.loaded / xhr.total;
        }
        this.getLoadingPercentage();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public addLoadingEntry(path: string): LoadingTrackerEntry {
    const existing = this.loadingTracker.find((item) => item.path === path);
    if (existing !== undefined) {
      return existing;
    }

    let entry = new LoadingTrackerEntry(path);
    this.loadingTracker.push(entry);

    return entry;
  }

  public doneLoading(trackerEntry: LoadingTrackerEntry): void {
    trackerEntry.finished = true;
    trackerEntry.progress = 1;

    if (this.isLoadingDone()) {
      if (this.onFinishedCallback !== undefined) {
        this.onFinishedCallback();
      } else {
        UIManager.setUserInterfaceVisible(true);
      }

      UIManager.setLoadingScreenVisible(false);
      this.world.setTimeScale(1);
    }
  }

  private getLoadingPercentage(): number {
    let done = true;
    let total = 0;
    let finished = 0;

    for (const item of this.loadingTracker) {
      total++;
      finished += item.progress;
      if (!item.finished) done = false;
    }

    let percent = (finished / total) * 100;

    document.dispatchEvent(
      new CustomEvent("loadProgress", {
        detail: { progress: percent * this.percentageMultiplier },
      })
    );
    if (percent >= 100) this.percentageMultiplier = 1.0;

    return percent;
  }

  private isLoadingDone(): boolean {
    for (const entry of this.loadingTracker) {
      if (!entry.finished) return false;
    }

    //clear loading cache
    Object.keys(this.assetCache).forEach((path) => {
      this.assetCache[path] = undefined;
    });
    this.assetCache = {};
    return true;
  }
}
